/* eslint-disable regex/invalid */
// TODO: This file will be deleted at the ned of the migration
// file contains variables from design system, edit this file only if design system changes

const colorsPalette = {
  white: "#FFFFFF",
  light100: "#F1F3F4",
  light120: "#F7F7F7",
  light180: "#EAEDEF",
  light200: "#E1E5E8",
  light300: "#C5CDD3",
  blue100: "#43A7EA",
  blue200: "#0078B7",
  blue300: "#046294",
  dark100: "#046294",
  dark200: "#1F1F1F",
  dark300: "#000000",
  gray100: "#6B6B6B",
  blueLight100: "#E7F1F8",
};
const brandedColors = {
  // todo sync up with
  bodySecondary: "#737373",
  bodyPrimary: colorsPalette.dark200,
};
export const colors = {
  ...colorsPalette,
  ...brandedColors,
};

export const radii = {
  none: 0,
  sm: "2px",
  md: "4px",
  lg: "0.75rem",
  full: "9999px",
};

// spacing convention on which we agreed with designer.
export const space = {
  "-6": "-30px",
  "-5": "-25px",
  "-4": "-20px",
  "-3": "-15px",
  "-2": "-10px",
  "-1": "-5px",
  "0": "0px",
  "1": "5px",
  "2": "10px",
  "3": "15px",
  "4": "20px",
  "5": "25px",
  "6": "30px",
  "7": "35px",
  "8": "40px",
  "9": "45px",
  "10": "50px",
};

export type Spacing = keyof typeof space;

export const zIndexes = {
  1: 1,
  2: 2,
  3: 3,
  5: 5,
  max: 9999,
};

// font styles from https://www.figma.com/file/Wtp3SIo47PW0g1EwSrQmyd/Typography
// should be up to date
// TODO(dmitry) implement variant mapping to h2,h3,h5, span
export const fontStyles = {
  header3: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "48px",
    lineHeight: "56px",
  },
  header4: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "32px",
    lineHeight: "37px",
  },
  header5M: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "23px",
  },
  header6MC: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "uppercase",
  },
  subtitle2: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
  },
  subtitle3: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
  },
  subtitle4M: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
  },
  body2: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
  },
  body3: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "15px",
  },
  body4M: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13px",
    lineHeight: "15px",
  },
  buttonMC: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    textTransform: "uppercase",
  },
  buttonM: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.005em",
  },
  button: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.005em",
  },
  caption: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "14px",
  },
};
