import React, { forwardRef } from "react";
import { OverridableComponent, OverrideProps } from "@mui/material/OverridableComponent";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";

// Re-export everything from MUI's Button (all types, utilities)
export * from "@mui/material/Button";

// TODO: Write documentation for
// Defining custom variants
// Defining custom props for Polymorphic components
// Defining custom behavior

// Define your custom props
type CustomProps = {
  testId?: string;
};

// Define the type map for the overridable component
interface ButtonTypeMap {
  props: LoadingButtonProps & CustomProps;
  defaultComponent: "button";
}

// Create the final ButtonProps type
export type ButtonProps<D extends React.ElementType = ButtonTypeMap["defaultComponent"]> =
  OverrideProps<ButtonTypeMap, D>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { testId, ...props },
  ref
) {
  // Your custom behavior here
  return <LoadingButton data-testid={testId} {...props} ref={ref} />;
}) as OverridableComponent<ButtonTypeMap>;

// Override only the Button component
export { Button };

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    subtle: true;
  }
}

declare module "@mui/material/ButtonGroup" {
  interface ButtonGroupPropsVariantOverrides {
    subtle: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonOwnProps {
    variant?: "subtle" | "contained" | "ghost";
  }

  interface IconButtonPropsSizeOverrides {
    extraSmall: true;
  }
}
