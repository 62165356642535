import { useMemo, useState } from "react";
import { useCurrentUserEmail, useCurrentUserId, useCurrentUserScope } from "shared/auth-hooks";
import { ucfirst } from "shared/utils";
import { keyBy, omit, pick } from "lodash";
import { differenceInDays } from "date-fns";
import { useCan } from "shared/permissions";
import { useDynamicBackfillsSettings } from "shared/useDynamicBackfillsSettings";
import { safeJSONParse } from "shared/safeJSONParse";
import { ENV } from "runenv";
import {
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { POLICY_INCLUDE_DISABLED } from "@shared/dashboard";
import { RuleSelect } from "modules/risks/search/types";
import { riskydashboard } from "api";
import { CELConditionOperator, CELOperators } from "libs/cel-query";
import { useGridCollection } from "modules/grid/useGridCollection";
import { useUsersList } from "api/apiHooks";
import {
  getRiskyUsers,
  getRiskyUserHistory,
  getUserRiskProgression,
  listCategories,
  listDatasets,
  getUserRiskLevels,
} from "api/risks";
import {
  irm_UserRiskGroupTypeEnum,
  api,
  irm,
  types,
  irm_RiskLevelsStatusEnum,
  irm_RiskLevelEnum,
  filtersapi,
} from "api/gen";
import { IncidentAction } from "modules/risks/dashboard/utils";
import { transformGridFiltersToCEL } from "modules/grid/filters";
import { GridFilters } from "modules/grid/filterTypes";
import { notification } from "modules/notification";
import { silenceCancelError } from "libs/errors/requestErrorsHelper";
import { invalidateMultipleQueries } from "api/utils";
import { DirectoryUser } from "modules/work-os/workOsTypes";
import { isNullOrUndefined } from "libs/utils";
import { UserDirectoryKey } from "modules/work-os/apiHooks";
import { useRiskyUsersFilters } from "./state";
import { FlowData, UserRiskGroup, UserColumnField, UserSeveritiesCounts } from "./types";
import { UNASSIGNED_ID } from "./constants";
import { messages } from "./messages";
import { Severity } from "../dashboard-core/severity";

export const RiskyUsersListKey = "risky-users-list";
export const UserRiskGroupsKey = "user-risk-groups-counts";
export const UserRiskGroupsCountsKey = "user-risk-groups-counts";
export const RiskyUserKey = "risky-user";
export const RiskyUserRawRiskScoreKey = "risky-user-raw-risk-score";
export const RiskyUserFlowsKey = "risky-user-flows";
export const RiskyUserIncidentsCountsKey = "risky-user-incidents-counts";
export const RiskyUserRiskScoreKey = "risky-user-risk-score";
export const DataSetsAllKey = "all-datasets";
export const CategoriesAllKey = "categories-counts";
export const RiskLevelBoundsKey = "risk-level-bounds";
export const FlowsFiltersKey = "flows-filters";
export const UserSidePanelKeys: QueryKey[] = [
  [RiskyUserFlowsKey],
  [RiskyUserRiskScoreKey],
  [RiskyUserIncidentsCountsKey],
];
export const RiskyUserHistoryKey = "risky-user-history";
export const UserDetailsKey = "user-details";

export interface RiskyUsersRequestParams {
  timesFilter: types.TimesFilter;
  search?: string;
  gridFilters?: GridFilters;
  assignees?: Array<string | null>;
  selectedUserRiskGroups?: UserRiskGroup[] | null;
  sort?: Array<{ field: string; sort: "asc" | "desc" | undefined | null }>;
  columns?: string[];
  selectedUser?: string;
  enabled: boolean;
}

const MINUTE_MS = 1000 * 60;

export function useRiskyUsers(params: RiskyUsersRequestParams) {
  const [events_filter, filter] = transformGenericFiltersToCEL(params);
  const sort = params.sort?.map(({ field, sort }) => ({
    by: field === UserColumnField.NormalizedRiskScore ? UserColumnField.RiskScore : field,
    desc: sort === "desc",
  }));

  const { data: riskBounds } = useRiskLevelBounds();

  const queryClient = useQueryClient();
  const { dynamicBackfills, isLoading } = useDynamicBackfillsSettings();
  const query = useGridCollection<irm.RiskyUserDTO>({
    key: [RiskyUsersListKey, params, dynamicBackfills],
    fetchData: (pageOffset, signal) => {
      // every time we reload users we should also reload risk bounds
      queryClient.invalidateQueries({
        queryKey: [RiskLevelBoundsKey],
      });

      return getRiskyUsers(
        {
          filter,
          sort,
          fields: getRiskyUsersRequestFields({ visibleColumns: params.columns }),
          offset: pageOffset || 0,
          page_size: 500,
          events_filter,
          dynamic_backfills: dynamicBackfills,
        },
        { signal }
      );
    },
    staleTime: MINUTE_MS * 2,
    enabled: params.enabled && !isLoading && Boolean(ENV.FEATURES.INSIDER_RISK_MANAGEMENT_TABLE),
  });

  const riskyUsers = useMemo(
    () =>
      (query.data || []).map((dto) => {
        return {
          ...dto,
          risk_level: riskBounds?.levels
            ? getRiskLevel(dto.risk_score || 0, riskBounds.levels)
            : dto.risk_level ?? irm_RiskLevelEnum.RiskLevelUnknown,
          risk_level_status: riskBounds
            ? riskBounds.status
            : dto.risk_level
            ? irm_RiskLevelsStatusEnum.RiskLevelsStatusReady
            : irm_RiskLevelsStatusEnum.RiskLevelsStatusDisabled,
        };
      }),
    [query.data, riskBounds]
  );

  return {
    ...query,
    isLoading: query.isInitialLoading,
    riskyUsers,
    error: silenceCancelError(query.error),
  };
}

export function useRiskyUserRiskScore({
  id,
  start_time,
  end_time,
}: {
  id: string | number;
  start_time?: string;
  end_time?: string;
}): { riskScore: number | undefined; isLoading: boolean } {
  const [events_filter, filter] = transformGenericFiltersToCEL({
    selectedUser: String(id),
    timesFilter: { start_time, end_time },
  });

  const { dynamicBackfills, isLoading } = useDynamicBackfillsSettings();
  const { data, isInitialLoading } = useQuery({
    queryKey: [RiskyUserRawRiskScoreKey, id],

    queryFn: ({ signal }) =>
      getRiskyUsers(
        {
          filter,
          fields: ["risk_score"],
          offset: 0,
          page_size: 1,
          events_filter,
          dynamic_backfills: dynamicBackfills,
        },
        { signal }
      ).then((response) => response.result?.[0]),

    enabled: Boolean(id) && !isLoading,
    staleTime: MINUTE_MS * 5,
  });

  return {
    riskScore: data?.risk_score,
    isLoading: isInitialLoading,
  };
}

interface UpdateAssigneeParams {
  userIds: string[];
  assignee: string | null;
  all?: boolean;
  search?: string;
}
export function useUpdateAssignee() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ userIds, assignee: assigneeId }: UpdateAssigneeParams) => {
      const assignee = assigneeId === UNASSIGNED_ID ? null : assigneeId;
      userIds.forEach((userId) => {
        queryClient.setQueryData<irm.RiskyUserDTO>([RiskyUserKey, userId], (previousData) => ({
          ...previousData!,
          assignee,
        }));
      });

      return Promise.resolve();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [RiskyUsersListKey],
      });
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: [RiskyUsersListKey],
      });
      queryClient.invalidateQueries({
        queryKey: [RiskyUserKey],
      });
    },
  });
}

export function useAssignees(options?: { unassignedName?: string; assignToMeLabel?: string }) {
  const currentUserId = useCurrentUserId();
  const currentUserEmail = useCurrentUserEmail();

  const query = useUsersList();
  const unassignedOption = {
    id: UNASSIGNED_ID,
    name: options?.unassignedName || messages.filters.unassigned,
  };
  const assignToMeOption = {
    id: currentUserEmail!,
    name: options?.assignToMeLabel || messages.filters.assignToMe,
  };

  const assignees = [
    unassignedOption,
    assignToMeOption,
    ...(query.data || [])
      .filter((user) => user.id !== currentUserId)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((user) => ({
        id: user.email,
        name: user.email,
      })),
  ];

  return {
    ...query,
    assignees,
  };
}

export function useRiskyUserFlows({
  id,
  timeRange,
}: {
  id: string | number | undefined;
  timeRange: types.TimesFilter;
}) {
  const filters = getUserFlowsGeneralFilters({ timeRange, userId: id });

  const { data: datasets, isLoading: isLoadingDatasets } = useQuery({
    queryKey: [DataSetsAllKey],
    queryFn: ({ signal }) => listDatasets({ signal }).then((res) => res.datasets),
    staleTime: MINUTE_MS * 5,
  });
  const { data: categories, isLoading: isLoadingCategories } = useQuery({
    queryKey: [CategoriesAllKey],
    queryFn: ({ signal }) => listCategories({ signal }).then((res) => res.categories),
    staleTime: MINUTE_MS * 5,
  });
  const { data: filtersCEL, isLoading: isLoadingFilters } = useQuery({
    queryKey: [FlowsFiltersKey, filters],
    queryFn: ({ signal }) => api.filtersapi.Service.FiltersToCel({ filters }, { signal }),
    staleTime: MINUTE_MS * 5,
  });

  const { dynamicBackfills, isLoading: backfillsSettingsLoading } = useDynamicBackfillsSettings();

  const flowsQuery = useQuery({
    queryKey: [RiskyUserFlowsKey, filtersCEL],
    queryFn: ({ signal }) =>
      getUserFlows({
        filtersCEL: filtersCEL!.cel_string,
        datasets: datasets!,
        categories: categories!,
        dynamicBackfills,
        signal,
      }),
    enabled:
      Boolean(id) &&
      Boolean(datasets) &&
      Boolean(categories) &&
      Boolean(filtersCEL) &&
      !backfillsSettingsLoading,
    staleTime: MINUTE_MS * 5,
  });

  return {
    ...flowsQuery,
    isLoading:
      flowsQuery.isInitialLoading ||
      flowsQuery.isFetching ||
      isLoadingDatasets ||
      isLoadingCategories ||
      isLoadingFilters,
  };
}

const getUserFlowsGeneralFilters = ({
  timeRange,
  userId,
  global_filter,
}: {
  timeRange: types.TimesFilter;
  userId: string | number | undefined;
  global_filter?: string;
}): filtersapi.FilterToCelRequestFilters => ({
  times_filter: timeRange,
  users_filter: { directory_users: [{ user_id: String(userId) }] },
  category_severity_filter: {
    category_severities: [Severity.Low, Severity.Medium, Severity.High, Severity.Critical],
    allow_null_severity: false,
  },
  locations_filters: null,
  side_selector: "",
  datasets_filter: { all_datasets: true, dataset_ids: null },
  policies_filter: { all_policies: true, policy_ids: null },
  status_filter: "any",
  global_filter: global_filter || "",
});

function getUserFlows({
  categories,
  datasets,
  filtersCEL,
  dynamicBackfills,
  signal,
}: {
  filtersCEL: string;
  datasets: riskydashboard.DatasetDTO[];
  categories: types.Category[];
  dynamicBackfills: boolean;
  signal?: AbortSignal;
}): Promise<FlowData[]> {
  const categoriesMap = keyBy(categories, "id");
  const datasetsMap = keyBy(datasets, "id");

  return api.eventsapi.Service.Aggregations(
    {
      global_filter: filtersCEL,
      metrics: [{ metric: "risk_score" }, { metric: "count" }],
      include_disabled: POLICY_INCLUDE_DISABLED,
      aggregations: {
        user_flows: {
          group_by: ["dataset_id", "policy_id"],
          type: "group_by",
        },
      },
      dynamic_backfills: dynamicBackfills,
    },
    { signal }
  ).then(({ aggregations }) => {
    return (
      aggregations.user_flows.values?.map((dataset_policy) => {
        const dataset = datasetsMap[dataset_policy.value![0]];
        const category = categoriesMap[dataset_policy.value![1]];
        const risk_score = dataset_policy.metrics!.risk_score.value;
        return {
          id: `${dataset.id}-${category.id}`,
          risk_score,
          from: {
            id: dataset.id,
            name: dataset.name,
            count: dataset_policy.metrics!.count.value,
            sensitivity: dataset.sensitivity,
          },
          to: {
            id: category.id,
            name: category.name,
            incidentAction: (category.rule.incident_action || "none") as IncidentAction,
            severity: category.severity,
          },
        };
      }) ?? []
    );
  });
}

const getUserIncidentsCountsQueryParameters = ({
  timeRange,
  user,
}: {
  timeRange: types.TimesFilter;
  user: DirectoryUser | undefined;
}) => ({
  times_filter: timeRange,
  directory_users: [{ user_id: user?.id, sensor_users: user?.sensor_users }],
});

// TODO(katri): this can now be used correctly
export function useUserIncidentsCounts({
  user,
  timeRange,
}: {
  user: DirectoryUser | undefined;
  timeRange: types.TimesFilter;
}) {
  const params = getUserIncidentsCountsQueryParameters({ timeRange, user: user });

  return useQuery<UserSeveritiesCounts>({
    queryKey: [RiskyUserIncidentsCountsKey, params],
    queryFn: ({ signal }) => getIncidentsBySeverityCounts(params, { signal }),
    enabled: Boolean(user),
  });
}

function getIncidentsBySeverityCounts(
  filters: types.IncidentsFilters,
  { signal }: { signal?: AbortSignal }
) {
  return api.riskydashboard.ServiceInterface.CountIncidents(
    {
      column_contains: "",
      filters,
      max_items: 100,
      column: "category_severity",
    },
    { signal }
  ).then((incidentsCounts) => {
    const bySeverity = {
      [Severity.Critical]: 0,
      [Severity.High]: 0,
      [Severity.Medium]: 0,
      [Severity.Low]: 0,
      [Severity.Informational]: 0,
    };

    let total = 0;

    incidentsCounts.items?.forEach((item) => {
      total += item.count;
      bySeverity[Number(item.value) as Severity] += item.count;
    });

    return { total, bySeverity };
  });
}

export function useUserEventsBySeverityCounts({
  id,
  timesFilter,
}: {
  id: string | number | undefined;
  timesFilter: types.TimesFilter;
}) {
  const selectedUser = String(id);
  const [filter] = transformGenericFiltersToCEL({ timesFilter, selectedUser });
  const { dynamicBackfills, isLoading: settingsLoading } = useDynamicBackfillsSettings();

  const query = useQuery({
    queryKey: [RiskyUserIncidentsCountsKey, filter, dynamicBackfills],
    queryFn: ({ signal }) =>
      getEventsBySeverityCounts(filter, Boolean(dynamicBackfills), { signal }),
    enabled: Boolean(id) && !settingsLoading,
    staleTime: MINUTE_MS * 5,
  });

  return {
    ...query,
    isLoading: query.isInitialLoading || query.isFetching,
  };
}

function getEventsBySeverityCounts(
  filter: string,
  dynamicBackfills: boolean,
  { signal }: { signal?: AbortSignal } = {}
) {
  return api.irm.Service.Suggest(
    {
      fields: {
        severity: {
          field: "string(policy_severity)",
          page_size: 5,
          offset: 0,
          filter,
        },
      },
      dynamic_backfills: dynamicBackfills,
    },
    { signal }
  ).then((counts) => {
    const severityCounts = counts.fields!["severity"];

    const bySeverity = {
      [Severity.Critical]: 0,
      [Severity.High]: 0,
      [Severity.Medium]: 0,
      [Severity.Low]: 0,
      [Severity.Informational]: 0,
    };

    let total = 0;

    severityCounts.values?.forEach((item) => {
      total += item.count;
      bySeverity[Number(item.value) as Severity] += item.count;
    });

    return { total, bySeverity };
  });
}

const getUserRiskProgressionQueryParameters = ({
  timeRange,
  userId,
  filter,
}: {
  timeRange: types.TimesFilter;
  userId: string | number | undefined;
  filter?: string;
}) => ({
  times_filter: timeRange,
  userId: String(userId),
  period: getRiskPeriod(new Date(timeRange.start_time!), new Date(timeRange.end_time!)),
  filter: filter || "",
});

const getRiskPeriod = (startDate: Date, endDate: Date): types.Period => {
  const timeRangeDiffDays = Math.abs(differenceInDays(startDate, endDate));
  if (timeRangeDiffDays >= 179) {
    return "weekly";
  }

  return "daily";
};

export function useUserRiskProgression({
  id,
  timeRange,
}: {
  id: string | number | undefined;
  timeRange: types.TimesFilter;
}) {
  const params = getUserRiskProgressionQueryParameters({
    timeRange,
    userId: id,
  });

  const { dynamicBackfills, isLoading } = useDynamicBackfillsSettings();
  const query = useQuery({
    queryKey: [RiskyUserRiskScoreKey, params],
    queryFn: ({ signal }) =>
      getUserRiskProgression({ ...params, dynamicBackfills: Boolean(dynamicBackfills), signal }),
    enabled: Boolean(id) && !isLoading,
    staleTime: MINUTE_MS * 5,
  });

  return {
    ...query,
    isLoading: query.isInitialLoading || query.isFetching,
  };
}

const sortGroupsByDate = (groupA: UserRiskGroup, groupB: UserRiskGroup) => {
  return Number(new Date(groupA.createdAt || 0)) - Number(new Date(groupB.createdAt || 0));
};

export const mapGroupDtoToGroup = (group: irm.UserRiskGroupDTO): UserRiskGroup => {
  const metadata = safeJSONParse(group.metadata);
  const rulesSelect = safeJSONParse(metadata?.rules_select) as RuleSelect[];
  return {
    id: group.id,
    name: group.name,
    type: group.type,
    filter: group.filter,
    risk_multiplier: group.risk_multiplier ?? 1,
    color: metadata?.color,
    description: metadata?.description,
    quickFilter: metadata?.quick_filter,
    rulesSelect,
    createdAt: group.created_at,
  };
};

export const mapRiskyUserGroupToGroup = (
  group: types.UserRiskGroupInfo | null
): UserRiskGroup | null => {
  // TODO this is a hack: we should get group info from the groups api instead of embedding into user
  if (!group) {
    return null;
  }

  const metadata = safeJSONParse(group.metadata);
  const rulesSelect = safeJSONParse(metadata?.rules_select) as RuleSelect[];

  return {
    id: group.id,
    name: group.name!,
    type: rulesSelect?.length
      ? irm_UserRiskGroupTypeEnum.UserRiskGroupTypeDynamic
      : irm_UserRiskGroupTypeEnum.UserRiskGroupTypeManual,
    filter: "",
    risk_multiplier: 1,
    color: metadata?.color,
    description: metadata?.description,
    quickFilter: metadata?.quick_filter,
    rulesSelect,
    createdAt: undefined,
  };
};

export const mapGroupToDto = (group: UserRiskGroup): irm.UserRiskGroupDTO => {
  return {
    id: group.id,
    name: group.name,
    type: group.type,
    filter: group.type === irm_UserRiskGroupTypeEnum.UserRiskGroupTypeDynamic ? group.filter : "",
    risk_multiplier: group.risk_multiplier,
    metadata: JSON.stringify({
      color: group.color,
      description: group.description,
      quick_filter: group.quickFilter,
      rules_select: JSON.stringify(group.rulesSelect ?? []),
    }),
  };
};

export function useUserRiskGroups() {
  const { data: canFetch } = useCan("user_risk_group__read");
  return useQuery({
    queryKey: [UserRiskGroupsKey],

    queryFn: async ({ signal }) => {
      return api.irm.Service.ListUserRiskGroups({}, { signal }).then(({ groups: groupsDto }) => {
        if (!groupsDto?.length) {
          return [];
        }

        return groupsDto.map(mapGroupDtoToGroup).sort(sortGroupsByDate);
      });
    },

    staleTime: MINUTE_MS * 5,
    enabled: Boolean(canFetch),
  });
}

export interface UserRiskGroupCount extends UserRiskGroup {
  count?: number;
}
export function useUserRiskGroupsCounts() {
  const { timeRange } = useRiskyUsersFilters();
  const [timesFilter] = transformGenericFiltersToCEL({ timesFilter: timeRange });

  const groupsQuery = useUserRiskGroups();
  const groups = groupsQuery.data;

  const { dynamicBackfills, isLoading } = useDynamicBackfillsSettings();

  const groupsCountsQuery = useQuery({
    queryKey: [UserRiskGroupsCountsKey, timesFilter, groups, dynamicBackfills],

    queryFn: ({ signal }) =>
      api.irm.Service.UserRiskGroupsCount(
        {
          filter: timesFilter,
          dynamic_backfills: dynamicBackfills,
        },
        { signal }
      ).then(({ user_risk_groups_count }) => user_risk_groups_count),
    enabled: Boolean(groups?.length) && !isLoading,
    staleTime: MINUTE_MS * 2,
  });

  const groupsCounts = groupsCountsQuery.data;

  const data = useMemo(
    () => groups?.map((group) => ({ ...group, count: groupsCounts?.[group.id!] || 0 })) || [],
    [groups, groupsCounts]
  );

  return {
    data,
    isLoading: groupsQuery.isLoading,
    isLoadingCounts: groupsCountsQuery.isFetching,
    isError: groupsQuery.isError || groupsCountsQuery.isError,
    refetch: () => {
      groupsQuery.refetch();
      groupsCountsQuery.refetch();
    },
  };
}

export function useAddUserRiskGroup() {
  const queryClient = useQueryClient();
  const { data: userScope } = useCurrentUserScope();
  const userGroupAccessType =
    userScope?.subject_scope_view?.[0]?.definition?.user_groups?.access_type || "full";

  return useMutation({
    mutationFn: async ({ group }: { group: UserRiskGroup }) => {
      try {
        const { id } = await api.irm.Service.AddUserRiskGroup({
          group: mapGroupToDto(group),
        });

        return { id, ...group };
      } catch (err: any) {
        throw new Error(ucfirst(err.response?.data?.error ?? "The group could not be created"));
      }
    },
    onSuccess: (newGroup: UserRiskGroup) => {
      // If user has access restriction, then we cannot prefill user group on frontend
      if (userGroupAccessType === "full") {
        queryClient.setQueryData([UserRiskGroupsKey], (prevGroups: UserRiskGroup[] | undefined) => [
          ...(prevGroups || []),
          newGroup,
        ]);
      } else {
        queryClient.invalidateQueries({ queryKey: [UserRiskGroupsKey] });
      }
      invalidateMultipleQueries(queryClient, [
        [RiskyUserKey],
        [RiskyUserFlowsKey],
        [RiskyUsersListKey],
        [RiskyUserRiskScoreKey],
        [RiskyUserRawRiskScoreKey],
      ]);
    },
    throwOnError: true,
  });
}

export function useUpdateUserRiskGroups() {
  const queryClient = useQueryClient();
  const filtersState = useRiskyUsersFilters();

  return useMutation({
    mutationFn: async ({ updates }: { updates: UserRiskGroup[] }) => {
      try {
        await api.irm.Service.UpdateUserRiskGroups({ groups: updates.map(mapGroupToDto) });

        return updates;
      } catch (err: any) {
        throw new Error(ucfirst(err.response?.data?.error ?? "The group could not be updated"));
      }
    },
    onSuccess: (updatedGroups: UserRiskGroup[]) => {
      queryClient.setQueryData([UserRiskGroupsKey], (prevGroups: UserRiskGroup[] | undefined) =>
        (prevGroups || []).map((group) => updatedGroups?.find(({ id }) => id === group.id) || group)
      );
      invalidateMultipleQueries(queryClient, [
        [RiskyUserKey],
        [RiskyUserFlowsKey],
        [RiskyUsersListKey],
        [RiskyUserRiskScoreKey],
        [RiskyUserRawRiskScoreKey],
      ]);

      const newFilters = filtersState.selectedUserRiskGroups?.map(
        (group) => updatedGroups?.find(({ id }) => id === group.id) ?? group
      );
      if (newFilters) {
        filtersState.setSelectedUserRiskGroups(newFilters);
      }
    },
  });
}

export function useUpdateUserRiskGroupMembership() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      userAlias,
      addTo,
      removeFrom,
    }: {
      userAlias: string;
      addTo?: string[];
      removeFrom?: string[];
    }) =>
      api.irm.Service.UpdateUserRiskGroupMembership({
        user_alias: userAlias,
        add_to: addTo,
        remove_from: removeFrom,
      }),
    onSuccess: () => {
      invalidateMultipleQueries(queryClient, [
        [RiskyUserKey],
        [RiskyUserFlowsKey],
        [RiskyUsersListKey],
        [RiskyUserRiskScoreKey],
        [UserRiskGroupsKey],
        [UserDirectoryKey],
      ]);
    },
  });
}

export function useDeleteUserRiskGroup() {
  const queryClient = useQueryClient();
  const filtersState = useRiskyUsersFilters();

  return useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      await api.irm.Service.DeleteUserRiskGroup({ id });

      return id;
    },
    onSuccess: (deletedGroupId: string) => {
      queryClient.setQueryData([UserRiskGroupsKey], (prevGroups: UserRiskGroup[] | undefined) =>
        (prevGroups || []).filter((group) => group.id !== deletedGroupId)
      );
      invalidateMultipleQueries(queryClient, [
        [RiskyUserKey],
        [RiskyUserFlowsKey],
        [RiskyUsersListKey],
        [RiskyUserRiskScoreKey],
        [RiskyUserRawRiskScoreKey],
      ]);

      const newFilters = filtersState.selectedUserRiskGroups?.filter(
        (group) => deletedGroupId !== group.id
      );
      filtersState.setSelectedUserRiskGroups(newFilters);
    },
  });
}

export function useClearUserRiskScore() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      userId,
      comment,
      lastViolationTime,
    }: {
      userId: string;
      comment: string;
      lastViolationTime: string;
    }) => {
      await api.irm.Service.ResetUserRiskScore({
        comment,
        matchers: [{ user_id: userId, last_violation_time: lastViolationTime }],
      });

      return userId;
    },
    onSuccess: (userId: string) => {
      notification.success({
        message: "Risk score successfully cleared",
      });
      queryClient.invalidateQueries({
        queryKey: [RiskyUserKey, userId],
      });
      queryClient.invalidateQueries({
        queryKey: [RiskyUsersListKey],
      });
      queryClient.invalidateQueries({
        queryKey: [RiskyUserHistoryKey, userId],
      });
    },
    onError: () => {
      notification.error({
        message: "Failed to clear risk score",
      });
    },
  });
}
export function useRiskyUserHistory(userId: string | number | undefined) {
  const { data: canReadHistory } = useCan("risk_score_clearing__read");
  const query = useInfiniteQuery({
    queryKey: [RiskyUserHistoryKey, userId],
    queryFn: async ({ pageParam, signal }) => {
      // return userId && lastPage?.hasMore !== false
      return userId && !isNullOrUndefined(pageParam)
        ? getRiskyUserHistory({ userId: String(userId), page: pageParam, signal })
        : { actions: [], hasMore: false, nextPage: undefined };
    },
    enabled: Boolean(userId) && canReadHistory,
    getNextPageParam: (lastPage) => (lastPage.hasMore ? lastPage.nextPage : undefined),
    initialPageParam: 0,
  });

  return {
    ...query,
    actions: query?.data?.pages.flatMap((page) => page.actions) ?? [],
  };
}

export function useRiskLevelBounds() {
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);

  return useQuery({
    queryKey: [RiskLevelBoundsKey],
    queryFn: async ({ signal }) => {
      const res = await getUserRiskLevels({ signal });
      if (res.status === irm_RiskLevelsStatusEnum.RiskLevelsStatusRecalibrating) {
        setRefetchInterval(15 * 1000);
      } else {
        setRefetchInterval(false);
      }

      return res;
    },
    refetchInterval,
  });
}

export async function getFilterSuggestions({
  field,
  pageSize,
  nextPage,
  search,
  dynamicBackfills,
}: {
  field: string;
  nextPage?: number | string | null;
  pageSize: number;
  search?: string;
  dynamicBackfills: boolean;
}) {
  const offset = Number(nextPage);
  const response = await api.irm.Service.Suggest({
    fields: {
      [field]: {
        field: field,
        page_size: pageSize,
        offset,
        filter: search ? CELOperators.string.contains(field, [search]) : "",
      },
    },
    dynamic_backfills: dynamicBackfills,
  });

  const fieldResponse = response?.fields?.[field];
  const options = fieldResponse!.values!.map((v) => v.value);

  return {
    options,
    nextPage: offset + options.length < fieldResponse!.total ? offset + options.length : undefined,
  };
}

interface GenericFilters {
  timesFilter?: types.TimesFilter;
  search?: string;
  gridFilters?: GridFilters;
  selectedUserRiskGroups?: UserRiskGroup[] | null;
  selectedUser?: string;
  selectedUsers?: string[];
}
export function transformGenericFiltersToCEL({
  timesFilter,
  search,
  gridFilters,
  selectedUserRiskGroups,
  selectedUser,
  selectedUsers,
  dateField = "local_date_utc",
}: GenericFilters & { dateField?: string; selectedUsers?: string[] }): [
  eventsFilter: string,
  recordsFilter: string
] {
  const filters: string[] = [];
  const recordsFilters: string[] = [];

  if (timesFilter && timesFilter.start_time && timesFilter.end_time) {
    filters.push(
      `${CELOperators.number.greaterOrEqual(
        dateField,
        CELOperators.toDate(timesFilter.start_time)
      )}`
    );
    filters.push(
      `${CELOperators.number.lessOrEqual(dateField, CELOperators.toDate(timesFilter.end_time))}`
    );
  }

  if (search) {
    const searchCELs = [
      UserColumnField.DisplayName,
      UserColumnField.Alias,
      UserColumnField.Title,
      UserColumnField.Department,
    ].map((field) => CELOperators.string.contains(field, [search]));
    searchCELs.push(
      CELOperators.list.existsContains(
        UserColumnField.EndpointInfo,
        [search],
        (x) => `${x}.local_user_name`
      ),
      CELOperators.list.existsContains(
        UserColumnField.EndpointInfo,
        [search],
        (x) => `${x}.hostname`
      )
    );
    filters.push(`(${searchCELs.join(` ${CELConditionOperator.OR} `)})`);
  }

  if (selectedUser) {
    filters.push(`${CELOperators.common.equal("id", CELOperators.toString(selectedUser))}`);
  } else if (selectedUsers?.length) {
    filters.push(`${CELOperators.common.in("id", selectedUsers.map(CELOperators.toString))}`);
  }

  if (gridFilters) {
    const gridFiltersCEL = transformGridFiltersToCEL(
      omit(gridFilters, UserColumnField.LastViolationTime),
      {
        // Custom transformers to CEL for specific columns
        [UserColumnField.UserGroups]: (item, defaultTransformer) => {
          return defaultTransformer(UserColumnField.UserGroups, item, (field) => `${field}.name`);
        },
        [UserColumnField.Emails]: (item, defaultTransformer) => {
          return defaultTransformer(UserColumnField.Emails, item, (field) => `${field}.value`);
        },
      }
    );
    if (gridFiltersCEL) {
      filters.push(gridFiltersCEL);
    }
    const gridRecordFiltersCEL = transformGridFiltersToCEL(
      pick(gridFilters, UserColumnField.LastViolationTime)
    );
    if (gridRecordFiltersCEL) {
      recordsFilters.push(gridRecordFiltersCEL);
    }
  }

  if (selectedUserRiskGroups?.length) {
    filters.push(
      `risk_groups.exists(x, x.id in [${selectedUserRiskGroups
        .map(({ id }) => id)
        .map(CELOperators.toString)
        .join(",")}])`
    );
  }

  return [
    filters.join(` ${CELConditionOperator.AND} `),
    recordsFilters.join(` ${CELConditionOperator.AND} `),
  ];
}

function getRiskyUsersRequestFields({
  visibleColumns,
}: { visibleColumns?: string[] } = {}): string[] {
  const allRequestedFields: string[] = [];
  if (visibleColumns) {
    allRequestedFields.push(
      ...visibleColumns.reduce((fields: string[], column) => {
        if (column === UserColumnField.LocalUserName) {
          fields.push(UserColumnField.EndpointInfo);
        } else {
          fields.push(column);
        }
        return fields;
      }, [])
    );
  }
  // always include fields necessary for user actions
  allRequestedFields.push(UserColumnField.RiskScore);
  allRequestedFields.push(UserColumnField.RiskGroups);
  return [...new Set(allRequestedFields)];
}

function getRiskLevel(
  risk: number,
  riskLevelBounds: irm.RiskLevelBounds[]
): irm.RiskLevel | undefined {
  return riskLevelBounds.find(
    ({ min_score, max_score }) => risk >= min_score && (!max_score || risk <= max_score)
  )?.level;
}
