import React from "react";
import { Divider as MDivider } from "@ds-proxy";
export interface StackDividerProps {
  fitContent?: boolean;
  style?: any;
}

export function Divider(props: StackDividerProps) {
  const marginX = `calc(var(--padding-x-value) * -1)`;
  return (
    <MDivider
      style={
        props.fitContent
          ? { ...props.style }
          : {
              ...props.style,
              marginLeft: marginX,
              marginRight: marginX,
            }
      }
    />
  );
}

export function HDivider(props: StackDividerProps) {
  const marginX = `calc(var(--padding-x-value) * -1)`;
  return (
    <MDivider
      style={
        props.fitContent
          ? { ...props.style }
          : {
              ...props.style,
              marginLeft: marginX,
              marginRight: marginX,
            }
      }
    />
  );
}
