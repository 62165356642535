import React, { ComponentType, useCallback, useEffect, useMemo, useRef } from "react";
// eslint-disable-next-line no-restricted-imports
import * as muiToolpadDialogs from "./mui-toolpad-dialogs";

// eslint-disable-next-line no-restricted-imports
export { DialogsProvider } from "./mui-toolpad-dialogs";

export const COMPONENT_UNMOUNTED = Symbol("Component unmounted");

export function isComponentUnmountedResult(result: unknown): result is symbol {
  return result === COMPONENT_UNMOUNTED;
}

export interface OpenDialogOptions<R> extends muiToolpadDialogs.OpenDialogOptions<R> {
  onUnmount?(close: (result: R) => void): void;
}

export interface DialogProps<R = void> {
  open: boolean;
  onClose: (result: R) => Promise<void>;
}

export interface UseDialogsOptions {
  /** When it changes, closes the dialogs previously open from this hook. */
  key?: string;
}

export function useDialogs({ key }: UseDialogsOptions = {}) {
  const { open: openToolpadDialog, close: closeToolpadDialog } = muiToolpadDialogs.useDialogs();
  const abortControllerRef = useRef(new AbortController());
  const closeToolpadDialogRef = useRef(closeToolpadDialog);
  closeToolpadDialogRef.current = closeToolpadDialog;

  // TODO - Update types so it also accepts a single parameter when there are no custom props
  const open = useCallback(
    function openDialog<P, R>(
      Component: ComponentType<P & DialogProps<R>>,
      options: OpenDialogOptions<R> & Omit<P, "open" | "onClose">
    ) {
      const { onClose: originalOnClose, onUnmount: inOnUnmount, ...componentProps } = options ?? {};

      const onClose = originalOnClose as ((result: R | symbol) => Promise<void>) | undefined;

      const dialog = openToolpadDialog<DialogPayload<P, R>, R | symbol>(
        ToolpadOpenDialogAdapter,
        {
          Component,
          componentProps: componentProps as Omit<P, keyof DialogProps<R>>,
        },
        { onClose }
      );

      const onUnmount =
        inOnUnmount ??
        ((close: (result: R | symbol) => void) => {
          close(COMPONENT_UNMOUNTED);
        });

      const closeDialog = () =>
        onUnmount((result) => closeToolpadDialogRef.current(dialog, result));

      abortControllerRef.current.signal.addEventListener("abort", closeDialog);
      dialog.finally(() => {
        abortControllerRef.current.signal.removeEventListener("abort", closeDialog);
      });

      return dialog;
    },
    [openToolpadDialog]
  );

  useEffect(() => {
    abortControllerRef.current = new AbortController();
    return () => {
      abortControllerRef.current.abort();
    };
  }, [key]);

  return useMemo(
    () => ({
      open,
      close: closeToolpadDialog,
    }),
    [open, closeToolpadDialog]
  );
}

interface DialogPayload<P, R> {
  Component: ComponentType<P & DialogProps<R>>;
  componentProps: Omit<P, keyof DialogProps<R>>;
}

function ToolpadOpenDialogAdapter<P, R>({
  open,
  onClose,
  payload,
}: muiToolpadDialogs.DialogProps<DialogPayload<P, R>, R>) {
  const { Component, componentProps } = payload;
  return <Component open={open} onClose={onClose} {...(componentProps as P)} />;
}
