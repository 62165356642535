import React from "react";
import { Merge } from "ts-essentials";
import { InputAdornment, TextField, TextFieldProps } from "@ds-proxy";
import { Icon } from "@ds-ui";

export const SearchInputV2 = React.forwardRef(function SearchInputV2(
  {
    size,
    onChange,
    onCancel,
    ...props
  }: Merge<
    Partial<TextFieldProps>,
    {
      size?: "small" | "inherit" | "medium" | "large";
      onCancel?: (reason: "cancel" | "escape") => void;
      onChange: (v: string) => void;
    }
  >,
  ref
) {
  return (
    <TextField
      inputRef={ref}
      {...props}
      fullWidth
      autoFocus
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          onCancel?.("escape");
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon name={"SearchOutlined"} fontSize={size} color="action" />
          </InputAdornment>
        ),
        endAdornment: onCancel ? (
          <InputAdornment
            style={{ cursor: "pointer" }}
            onClick={() => onCancel("cancel")}
            position="end"
          >
            <Icon name={"ClearOutlined"} fontSize={size} color="action" />
          </InputAdornment>
        ) : null,
      }}
      onChange={(el) => {
        const value = el.target.value;
        onChange(value);
      }}
    />
  );
});
