import React from "react";
import ReactCopyToClipboard from "react-copy-to-clipboard";
import { IconButton, IconButtonProps } from "@ds-proxy";
import { notification } from "modules/notification";
import { messages } from "ui/messages";
import { Icon, IconName } from "../icons";

export interface CopyToClipboardProps {
  text: string;
  iconName?: IconName;
  size?: IconButtonProps["size"];
  onSuccess?: () => void;
  testId?: string;
}

export const onSuccessDefault = () =>
  notification.success({
    message: messages.copyTooltip.copiedText,
  });

// TODO: this should be a button, maybe IconButton component
export function CopyToClipboard({
  text = "",
  size = "medium",
  iconName = "FileCopyOutlined",
  onSuccess = onSuccessDefault,
  testId,
}: CopyToClipboardProps) {
  return (
    <ReactCopyToClipboard text={text} onCopy={onSuccess} data-testid={testId}>
      <IconButton
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
        className="inline-flex cursor-pointer p-0 bg-transparent border-0"
        size={size}
      >
        <Icon name={iconName} />
      </IconButton>
    </ReactCopyToClipboard>
  );
}
