import React from "react";
import { Merge } from "ts-essentials";
import { FormApi } from "final-form";
import { Button, ButtonProps, Fab } from "@ds-proxy";
import { IModalFormProps, ModalForm } from "./ModalForm";

export interface IOpenModalButtonProps extends ButtonProps {
  label: string | JSX.Element;
  modalProps: Pick<IModalFormProps, Exclude<keyof IModalFormProps, "isOpen" | "onClose">>;
  Modal?: React.Component;
  ButtonComponent: React.ElementType<ButtonProps>;
}

interface IOpenModalButtonState {
  isOpen: boolean;
}

/**
 * @deprecated This component  should not be used
 */
export class OpenModalButton extends React.Component<
  Merge<IOpenModalButtonProps, { variant?: ButtonProps["variant"] | "fab"; component?: any }>,
  IOpenModalButtonState
> {
  static defaultProps = {
    ButtonComponent: Button,
  };
  state = {
    isOpen: false,
  };
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  open = () => this.setState({ isOpen: true });
  close = () => this.setState({ isOpen: false });

  onSubmit = async (values: any, modalApi?: FormApi) => {
    let errors = undefined;
    if (this.props.modalProps.onSubmit) {
      errors = await this.props.modalProps.onSubmit(values, modalApi!)!;
    }

    if (!errors && this._isMounted) {
      this.close();
    }

    return errors;
  };

  render() {
    const { ButtonComponent, children, label, modalProps, variant, ...rest } = this.props;
    let button = (
      <ButtonComponent
        variant={variant as ButtonProps["variant"]}
        color="primary"
        size="small"
        onClick={this.open}
        {...rest}
      >
        {label}
      </ButtonComponent>
    );
    if (variant === "fab") {
      button = (
        <Fab color="primary" size="small" onClick={this.open} {...rest}>
          {label}
        </Fab>
      );
    }
    return (
      <>
        {button}
        <ModalForm
          {...modalProps}
          onSubmit={this.onSubmit}
          isOpen={this.state.isOpen}
          onClose={this.close}
        >
          {children}
        </ModalForm>
      </>
    );
  }
}
