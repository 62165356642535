import React from "react";
import { fixedForwardRef } from "shared";
import { SvgIconComponent } from "@mui/icons-material";
import { SvgIconProps } from "@ds-proxy";
import * as MaterialIcons from "./material-icons";
import * as CustomIcons from "./custom-icons";
import { recordKeys } from "../../libs/utils/helpers";

export const iconSizes = {
  xs: 12,
  s: 16,
  m: 20,
  l: 24,
  xl: 40,
  xxl: 48,
  xxxl: 96,
};

export type IconName = keyof typeof MaterialIcons | keyof typeof CustomIcons;

export type IconSize = keyof typeof iconSizes;

export const iconSizesKeys = recordKeys(iconSizes).reduce((acc, key) => {
  acc[key] = key;
  return acc;
}, {} as Record<keyof typeof iconSizes, keyof typeof iconSizes>);

export type IconProps = SvgIconProps & {
  name: IconName;
  size?: IconSize | "inherit";
  disabled?: boolean;
};

export const Icon = fixedForwardRef(function Icon(
  { name, size: sizeProp = "l", style, ...rest }: IconProps,
  ref: React.Ref<SVGSVGElement>
) {
  const Icon =
    (MaterialIcons as Record<string, SvgIconComponent>)[name] ||
    (CustomIcons as Record<string, SvgIconComponent>)[name];
  // TODO: we need to support inherit and use fontSize, same as material-
  const size = sizeProp === "inherit" ? "inherit" : iconSizes[sizeProp];

  const sizeProps =
    sizeProp === "inherit"
      ? {
          fontSize: "inherit",
        }
      : { width: size, height: size };

  return (
    <Icon
      style={{ ...sizeProps, ...style }}
      data-size={size}
      data-testid={name}
      {...rest}
      ref={ref}
    />
  );
});
