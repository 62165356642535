import constate from "constate";
import { useMemo } from "react";
import { types } from "api";
import { GetAuthProvidersQuery } from "api/graphql";
import {
  useSAMLProviders,
  useSAMLProvidersList,
  useDefaultProviders,
  useDefaultProvidersList,
} from "./apiHooks";

const googleProviderName = "google.com";
const passwordProviderName = "password";
const googleCloudType = "google";
const samlCloudType = "saml";

export enum ProviderType {
  SAML = "SAML",
  Default = "Default",
}

export interface GenericProvider {
  id: string;
  name: string;
  enabled: boolean;
  enforce_mfa: boolean;
}

export interface DefaultProvider extends GenericProvider {
  type: ProviderType.Default;
  data: GetAuthProvidersQuery["auth_provider"][0];
}

export interface SAMLProvider extends GenericProvider {
  type: ProviderType.SAML;
  data: types.SAMLProvider;
}

export type AuthProvider = DefaultProvider | SAMLProvider;

export const [AuthProvidersProvider, useAuthProviders, useEnabledProvidersCount] = constate(
  () => {
    const {
      isLoading: isLoadingDefault,
      isFetching: isFetchingDefault,
      data: defaultData,
    } = useDefaultProvidersList();
    const {
      isLoading: isLoadingSAML,
      isFetching: isFetchingSaml,
      data: samlData,
    } = useSAMLProvidersList();

    const sortedDefaultProviders = sortDefaultProviders(defaultData?.providers || []);
    const defaultProviders: DefaultProvider[] = sortedDefaultProviders.map((provider) => ({
      id: provider.name,
      name: provider.title,
      enabled: provider.enabled,
      enforce_mfa: provider.enforce_mfa,
      data: provider,
      type: ProviderType.Default,
    }));

    const samlProviders: SAMLProvider[] =
      samlData?.providers?.map((provider) => ({
        id: provider.id,
        name: provider.name,
        enabled: provider.enabled,
        enforce_mfa: false,
        data: provider,
        type: ProviderType.SAML,
      })) || [];

    const data = [...defaultProviders, ...samlProviders];

    const enabledProvidersCount = useMemo(() => data.filter((p) => p.enabled).length, [data]);

    return {
      isLoading: isLoadingSAML || isFetchingSaml || isLoadingDefault || isFetchingDefault,
      data: [...defaultProviders, ...samlProviders],
      enabledProvidersCount,
    };
  },
  ({ isLoading, data }) => ({ isLoading, data }),
  ({ enabledProvidersCount }) => enabledProvidersCount
);

export interface AvailableAuthProvider {
  id: string;
  name: string;
  cloudType: string;
}

function getDefaultProviderCloudType(name: string): string {
  if (name === googleProviderName) {
    return googleCloudType;
  }

  return name;
}

export function useAvailableAuthProviders(): {
  providers: AvailableAuthProvider[];
  isSomeLoading: boolean;
  isAllLoading: boolean;
  isPasswordAuthEnabled: boolean;
} {
  const { data: defaultData, isLoading: isLoadingDefault } = useDefaultProviders();
  const { data: samlData, isLoading: isLoadingSAML } = useSAMLProviders();

  const sortedDefaultProviders = sortDefaultProviders(defaultData?.providers || []);
  const isPasswordAuthEnabled = sortedDefaultProviders.some(
    ({ name }) => name === passwordProviderName
  );
  const defaultProvidersWithoutPassword = sortedDefaultProviders
    .filter(({ name }) => name !== passwordProviderName)
    .map(({ name, title }) => ({
      id: name,
      name: title,
      cloudType: getDefaultProviderCloudType(name),
    }));

  const samlProviders =
    samlData?.providers?.map((provider) => ({
      ...provider,
      cloudType: samlCloudType,
    })) || [];

  return {
    providers: [...defaultProvidersWithoutPassword, ...samlProviders],
    isSomeLoading: isLoadingDefault || isLoadingSAML,
    isAllLoading: isLoadingDefault && isLoadingSAML,
    isPasswordAuthEnabled,
  };
}

function sortDefaultProviders<T extends { title: string }>(providers: T[]): T[] {
  return [...providers].sort((a, b) =>
    a.title.localeCompare(b.title, "en", { sensitivity: "base" })
  );
}
