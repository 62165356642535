import React from "react";
import { Field, FormRenderProps } from "react-final-form";
import { Box, Button, FormHelperText, Grid, LinearProgress, Typography } from "@ds-proxy";
import { Icon } from "@ds-ui";
import { TextFieldComponent } from "modules/form-utils";
import { Stack } from "libs/layouts";
import { messages } from "../messages";

export function TwoFactorAuthForm({ handleSubmit, error, submitting }: FormRenderProps<any>) {
  return (
    <form onSubmit={handleSubmit} autoComplete="on">
      {submitting && <LinearProgress />}
      <Grid container direction="column" spacing={4}>
        <Grid item>{!submitting && error && <FormHelperText error>{error}</FormHelperText>}</Grid>

        <Grid item style={{ width: "100%" }}>
          <Field
            name="twoFactorCode"
            component={TextFieldComponent as any}
            fullWidth
            label={messages.twoFACodeLabel}
          />
        </Grid>

        <Grid container item direction="row" justifyContent="space-between">
          <Button fullWidth color="primary" variant="outlined" type="submit" disabled={submitting}>
            {messages.logIn}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export function TwoFactorPhoneAuthForm({
  handleSubmit,
  error,
  submitting,
  resend,
  onCancel,
}: FormRenderProps<any> & { resend?: () => void; onCancel?: () => void }) {
  return (
    <form onSubmit={handleSubmit} autoComplete="on">
      {submitting && <LinearProgress />}
      <Stack space={3}>
        {onCancel && (
          <Box marginLeft={-3}>
            <Button color="primary" onClick={onCancel}>
              <Icon name={"ArrowBackOutlined"} fontSize="small" /> Back
            </Button>
          </Box>
        )}
        <Stack space={4}>
          <Typography variant="body2">{messages.enterVerificationCode}</Typography>
          <Stack>
            <Field
              name="twoFactorCode"
              component={TextFieldComponent as any}
              fullWidth
              label={messages.twoFAPoneCodeLabel}
            />
            {!submitting && error && <FormHelperText error>{error}</FormHelperText>}
          </Stack>
        </Stack>
        <Button
          id="mfaSendCodeButton"
          fullWidth
          color="primary"
          variant="contained"
          type="submit"
          style={{
            alignSelf: "flex-start",
          }}
          disabled={submitting}
        >
          {messages.logIn}
        </Button>
        <Button fullWidth color="primary" type="button" onClick={resend}>
          {messages.resendMFALoginCode}
        </Button>
      </Stack>
    </form>
  );
}
