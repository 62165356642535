import React from "react";
import { Avatar as AvatarMaterial } from "@ds-proxy";
import { css } from "ui/css";

export type AvatarProps = {
  className?: string;
  url?: string;
  firstName?: string | null;
  name?: string;
  lastName?: string | null;
  size?: number;
  style?: React.CSSProperties;
};

const avatarStyles = css({
  display: "flex",
  color: "var(--palette-primary-main)",
  fontSize: 16,
  fontWeight: 500,
  background: "var(--palette-background-paper)",
});

export const getUserInitials = (
  firstName: string | undefined | null,
  lastName: string | undefined | null,
  name: string | undefined
) => {
  if (firstName && lastName) {
    return firstName[0].toUpperCase() + lastName[0].toUpperCase();
  }
  if (name) {
    const nameSplitted = name.split(" ");
    return nameSplitted.length === 1
      ? nameSplitted[0].slice(0, 2).toUpperCase()
      : nameSplitted[0][0].toUpperCase() + nameSplitted[nameSplitted.length - 1][0].toUpperCase();
  }

  return null;
};

export const Avatar = ({
  className,
  url,
  firstName,
  lastName,
  name,
  size = 38,
  style,
}: AvatarProps) => {
  return (
    <AvatarMaterial
      variant="circular"
      imgProps={{ src: url, sizes: "20px 20px" }}
      style={{ width: size, height: size, ...style }}
      className={avatarStyles({ className }).className}
    >
      {!url ? getUserInitials(firstName, lastName, name) : null}
    </AvatarMaterial>
  );
};

Avatar.displayName = "Avatar";
