import { CSSProperties } from "react";
import { darken, getContrastRatio, lighten } from "@ds-proxy";

const fontSize = 14; // Default body font size 14px
const htmlFontSize = 16;
const coef = fontSize / 14;
export const pxToRem = (size: number) => `${(size / htmlFontSize) * coef}rem`;

export function generateColorGroup(color: NonNullable<CSSProperties["color"]>) {
  return {
    main: color,
    light: lighten(color, 0.6),
    dark: darken(color, 0.6),
    contrastText: getContrastRatio(color, "#fff") > 4.5 ? "#FFFFFF" : "#020617",
  };
}
