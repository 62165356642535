import React, { useEffect } from "react";
import { Can, useCanArray, usePermissions } from "shared/permissions";
import { useNavigate, useMatch } from "react-router-dom";
import { PermissionCheck } from "shared/permissionsTypes";
import { Typography } from "@ds-proxy";
import { css } from "ui/css";
import { HStack, ErrorBoundary } from "ui";
import { Permission } from "api/types";
import { Tabs, Tab, TabGroup } from "./Tabs";

const headerHeight = "46px";
const pageContentClassName = css({
  height: `calc(100% - ${headerHeight})`,
});
const settingClassName = css({
  height: "100%",
  width: "100%",
  overflow: "auto",
});

export type TabItem = {
  value: string;
  label: string;
  disabled?: boolean;
  requiredPermissions?: PermissionCheck;
  isAnyOf?: boolean;
  getPage?: () => JSX.Element;
  items?: TabItem[];
};

export interface SidebarTabsProps {
  title: string;
  tabs: TabItem[];
  baseUrl: string;
}

export function SidebarTabs({ title, tabs, baseUrl }: SidebarTabsProps) {
  const navigate = useNavigate();
  const matchTabWithId = useMatch(`${baseUrl}/:tab/:id`);
  const matchTab = useMatch(`${baseUrl}/:tab`);
  const flatTabs = tabs.reduce((acc, tab) => {
    if (!tab.items) {
      return [...acc, tab];
    }

    return acc.concat(tab.items || []);
  }, [] as TabItem[]);

  const isDoEither = (tab: TabItem) => Boolean(tab.isAnyOf);
  const { permissionsSet } = usePermissions();
  const allowedTabs = useCanArray({
    items: flatTabs,
    getItemPermission: (tab) => tab.requiredPermissions,
    isDoEither,
  });
  const allowedTabsSet = new Set(allowedTabs?.map((tab) => tab.value));

  const handleChange: (v: string) => void = (v: string) => navigate(`${baseUrl}/${v}`);

  const currentTabValue = matchTab?.params.tab || matchTabWithId?.params.tab;

  useEffect(() => {
    // open first tab by default or if user does not have permission for destination tab
    if (
      allowedTabs?.length &&
      (!currentTabValue || !allowedTabs.some((tab) => tab.value === currentTabValue))
    ) {
      handleChange(allowedTabs[0].value);
    }
  }, [currentTabValue, allowedTabs]);

  const selectedTab = flatTabs?.find((tab: TabItem) => tab.value === currentTabValue);

  const isLoading = !selectedTab || !allowedTabs;

  return (
    <div className="flex flex-col h-full">
      <div
        className="flex items-center p-l
      bg-background-base3
      border border-t-0 border-l-0 border-r-0 border-b-1 border-solid border-outlines-1"
      >
        <Typography variant="h6">{title}</Typography>
      </div>
      <HStack align="start" fullWidth className={pageContentClassName().className}>
        <Tabs
          isLoading={isLoading}
          sx={{
            margin: "var(--spacing-l)",
            marginRight: "var(--spacing-m)",
          }}
        >
          {tabs?.map((tab) => {
            if (tab.items) {
              const allowedSubtabs = tab.items.filter((subtab) => allowedTabsSet.has(subtab.value));

              if (!allowedSubtabs.length) {
                return null;
              }

              const filteredItems = (tab.items || []).filter((item) => {
                const arr = (
                  Array.isArray(item.requiredPermissions)
                    ? item.requiredPermissions
                    : [item.requiredPermissions]
                ) as Permission[];
                if (!arr.length) {
                  return true;
                }

                return arr.every((permission) => permissionsSet.has(permission));
              });

              return (
                <TabGroup
                  key={tab.value}
                  label={tab.label}
                  defaultExpanded={allowedSubtabs.some((item) => item.value === currentTabValue)}
                  items={filteredItems.map((item: any) => ({
                    ...item,
                    isSelected: currentTabValue === item.value,
                    onChange: handleChange,
                  }))}
                />
              );
            }

            if (!allowedTabsSet.has(tab.value)) {
              return null;
            }

            return (
              <Tab
                {...tab}
                key={tab.value}
                onChange={handleChange}
                isSelected={currentTabValue === tab.value}
              />
            );
          })}
        </Tabs>
        <ErrorBoundary>
          <div className={settingClassName().className}>
            {!isLoading && (
              <Can
                do={!isDoEither(selectedTab) ? selectedTab?.requiredPermissions : undefined}
                doAnyOf={isDoEither(selectedTab) ? selectedTab?.requiredPermissions : undefined}
              >
                {typeof selectedTab?.getPage === "function" && selectedTab?.getPage()}
              </Can>
            )}
          </div>
        </ErrorBoundary>
      </HStack>
    </div>
  );
}
