import React from "react";
import { Tab as MTab, TabProps } from "@ds-proxy";
import { css } from "ui/css";
export { Tabs } from "@ds-proxy";

const styles = {
  tab: css({
    zIndex: 1,
    textTransform: "uppercase !important",
  }),
};

export const Tab = function Tab(props: TabProps) {
  return <MTab {...props} className={styles.tab({ className: props.className }).className} />;
};
