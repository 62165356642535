import React from "react";
import { GridColumnHeaderTitle, GridColumnHeaderParams } from "@mui/x-data-grid-pro";
import { IconButton, IconButtonProps } from "@ds-proxy";
import { Icon } from "@ds-ui";
import { useExpandCollapseAll } from "../useExpandCollapseAll";

export type GridDetailPanelHeaderProps = GridColumnHeaderParams & {
  collapseAllInactiveTitle?: string;
  collapseAllIconButtonProps?: Omit<IconButtonProps, "onClick">;
};

export function GridDetailPanelHeader({
  colDef,
  collapseAllIconButtonProps,
}: GridDetailPanelHeaderProps) {
  const { expandedRowIds, collapseAll } = useExpandCollapseAll();

  if (expandedRowIds.length < 2) {
    return (
      colDef.headerName && (
        <GridColumnHeaderTitle label={colDef.headerName} columnWidth={colDef.computedWidth} />
      )
    );
  }

  return <CollapseAllButton onClick={collapseAll} {...collapseAllIconButtonProps} />;
}

function CollapseAllButton(props: IconButtonProps) {
  return (
    <IconButton
      className="grid-detail-panel-collapse-all"
      data-testid="grid-detail-panel-collapse-all"
      tabIndex={-1}
      aria-label="Collapse All"
      {...props}
    >
      <Icon name="VerticalAlignCenterOutlined" size="m" />
    </IconButton>
  );
}
