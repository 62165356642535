import React, { HtmlHTMLAttributes } from "react";
import { PolymorphicComponentProps } from "react-polymorphic-box";
// eslint-disable-next-line no-restricted-imports
import { theme } from "@ds-proxy/theme";
import { HStack, Stack } from "libs/layouts";

type CardPropsBase = HtmlHTMLAttributes<HTMLDivElement> & { space?: number };
export type CardProps<E extends React.ElementType = "div"> = PolymorphicComponentProps<
  E,
  CardPropsBase
>;

type SpaceKey = keyof typeof theme.customSpacing;
export function Card<E extends React.ElementType = "div">({
  space = 3,
  children,
  ...rest
}: CardProps<E>) {
  return (
    // @ts-ignore
    <Stack
      sx={{
        paddingX: theme.customSpacing[(space + 1) as unknown as SpaceKey],
        paddingY: theme.customSpacing[space as unknown as SpaceKey],
      }}
      as="div"
      space={space}
      {...rest}
    >
      {children}
    </Stack>
  );
}

export function CardActions({
  space = 3,
  children,
  secondary,
}: {
  space?: number;
  children: React.ReactNode;
  secondary?: JSX.Element;
}) {
  return (
    <HStack space={space}>
      {secondary}
      <HStack.Spacer />
      {children}
    </HStack>
  );
}
