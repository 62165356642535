import React, { useEffect } from "react";
import { CHIcon4 } from "assets/icons";
import { useLocation } from "react-router";
import { Dialog, Button, Stack, DialogContent, Typography } from "@ds-proxy";
import { useModal, useStorageWithExpiration } from "libs/hooks";
import { subscribeOnVersionChange } from "./setupVersionCheck";
import { ROUTES_PATHS } from "./routesPaths";

const IGNORE_TIMEOUT = 1000 * 60 * 2;
export function NewVersionHandler() {
  // If user dismissed update, then do not bother with popup for IGNORE_TIMEOUT
  const [getIsIgnore, setIsIgnore] = useStorageWithExpiration(
    "ignore-version-updates",
    false,
    IGNORE_TIMEOUT
  );

  const [modal, modalApi] = useModal(({ extraProps: _, ...args }) => {
    const onDismiss = () => {
      setIsIgnore(true);
      args.onClose();
    };

    const onRefresh = () => {
      setIsIgnore(true);
      window.location.reload();
    };

    return (
      <Dialog
        {...args}
        PaperProps={{ style: { maxWidth: "400px" } }}
        fullWidth
        disableEscapeKeyDown
      >
        <DialogContent>
          <Stack gap={2} className={"text-center"}>
            <div className={"text-center"}>
              <CHIcon4 style={{ width: "1em", height: "1em", fontSize: "50px" }} />
            </div>
            <Typography variant="h6">New version is available!</Typography>
            <Typography>
              We kindly request that you click on the Refresh button to access the latest version of
              our product and enjoy an enhanced user experience
            </Typography>
            <Stack gap={1}>
              <Button fullWidth color="primary" variant={"contained"} onClick={onRefresh}>
                Refresh
              </Button>
              <Button color="secondary" fullWidth onClick={onDismiss}>
                Dismiss
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  }, true);

  useEffect(
    () =>
      subscribeOnVersionChange(() => {
        if (!getIsIgnore()) {
          modalApi.handleOpen();
        }
      }),
    []
  );

  const location = useLocation();
  useEffect(() => {
    // If user was logged out due to inactivity and did not accept update, then we can safely refresh page
    if (modalApi.open && location.pathname.includes(ROUTES_PATHS.LOGIN)) {
      window.location.reload();
    }
  }, [modalApi.open, location.pathname]);

  return <>{modal}</>;
}
