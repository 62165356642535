import React from "react";
import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps } from "@ds-proxy";

interface CheckboxProps extends MuiCheckboxProps {}

export function Checkbox(props: CheckboxProps) {
  return (
    <MuiCheckbox
      {...props}
      disableRipple
      disableFocusRipple
      disableTouchRipple
      classes={{
        root: "!bg-transparent text-interactive-positive",
        disabled: "!text-interactive-disabled",
      }}
    />
  );
}
