import { ResizeObserver } from "@juggle/resize-observer";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "assets/styles/index.css";
import "assets/styles/tailwind.css";
import "./app/setupExternalServices";
import "./app/setupVersionCheck";
window.ResizeObserver = ResizeObserver;
import { overrideInsert } from "@shared/fullstory-styles-fix";
overrideInsert();

function initApp() {
  import(
    /* webpackChunkName: "app"  */
    /* webpackMode: "eager"*/
    "./app/main"
  ).then(({ main }) => main());
}

if (process.env.ENABLE_REACT_SCAN === "true" && process.env.NODE_ENV === "development") {
  import("react-scan").then(({ scan }) => {
    scan({
      enabled: true,
    });
    initApp();
  });
} else {
  initApp();
}
