import React from "react";
import classNames from "classnames";
import {
  GridRenderCellParams,
  gridClasses,
  gridDetailPanelExpandedRowsContentCacheSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { IconButton } from "@ds-proxy";
import { Icon } from "@ds-ui";
import { DETAIL_PANEL_COLLAPSE_ICON, DETAIL_PANEL_EXPAND_ICON } from "../constants";

export function GridDetailPanelToggle(props: Pick<GridRenderCellParams, "id" | "value">) {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = React.isValidElement(contentCache[id]);

  return (
    <IconButton
      data-testid="grid-detail-panel-toggle"
      className={classNames(gridClasses.detailPanelToggleCell, {
        [gridClasses["detailPanelToggleCell--expanded"]]: isExpanded,
      })}
      variant="subtle"
      color="primary"
      size="small"
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? "Close" : "Open"}
    >
      <Icon
        name={isExpanded ? DETAIL_PANEL_COLLAPSE_ICON : DETAIL_PANEL_EXPAND_ICON}
        fontSize="inherit"
      />
    </IconButton>
  );
}
