import { types, riskydashboard } from "api";
import { aggs } from "api/gen";

export type UseDatasetCounts = {
  onlyCounts?: aggs.PreviewDataset | null | undefined;
  isEnabled?: boolean;
  previewPolicy?: riskydashboard.PreviewCategory;
};

export type DatasetInternal = {
  id?: string;
  name: string;
  description?: string;
  sensitivity?: number;
  query: types.Query;
  rules: DatasetRuleInternal[];
  count?: number;
};

export type CategoryInternal = {
  id?: string;
  name: string;
  description?: string;
  query: types.Query;
  default_rule: RuleInternal;
  dataset_rules: DatasetRuleInternal[];
};

export type DataSetListItem = riskydashboard.DatasetDTO & {
  count: number;
  id?: string;
  nonEditable?: boolean;
};

export type RuleInternal = Pick<types.Rule, "status" | "id" | "create_incident"> & {
  email: Pick<types.Rule, "notify_enabled" | "notify_email" | "notify_status">;
  response: Pick<
    types.Rule,
    | "blocking_action"
    | "show_logo"
    | "require_justification"
    | "allow_request_review"
    | "should_ack_warning"
    | "incident_action"
    | "warning_dialog"
    | "blocking_dialog"
    | "show_title"
  >;
};

export type DatasetRuleInternal = {
  name: string;
  category: types.Category;
  default_rule: RuleInternal;
  exceptions: ExceptionInternal[];
};

export type CategoryRuleInternal = RuleInternal & {
  name?: string;
};

export type ExceptionInternal = {
  flows: FlowsValues;
  rule: RuleInternal;
};

export type DateRange = "hourly" | "daily" | "weekly" | "monthly";

export type AddUsersValue = {
  user_query?: string;
  group_query?: string;
  all?: boolean;
};

export type FlowsValues = {
  selection: "all" | "advanced" | "exceptOrigin";
};

export type DatasetPreviewFeDTO = riskydashboard.DatasetDTO;

export type CategoryPreviewFeDTO = types.Category;

export type ExpandableLocation = {
  subitems_size: number;
} & types.ExpandableLocationItem;

export enum LineaIncidentCreationOption {
  LineaOnly = "linea_only",
  Always = "always",
  Never = "never",
}

export enum ActivityTypeFilterOption {
  /* All activity types except `dar_scan` */
  DataActivity = "data_activity",
  /* `dar_scan` activity type */
  ScanActivity = "scan_activity",
}
