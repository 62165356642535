import React, { ForwardedRef, forwardRef } from "react";
// TODO: There is a bug with the TextFieldProps type, needs fixing, make it generic
// eslint-disable-next-line no-restricted-imports
import { TextFieldProps, TextField, TextFieldVariants } from "@mui/material";

export type InputSelectProps<Variant extends TextFieldVariants = TextFieldVariants> = Omit<
  TextFieldProps<Variant>,
  "select"
>;

export const InputSelect = forwardRef(function InputSelect<Variant extends TextFieldVariants>(
  props: InputSelectProps<Variant>,
  ref: ForwardedRef<HTMLInputElement>
) {
  return <TextField ref={ref} select {...props} />;
});
