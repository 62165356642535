import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  gridClasses,
  GridFilterOperator,
} from "@mui/x-data-grid-pro";
import { GridFilterAutocomplete } from "./organisms/GridFilterAutocomplete";

export const CHECKBOX_COLUMN_ID = GRID_CHECKBOX_SELECTION_COL_DEF.field;

export const customGridClasses = {
  actionsHeader: `${gridClasses.columnHeader}--actions`,
  detailPanelHeader: `${gridClasses.columnHeader}--detailPanelHeader`,
  filterIconButton: `filterIconButton`,
  filterIconButtonActive: `filterIconButton--active`,
  highlightCell: `${gridClasses.cell}--highlight`,
  zeroNumberCell: `${gridClasses.cell}--zero`,
};

export const GRID_OPERATORS: Record<"notContains", GridFilterOperator> = {
  notContains: {
    label: "does not contain",
    value: "doesNotContain",
    getApplyFilterFn: () => () => true,
    InputComponent: GridFilterAutocomplete,
  },
};

export const FIELDS_ALWAYS_IN_BEGINNING_ORDERED = [
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field,
  GRID_CHECKBOX_SELECTION_COL_DEF.field,
];

export const PICKED_ROW_CLASS_NAME = "Mui-selected";
export const DISABLED_ROW_CLASS_NAME = "rowDisabled";

export const DETAIL_PANEL_EXPAND_ICON = "KeyboardArrowRightOutlined" as const;
export const DETAIL_PANEL_COLLAPSE_ICON = "KeyboardArrowDownOutlined" as const;
