import React from "react";
import { Typography } from "@ds-proxy";
import { Icon } from "@ds-ui";
import { styled, css } from "ui/css";
import { messages } from "ui/messages";

const StyledFigure = styled("figure", {
  "margin": "auto",
  "textAlign": "center",
  "color": "var(--text-secondary)",
  "& > svg": { fontSize: "100px" },
});

export const containerStyles = css({
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export function NoItemsView({
  children,
  icon,
}: {
  children?: React.ReactNode;
  icon?: React.ReactChild;
}) {
  return (
    <StyledFigure>
      {icon}
      <figcaption>{children}</figcaption>
    </StyledFigure>
  );
}
NoItemsView.defaultProps = {
  icon: <Icon name={"ErrorOutlined"} />,
};
const defaultProps = {
  title: messages.noItemsFound.title,
  subtitle: messages.noItemsFound.subtitle,
  icon: <Icon name={"ErrorOutlined"} />,
};

type NoItemsFullpageProps = {
  "className"?: string;
  "children"?: React.ReactChild;
  "role"?: string;
  "aria-label"?: string;
} & Partial<typeof defaultProps>;

export const NoItemsFullpage = ({
  className,
  children,
  title,
  subtitle,
  icon,
  ...rest
}: NoItemsFullpageProps) => {
  return (
    <div {...rest} role="presentation" className={containerStyles({ className }).className}>
      <NoItemsView icon={icon}>
        {children}
        <Typography color="inherit">{title}</Typography>
        <Typography color="inherit">{subtitle}</Typography>
      </NoItemsView>
    </div>
  );
};

NoItemsFullpage.defaultProps = defaultProps;
