import { gridClasses } from "@mui/x-data-grid-pro";
import { SxProps } from "@mui/system";
import { Theme } from "@ds-proxy/mui-proxy/styles";
import { mergeDeep } from "@libs/utils/mergeDeep";
import { GridDisplayState } from "./organisms";
import { DISABLED_ROW_CLASS_NAME, customGridClasses } from "./constants";
import { newDesign } from "./theme";

const is = (selectors: string[]) => `:is(${selectors.join(", ")})`;

const COLUMNS_SELECTOR = is([
  `.${gridClasses.pinnedColumns}`,
  `.${gridClasses.virtualScrollerRenderZone}`,
]);

export const CSS_SELECTORS = {
  columns: COLUMNS_SELECTOR,
  leftmostColumns: `${COLUMNS_SELECTOR}:first-child`,
  rightmostColumns: is([
    `${COLUMNS_SELECTOR}:last-child`,
    `.${gridClasses.virtualScrollerContent}:has(.${gridClasses.detailPanel}) ${COLUMNS_SELECTOR}:nth-last-child(2)`,
  ]),
  specialColumnHeader: is([
    `.${gridClasses.columnHeaderCheckbox}`,
    `.${customGridClasses.detailPanelHeader}`,
    `.${customGridClasses.actionsHeader}`,
  ]),
};

const ACTIONS_BUTTON_HOVER_DARKENING_FACTOR = 0.01;
const COLUMN_HEADER_OUTLINE_OFFSET = 2;
const CHECKBOX_PADDING = 9; // TODO - unhardcode this

export const newDesignSystemStyles =
  (customSx?: (style: SxProps<Theme>) => SxProps<Theme>) =>
  (style: any, { alignTop, pinnedColumns, hasActions, scroll }: GridDisplayState) => {
    return [
      {
        "--ch-data-grid-bgcolor": "var(--background-base2)",
        "border": "none",
        [`& .${DISABLED_ROW_CLASS_NAME} .${gridClasses.cell}:not([data-field="__detail_panel_toggle__"]):not(.${gridClasses.cellCheckbox}) > *`]:
          {
            opacity: 0.5,
          },
        [`& .${gridClasses.virtualScroller}`]: {
          flexGrow: 1,
        },
        [`& .${gridClasses.virtualScrollerRenderZone}`]: {
          maxWidth: "100%",
        },
        [`& .${gridClasses.pinnedColumns}`]: {
          minHeight: "0 !important", // not sure what it is for
          overflow: "visible", // // not sure what it is for
          background: "transparent",
          display: "flex",
          flexDirection: "column",
          [`&.${gridClasses["pinnedColumns--right"]}`]: {
            zIndex: pinnedColumns.hasRight || hasActions ? 1 : -1, // not sure we need this
          },
        },
        [`& ${CSS_SELECTORS.leftmostColumns} .${gridClasses.cell}:first-child`]: {
          borderRadius: "4px 0 0 4px",
          borderLeftWidth: "1px",
          borderLeftStyle: "solid",
        },
        [`& ${CSS_SELECTORS.rightmostColumns} .${gridClasses.cell}:last-child`]: {
          borderRadius: "0 4px 4px 0",
          borderRightWidth: "1px",
          borderRightStyle: "solid",
        },
        // TODO - Right spacing only works when right pinned columns are present.
        // Also master detail does not have right padding
        ...(scroll.vertical.visible && {
          [`& :is(.${gridClasses.columnHeadersInner}, .${gridClasses.pinnedColumnHeaders}):last-child`]:
            {
              borderRight: "8px solid transparent",
            },
          [`& ${CSS_SELECTORS.rightmostColumns}`]: {
            borderRight: "8px solid var(--ch-data-grid-bgcolor)",
          },
          [`& .${gridClasses["pinnedColumns--right"]} + .${gridClasses.detailPanels}`]: {
            // detail panels use the spacing from the non-pinned columns only
            borderRight: "8px solid var(--ch-data-grid-bgcolor)",
          },
        }),
        ...((!scroll.horizontal.visible || scroll.horizontal.position === "start") && {
          [`& .${gridClasses["pinnedColumns--left"]}`]: {
            boxShadow: "none",
          },
        }),
        ...((!scroll.horizontal.visible || scroll.horizontal.position === "end") && {
          [`& .${gridClasses["pinnedColumns--right"]}`]: {
            boxShadow: "none",
          },
        }),
        [`& .${gridClasses.columnHeaders}`]: {
          color: "var(--text-secondary)",
          border: "1px solid var(--outlines-1)",
          fontSize: "var(--font-size-l)",
          zIndex: 2,
          boxShadow: "var(--box-shadow-shadow1)",
        },
        [`& .${gridClasses["pinnedColumnHeaders--left"]}`]: {
          left: -1, // fixes shadow alignment
          ...((!scroll.horizontal.visible || scroll.horizontal.position === "start") && {
            boxShadow: "none",
          }),
          [`& .${gridClasses.columnHeader}:last-child`]: {
            ...(scroll.horizontal.visible &&
              scroll.horizontal.position !== "start" && {
                borderRightColor: "transparent",
              }),
          },
        },
        [`& .${gridClasses["pinnedColumnHeaders--right"]}`]: {
          backgroundColor: "var(--background-base3)",
          color: "var(--text-secondary)",
          fontSize: "var(--font-size-m)",
          right: -1, // fixes shadow alignment
          ...((!scroll.horizontal.visible || scroll.horizontal.position === "end") && {
            boxShadow: "none",
          }),
          [`& .${gridClasses.columnHeader}:first-child`]: {
            borderLeft: `1px solid transparent`,
            ...((!scroll.horizontal.visible || scroll.horizontal.position === "end") && {
              borderLeftColor: "var(--outlines-2)",
            }),
          },
          [`& .${gridClasses.columnHeader}:last-child`]: {
            borderRight: "none",
          },
        },
        [`& .${gridClasses.columnHeader}`]: {
          outline: "none !important",
          color: "var(--text-secondary)",
          backgroundColor: "var(--background-base3)",
          borderColor: "var(--outlines-2)",
          fontSize: "var(--font-size-m)",
          padding: "0 var(--spacing-xl)",
          [`&.${gridClasses.columnHeaderCheckbox}`]: {
            padding: 0,
          },
          [`&:not(${CSS_SELECTORS.specialColumnHeader}) .${gridClasses.iconButtonContainer}`]: {
            paddingLeft: `${COLUMN_HEADER_OUTLINE_OFFSET}px`,
            paddingRight: `${COLUMN_HEADER_OUTLINE_OFFSET}px`,
            ["&:not(:first-child)"]: {
              marginLeft: "var(--spacing-xs)",
            },
          },
          [`&:not(:hover) .${gridClasses.iconButtonContainer}:has(.${customGridClasses.filterIconButton}:not(.${customGridClasses.filterIconButtonActive}))`]:
            {
              display: "none",
            },
          // TODO [grid] remove this once we update to v7 of the grid and we are able to use the slotProps for the sortIconButton
          ["& .MuiDataGrid-iconButtonContainer > button.MuiIconButton-sizeSmall"]: {
            padding: 0,
            width: "unset",
            height: "unset",
          },
          [`&.${gridClasses["columnHeader--sorted"]} button:not(.${customGridClasses.filterIconButton})[data-tempBtnHack='tempBtnHack']`]:
            {
              outline: `${COLUMN_HEADER_OUTLINE_OFFSET}px solid var(--outlines-focus)`,
            },
          [`& .${customGridClasses.filterIconButtonActive}[data-tempBtnHack='tempBtnHack']`]: {
            outline: `${COLUMN_HEADER_OUTLINE_OFFSET}px solid var(--outlines-focus)`,
          },
        },
        // columns selector needed to have enough speecificity on all row states
        [`& ${CSS_SELECTORS.columns} .${gridClasses.row}`]: {
          // Overrides default mui bg for rows which we apply on cells directly so we can have border radius.
          // Need a solid color otherwise content can be seen through the remaining space near the curvature
          // of the border.
          "backgroundColor": "var(--ch-data-grid-bgcolor) !important",
          "&.Mui-hovered": {
            cursor: "auto",
            [`& .${gridClasses.cell}`]: {
              backgroundColor: "var(--background-base3)",
              [`& .${gridClasses.actionsCell}`]: {
                filter: `brightness(${1 - ACTIONS_BUTTON_HOVER_DARKENING_FACTOR})`,
              },
            },
          },
          "&.Mui-selected": {
            [`& .${gridClasses.cell}`]: {
              backgroundColor: "var(--background-idle)",
              borderColor: `var(--outlines-2)`,
            },
            "&.Mui-hovered": {
              [`& .${gridClasses.cell}`]: {
                backgroundColor: `color-mix(
                  in srgb, 
                  var(--background-idle) 100%, 
                  black ${ACTIONS_BUTTON_HOVER_DARKENING_FACTOR * 100}%
                )`,
                [`& .${gridClasses.actionsCell}`]: {
                  filter: `brightness(${1 - ACTIONS_BUTTON_HOVER_DARKENING_FACTOR})`,
                },
              },
            },
          },
        },
        [`& .${gridClasses.cell}`]: {
          outline: "none !important",
          backgroundColor: "var(--background-base2)",
          color: "var(--text-secondary)",
          fontSize: "var(--font-size-m)",
          borderColor: "var(--outlines-1)",
          // need border top since we have spacing between rows
          borderTopWidth: "1px",
          borderTopStyle: "solid",
          paddingTop: alignTop ? "var(--spacing-l)" : 0,
          paddingBottom: alignTop ? "var(--spacing-l)" : 0,
          // not sure why I need to add 2px but looks bad without it
          paddingLeft: `calc(var(--spacing-xl) + 2px)`,
          paddingRight: "var(--spacing-xl)",
          ...(alignTop && {
            alignItems: "start",
            lineHeight: "1.8",
          }),
          [`&.${gridClasses.cellCheckbox}`]: {
            padding: 0,
            ...(alignTop && {
              paddingTop: `calc(var(--spacing-l) - ${CHECKBOX_PADDING * (3 / 4)}px)`,
              paddingBottom: `calc(var(--spacing-l) - ${CHECKBOX_PADDING * (3 / 4)}px)`,
            }),
            ["& .Mui-disabled"]: {
              // I think this should be part of the checkbox component
              color: "var(--interactive-disabled) !important",
            },
          },
        },
        [`& .${gridClasses.detailPanel}`]: {
          backgroundColor: "var(--ch-data-grid-bgcolor)",
          padding: `${newDesign.rowSpacing}px 0`,
        },
        [`& .${gridClasses.toolbarContainer}`]: {
          padding: 0,
          marginBottom: "21px",
          height: "auto",
        },
      } satisfies SxProps<Theme>,
      customSx?.(style) ?? {},
    ].reduce(mergeDeep, {});
  };
