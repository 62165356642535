import { uniq, difference } from "lodash";
import { useMemo, useCallback } from "react";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridPinnedColumns } from "@mui/x-data-grid-pro";
import { useGrid } from "./useGrid";
import { CHGridColDef } from "./types";

export const ALWAYS_PINNED_COLUMNS = {
  left: [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field, GRID_CHECKBOX_SELECTION_COL_DEF.field],
  right: ["actions"],
};

export type UseColumnPinningOptions = {
  columns: CHGridColDef[];
  columnsOrder: string[];
};

export function useColumnsPinning({ columns, columnsOrder }: UseColumnPinningOptions) {
  const { pinnedColumns, setPinnedColumns } = useGrid();
  const columnFieldsSet = useMemo(() => new Set(columns.map((column) => column.field)), [columns]);

  const normalizedPinnedColumns = useMemo(() => {
    const rejectMissing = (fields: string[]) =>
      fields.filter((field) => columnFieldsSet.has(field));

    return {
      left: rejectMissing(uniq([...ALWAYS_PINNED_COLUMNS.left, ...(pinnedColumns?.left || [])])),
      right: rejectMissing(uniq([...(pinnedColumns?.right || []), ...ALWAYS_PINNED_COLUMNS.right])),
    };
  }, [columnFieldsSet, pinnedColumns]);

  const onPinnedColumnsChange = useCallback(
    (nextPinnedColumns: GridPinnedColumns) => {
      const left = difference(nextPinnedColumns?.left || [], ALWAYS_PINNED_COLUMNS.left);
      const right = difference(nextPinnedColumns?.right || [], ALWAYS_PINNED_COLUMNS.right);
      setPinnedColumns({ left, right });
    },
    [pinnedColumns, columnsOrder, setPinnedColumns]
  );

  return [normalizedPinnedColumns, onPinnedColumnsChange] as const;
}
