import { MutableRefObject, useState, useEffect } from "react";
import { GridApiPro } from "@mui/x-data-grid-pro";

export type ScrollPosition = "start" | "middle" | "end";

/**
 * Returns the horizontal scroll position within the data grid.
 * When the virtualScrollerElement is not yet available, returns undefined.
 * When there is no horizontal overflow, returns "start".
 */
export function useHorizontalScrollPosition(
  apiRef: MutableRefObject<GridApiPro>,
  virtualScrollerElement: HTMLDivElement | null
) {
  const [horizontalScrollPosition, setHorizontalScrollPosition] = useState<ScrollPosition>();

  useEffect(() => {
    if (!virtualScrollerElement) {
      return;
    }

    return apiRef.current.subscribeEvent("scrollPositionChange", ({ left: scrollLeft }) => {
      let nextHorizontalScrollPosition: ScrollPosition;
      if (scrollLeft === 0) {
        nextHorizontalScrollPosition = "start";
      } else if (
        virtualScrollerElement.scrollWidth - virtualScrollerElement.clientWidth - scrollLeft <
        1
      ) {
        nextHorizontalScrollPosition = "end";
      } else {
        nextHorizontalScrollPosition = "middle";
      }

      if (nextHorizontalScrollPosition !== horizontalScrollPosition) {
        setHorizontalScrollPosition(nextHorizontalScrollPosition);
      }
    });
  }, [apiRef, virtualScrollerElement, horizontalScrollPosition]);

  return horizontalScrollPosition;
}
