import { MutableRefObject } from "react";
import { useHasScrollbar } from "@libs/hooks";
import { useIsScrolled } from "@libs/hooks/useIsScrolled";
import { GridApiPro } from "@mui/x-data-grid-pro";
import { ScrollPosition, useHorizontalScrollPosition } from "./useHorizontalScrollPosition";

export interface ScrollState {
  isScrolled: boolean;
  horizontal: { visible: boolean; position?: ScrollPosition };
  vertical: { visible: boolean };
}

export function useScrollState(
  apiRef: MutableRefObject<GridApiPro>,
  virtualScrollerElement: HTMLDivElement | null
): ScrollState {
  const isScrolled = useIsScrolled(virtualScrollerElement);
  const scrollbarPresence = useHasScrollbar(virtualScrollerElement);
  const horizontalScrollPosition = useHorizontalScrollPosition(apiRef, virtualScrollerElement);

  return {
    isScrolled,
    horizontal: {
      visible: scrollbarPresence.horizontal,
      position: horizontalScrollPosition,
    },
    vertical: { visible: scrollbarPresence.vertical },
  };
}
