import React, { FunctionComponent } from "react";
import { FieldRenderProps } from "react-final-form";
import { InputAdornment, TextField } from "@ds-proxy";
import { Icon } from "@ds-ui";
import { mapProps } from "ui";

export const EmailField: FunctionComponent<React.PropsWithChildren<FieldRenderProps>> = (props) => (
  <TextField
    {...mapProps(props)}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <Icon name={"PersonOutline"} />
        </InputAdornment>
      ),
    }}
  />
);

export const EmailFieldNoIcon: FunctionComponent<React.PropsWithChildren<FieldRenderProps>> = (
  props
) => <TextField {...mapProps(props)} />;
