import React, { forwardRef } from "react";
import { Button, ButtonProps } from "@ds-proxy";
import { css } from "ui/css";

const gridButtonStyles = css({
  lineHeight: 1,
  borderRadius: "4px",
  textTransform: "capitalize",
  padding: "7px 10px",
  height: "36px",
  variants: {
    color: {
      primary: {
        "color": "var(--palette-primary-main)",
        "borderColor": "var(--palette-primary-light)",
        "&:hover": {
          backgroundColor: "var(--palette-primary-dark)",
          borderColor: "var(--palette-primary-main)",
        },
      },
      secondary: {
        "color": "var(--palette-secondary-main)",
        "borderColor": "var(--palette-secondary-light)",
        "opacity": 0.6,
        "&:hover": {
          borderColor: "var(--palette-secondary-main)",
        },
      },
    },
  },
});

export const GridToolbarButton = forwardRef(function GridToolbarButton(
  props: ButtonProps,
  ref: any
) {
  return (
    <Button
      variant="outlined"
      ref={ref}
      className={
        gridButtonStyles({ color: (props.color as "primary" | "secondary") || "primary" }).className
      }
      {...props}
      size="medium"
    />
  );
});
