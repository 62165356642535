import { DeepPartial } from "redux";
import { ENV_LOGGED } from "runenv";
import { upperFirst } from "lodash";
import {
  types_CategoryStatusEnum,
  types_IncidentActionEnum,
  riskydashboard,
  types,
  types_PolicyTypeEnum,
} from "api/gen";

// DAT-3252: Skip logo data because we use hardcoded warning icon on the endpoint until DAT-3199 is resolved
export const dialogMessages = (customer: string = "customer", type = "Warning") => ({
  title: `${type}: violation of ${customer ? customer + " " : ""}security policy`,
  explanation: `The action you just performed violates ${
    customer ? customer + " " : ""
  }security policies`,

  placeholder: `${
    type === "Warning" ? "Optional:" : ""
  } Please, provide a justification for your action`,

  check_text: "I acknowledge that I have read and understood this message",
  submit_label: "I UNDERSTAND",
  review_check_text: "I received this by mistake, please review and update this policy",
  allow_label: "ALLOW",
});

export const policiesFormDefaults = {
  show_title: true,
  warning_dialog: {
    ...dialogMessages(upperFirst(ENV_LOGGED?.CUSTOMER_NAME)),
  },
  blocking_dialog: {
    ...dialogMessages(upperFirst(ENV_LOGGED?.CUSTOMER_NAME), "Blocking"),
  },
  // DAT-3252: always show logo
  show_logo: true,
};

export const protectionPolicyDefaults: types.Category = {
  type: types_PolicyTypeEnum.PolicyTypeDataProtection,
  name: "",
  dataset_ids: [],
  selection_type: "dataset",
  exclude_origin: false,
  query: {
    rules: [],
  },
  // Low
  severity: 1,
  rule: {
    ...policiesFormDefaults,
    create_incident: false,
    notify_email: "",
    notify_enabled: false,
    incident_action: types_IncidentActionEnum.IncidentActionNone,
    notify_status: "",
    status: types_CategoryStatusEnum.CategoryStatusRisky,
  },
};

export const inspectionPolicyDefaults: types.Category = {
  type: types_PolicyTypeEnum.PolicyTypeContentInspection,
  name: "",
  dataset_ids: [],
  selection_type: "dataset",
  exclude_origin: false,
  query: {
    rules: [],
  },
  severity: null,
  rule: {
    show_title: false,
    show_logo: false,
    disable_ai_incidents: true,
    create_incident: false,
    notify_email: "",
    notify_enabled: false,
    incident_action: types_IncidentActionEnum.IncidentActionNone,
    notify_status: "",
    status: types_CategoryStatusEnum.CategoryStatusAny,
  },
};

// in defaults we're using customer name that loads async after login
export function updatePolicyDefaults() {
  policiesFormDefaults.warning_dialog = {
    ...dialogMessages(upperFirst(ENV_LOGGED.CUSTOMER_NAME)),
  };
  policiesFormDefaults.blocking_dialog = {
    ...dialogMessages(upperFirst(ENV_LOGGED.CUSTOMER_NAME), "Blocking"),
  };

  protectionPolicyDefaults.rule = {
    ...policiesFormDefaults,
    create_incident: false,
    notify_email: "",
    notify_enabled: false,
    incident_action: types_IncidentActionEnum.IncidentActionNone,
    notify_status: "",
    status: types_CategoryStatusEnum.CategoryStatusRisky,
  };
}

export const datasetDefaults: DeepPartial<riskydashboard.DatasetDTO> = {
  name: "",
  category_ids: [],
  sensitivity: 1,
};

export const createTypeEnum = {
  search: "search",
  new: "new",
};

export const POLICY_INCLUDE_DISABLED = true;
