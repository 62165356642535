// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader-module__wrapper--Tcn8g {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Loader-module__pageProgress--s4oP6 {
  position: fixed;
  top: 0;
  left: 0;
  height: 4px;
  z-index: 1302;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/ui/atoms/Loader/Loader.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;EACb,WAAW;AACb","sourcesContent":[".wrapper {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.pageProgress {\n  position: fixed;\n  top: 0;\n  left: 0;\n  height: 4px;\n  z-index: 1302;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Loader-module__wrapper--Tcn8g`,
	"pageProgress": `Loader-module__pageProgress--s4oP6`
};
export default ___CSS_LOADER_EXPORT___;
