import { cloneDeep } from "lodash";
import { types, types_DeviceTypeEnum, types_LocationEnum, types_SearchFieldEnum } from "api/gen";

/**
 * The Endpoint location type has two new children: Managed and Unmanaged.
 * The FE code handles these two children as different values that can be assigned to the
 * Location field.
 * However, for the BE code, Managed and Unmanaged are not location types, but device types.
 * Changing the way the FE handles these values in the UI would require a lot of changes.
 * Instead, we can hackishly insert a new condition for the device type field and change the
 * Location field to Endpoint.
 * This function should be used in `apiHooks` before calling the API.
 */
export function hackishlyInsertDeviceTypeCondition<
  T extends { query: types.Query } | null | undefined
>(data: T): T {
  if (!data || !data?.query?.rules?.length) {
    return data;
  }

  return {
    ...data,
    query: {
      ...data.query,
      rules: data.query.rules.map((rule) => {
        if (!rule.conditions?.length) {
          return rule;
        }

        const deviceTypeIndex = rule.conditions.findIndex(
          (cond) =>
            cond.field_name === types_SearchFieldEnum.SearchFieldLocationCategory &&
            (cond.values?.[0]?.value === types_DeviceTypeEnum.DeviceTypeManaged ||
              cond.values?.[0]?.value === types_DeviceTypeEnum.DeviceTypeUnmanaged)
        );

        if (deviceTypeIndex === -1) {
          return rule;
        }

        const condition = rule.conditions[deviceTypeIndex];

        const newRule = {
          ...rule,
          conditions: [...rule.conditions],
        };
        newRule.conditions.splice(
          deviceTypeIndex,
          1,
          {
            ...condition,
            field_name: types_SearchFieldEnum.SearchFieldLocationCategory,
            values: [{ value: types_LocationEnum.LocationEndpoint }],
          },
          {
            ...condition,
            field_name: types_SearchFieldEnum.SearchFieldDeviceType,
          }
        );

        return newRule;
      }),
    },
  };
}

/**
 * The Endpoint location type has two new children: Managed and Unmanaged.
 * The FE code handles these two children as different values that can be assigned to the
 * Location field.
 * However, for the BE code, Managed and Unmanaged are not location types, but device types.
 * Changing the way the FE handles these values in the UI would require a lot of changes.
 * When a Dataset or Location uses the Device Type field, we need to remove it from the conditions
 * array and change the Location field value to the Device Type field value, so the UI works as
 * expected.
 * This function should be used when filters are initialized with the API response.
 */
export function hackishlyRemoveDeviceTypeCondition<
  T extends { query: types.Query } | null | undefined
>(data: T): T {
  if (!data || !data?.query?.rules?.length) {
    return data;
  }

  return {
    ...data,
    query: {
      ...data.query,
      rules: data.query.rules.map((rule: types.QueryRule) => {
        if (!rule.conditions?.length) {
          return rule;
        }

        const deviceTypeIndex = rule.conditions.findIndex(
          (cond: types.QueryCondition) =>
            cond.field_name === types_SearchFieldEnum.SearchFieldDeviceType
        );
        const locationIndex = rule.conditions.findIndex(
          (cond: types.QueryCondition) =>
            cond.field_name === types_SearchFieldEnum.SearchFieldLocationCategory
        );

        if (deviceTypeIndex === -1 || locationIndex === -1) {
          return rule;
        }

        const newRule = cloneDeep(rule);
        if (newRule.conditions) {
          newRule.conditions[locationIndex].values = newRule.conditions[deviceTypeIndex].values;
          newRule.conditions.splice(deviceTypeIndex, 1);
        }

        return newRule;
      }),
    },
  };
}
