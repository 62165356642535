import React from "react";
import { Tooltip, Typography } from "@ds-proxy";
import HelpIcon from "@mui/icons-material/Help";
import { css } from "ui/css";

const tooltipStyles = css({
  padding: "15px",
  maxHeight: "600px",
  overflow: "auto",
  maxWidth: "350px",
  backgroundColor: "var(--background-base3)",
});

const popperStyles = css({
  opacity: 1,
});

const classes = {
  tooltip: tooltipStyles().className,
  popper: popperStyles().className,
};

const ddClassName = css({
  fontSize: "13px",
  lineHeight: "24px",
  color: "var(--text-secondary)",
  marginLeft: "25px",
  pre: { color: "var(--text-inverted)", fontWeight: "bold" },
});

export function HelperInfo({
  label,
  links,
  examples,
}: {
  label: string;
  links: Array<{
    label: string;
    url: string;
  }>;
  examples: Array<{
    group: string;
    explanation: JSX.Element;
  }>;
}) {
  return (
    <Tooltip
      classes={classes}
      enterDelay={500}
      leaveDelay={500}
      title={
        <div>
          {links.map((link) => (
            <a
              key={link.label}
              rel="noopener noreferrer"
              style={{ display: "block", marginBottom: 15, fontSize: 15 }}
              target="_blank"
              href={link.url}
            >
              {link.label}
            </a>
          ))}

          <dl>
            {examples.map((el) => (
              <React.Fragment key={el.group}>
                <dt style={{ margin: "25px 0 10px", fontSize: 15 }}>{el.group}</dt>
                <dd className={ddClassName().className}>{el.explanation}</dd>
              </React.Fragment>
            ))}
          </dl>
        </div>
      }
    >
      <Typography variant="caption" style={{ cursor: "pointer", position: "relative" }}>
        {label}
        <HelpIcon
          fontSize="small"
          style={{
            position: "absolute",
            top: 0,
            right: -24,
          }}
        />
      </Typography>
    </Tooltip>
  );
}
