import React, { useEffect, useState } from "react";
import { upperFirst } from "lodash";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { Button, FormHelperText, Grid, LinearProgress } from "@ds-proxy";
import { Loader, TextFieldInput } from "ui";
import { Stack } from "libs/layouts";
import {
  parseEmail,
  getNotEmptyValidatorWithLabel,
  getValidator,
  validateEmail,
} from "modules/users/utils";
import { useAvailableAuthProviders } from "../useAuthProviders";
import {
  PasswordField,
  ProviderButton,
  ProviderButtonProps,
  TwoFactorPhoneAuthForm,
} from "../atoms";
import { validatePasswordComplexity } from "../utils";
import { messages } from "../messages";
import { useSignUp } from "../apiHooks";
import { SetupMFA } from "./SetupMFA";
import { useCaptcha } from "../loginHooks";

function validate(values: any) {
  const passwordsFilled = Boolean(values.password) && Boolean(values.passwordRepeat);
  const passwordsEqual = values.password === values.passwordRepeat;

  const errors = ["password", "passwordRepeat", "email"].reduce<Record<string, any>>(
    (errors, field) => {
      const value = values[field];
      if (!value) {
        errors[field] = messages.validation.required;
      }
      return errors;
    },
    {}
  );

  if (values.name && values.name.length < 3) {
    errors.name = messages.validation.nameMinLength;
  }
  const complexityValidation = validatePasswordComplexity(values.password);

  if (complexityValidation) {
    errors.password = complexityValidation;
    return errors;
  }

  if (passwordsFilled && !passwordsEqual) {
    errors.passwordRepeat = messages.validation.passEquality;
  }

  return errors;
}

type SignUpKeys = "oobCode";
export function canSignUp(params: Partial<Record<SignUpKeys, string>>) {
  if (!params.oobCode) {
    return {
      error: true,
      errorMessage: messages.notifications.notInvitedAccount,
    };
  }
  return {
    error: null as any,
    errorMessage: "",
  };
}

export interface IRegistrationFormProps {
  email?: string;
  isPasswordReset?: boolean;
}

export function RegistrationForm(props: IRegistrationFormProps) {
  const initialValues = {
    email: props.email,
    name: props.email!.split("@")[0],
    password: "",
    passwordRepeat: "",
  };
  const isPasswordOnlyAuth = Boolean(props.isPasswordReset);
  const { providers, isAllLoading, isSomeLoading, isPasswordAuthEnabled } =
    useAvailableAuthProviders();
  const [passwordToggle, setPasswordToggle] = useState(isPasswordOnlyAuth);
  const { mutateAsync: signUp, error, isPending: isSigningUp, reset } = useSignUp(props.email);
  const [captchaNode, verifier] = useCaptcha([], error?.type === "mfa");
  const { mutate: sendCode, error: sendCodeError } = useMutation({
    mutationFn: () => {
      return error?.type === "mfa" ? error.sendCode(verifier.current) : Promise.resolve("");
    },
  });
  // hack for internal auth
  (window as any).internalAuthSignUp = () =>
    signUp({
      type: "cloud",
      cloud: "google",
      params: { name: "google.com" },
    });
  useEffect(() => {
    if (error?.type === "mfa") {
      setTimeout(() => {
        sendCode();
      }, 200);
    }
  }, [error]);

  if (error?.type === "mfa") {
    return (
      <Form
        validate={getValidator(
          getNotEmptyValidatorWithLabel("twoFactorCode", "Two factor code is required")
        )}
        onSubmit={(v) => {
          return error.verifyCode(v.twoFactorCode);
        }}
        render={(props) => (
          <>
            {captchaNode}
            <TwoFactorPhoneAuthForm
              {...props}
              error={props.error || (sendCodeError as any)?.message}
              onCancel={reset}
              resend={() => {
                sendCode();
              }}
            />
          </>
        )}
      />
    );
  }
  if (error?.type === "mfa-required") {
    return <SetupMFA onDone={error.finishAuth} />;
  }

  return (
    <>
      {isAllLoading && <Loader loading />}
      {((isSomeLoading && !isAllLoading) || isSigningUp) && <LinearProgress />}
      <Stack space={3}>
        <Stack space={2}>
          {!isPasswordOnlyAuth &&
            providers.map((provider) => {
              return (
                <ProviderButton
                  key={provider.id}
                  type={provider.cloudType as ProviderButtonProps["type"]}
                  label={`Sign up with ${upperFirst(provider.name)}`}
                  onClick={() =>
                    signUp({
                      type: "cloud",
                      cloud: provider.cloudType,
                      params: { name: provider.id },
                    })
                  }
                />
              );
            })}
          {!passwordToggle && isPasswordAuthEnabled && (
            <ProviderButton
              type="password"
              label="Sign up with Password"
              onClick={() => setPasswordToggle(true)}
            />
          )}
        </Stack>
        {error && !isSigningUp && (
          <Grid item>
            <FormHelperText error>{(error as any)?.message}</FormHelperText>
          </Grid>
        )}
        {passwordToggle && (
          <Form
            initialValues={initialValues}
            validate={validate}
            onSubmit={(values) => signUp({ type: "local", values })}
            render={({ handleSubmit, submitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Field
                        name="email"
                        component={TextFieldInput as any}
                        label={messages.formFields.email}
                        fullWidth
                        disabled
                        parse={parseEmail}
                        validateOnBlur
                        validate={validateEmail}
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        hideIcon
                        fullWidth
                        component={PasswordField as any}
                        name="password"
                        label={messages.formFields.password}
                        autoComplete="new-password"
                      />
                    </Grid>

                    <Grid item>
                      <Field
                        hideIcon
                        fullWidth
                        component={PasswordField as any}
                        name="passwordRepeat"
                        label={messages.formFields.repeatPass}
                        autoComplete="new-password"
                      />
                      <FormHelperText>{messages.formFields.repeatPassRule}</FormHelperText>
                    </Grid>
                    <Button
                      fullWidth
                      color="primary"
                      variant="outlined"
                      type="submit"
                      disabled={submitting}
                    >
                      {messages.signUp}
                    </Button>
                  </Grid>
                </form>
              );
            }}
          />
        )}
        <Link to="/forgot" style={{ textAlign: "center", textDecoration: "none" }}>
          {messages.forgotPassword}
        </Link>
      </Stack>
    </>
  );
}
