import React, { ElementType, forwardRef } from "react";
import { PolymorphicComponentProps } from "react-polymorphic-box";
import { css } from "ui/css";
import { Box, CssPropType } from "ui/atoms/Box";

const VStackStyles = css({
  "display": "flex",
  "flexDirection": "column",
  "& > *:only-child": { height: "100%" },
  "& > * + *": { marginTop: "calc(5px * var(--stack-space-ratio-element))" },
});

const VStackPaddingModeStyles = css({
  "display": "grid",
  "gridAutoRows": "min-content",
  "& > *": {
    paddingTop: "calc(2.5px * var(--stack-space-ratio-element))",
    paddingBottom: "calc(2.5px * var(--stack-space-ratio-element))",
  },
  '*[data-nested="true"]': { paddingTop: "0", paddingBottom: "0" },
});

const HStackStyles = css({
  "display": "flex",
  "flexDirection": "row",
  "& > *:only-child": { width: "100%" },
  "& > * + *": { marginLeft: "calc(5px * var(--stack-space-ratio-element))" },
});

type Props = {
  // gap between elements
  space?: number | "inherit";
  // items alignment within container
  align?: "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
  fullWidth?: boolean;
  children?: React.ReactNode | React.ReactNode[];
};
const defaultElement = "div";

export type StackProps<E extends React.ElementType> = PolymorphicComponentProps<E, Props>;
/**
 * The alignment property doesn’t
 * position the VStack inside its container;
 * instead, it positions the views inside the VStack.
    The alignment property of a VStack only applies to the horizontal alignment
    of the contained controls using HorizontalAlignment.
    Similarly, the alignment property for an HStack only controls the vertical alignment
    using align.
  @deprecated The component should not be used
 */
export const Stack = forwardRef(function Stack<E extends React.ElementType = typeof defaultElement>(
  { children, className, align, space = 1, style, fullWidth, sx, ...props }: StackProps<E>,
  ref: any
) {
  return (
    <Box
      ref={ref}
      as={defaultElement}
      data-component={"VStack"}
      style={style}
      className={[VStackStyles().className, className].join(" ")}
      sx={{
        ...stackStyles({
          space,
          alignItems: align,
          fullWidth,
        }),
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
}) as any as (<E extends ElementType = typeof defaultElement>(
  props: StackProps<E>
) => JSX.Element) & { Spacer: ElementType };

export const StackInner = forwardRef(function Stack<E extends ElementType = typeof defaultElement>(
  {
    children,
    className,
    align,
    space = 1,
    style,
    nested = false,
    ...props
  }: Omit<StackProps<E>, "stretchAfter"> & { nested?: boolean },
  ref: any
) {
  return (
    <Box
      ref={ref}
      as={defaultElement}
      data-component={"VStackInner"}
      data-nested={nested}
      style={style}
      className={[VStackPaddingModeStyles().className, className].join(" ")}
      sx={stackStyles({ space, alignItems: align })}
      {...props}
    >
      {children}
    </Box>
  );
}) as any as (<E extends React.ElementType = typeof defaultElement>(
  props: StackProps<E> & { nested?: boolean }
) => JSX.Element) & { Spacer: ElementType };

/**
 * @deprecated The component should not be used
 */
export const HStack = forwardRef(function HStack<
  E extends React.ElementType = typeof defaultElement
>({ children, className, align, space = 1, style, fullWidth, ...props }: StackProps<E>, ref: any) {
  return (
    <Box
      ref={ref}
      as={defaultElement}
      data-component={"HStack"}
      style={style}
      className={[HStackStyles().className, className].join(" ")}
      sx={stackStyles({
        space,
        alignItems: align,
        fullWidth,
      })}
      {...props}
    >
      {children}
    </Box>
  );
}) as any as (<E extends React.ElementType = typeof defaultElement>(
  props: StackProps<E>
) => JSX.Element) & { Spacer: ElementType };

function stackStyles({ space, alignItems, fullWidth = false }: any) {
  const spaceValue = space === "inherit" ? "var(--stack-space-ratio-element)" : space;
  let restStyles = {};
  if (fullWidth) {
    restStyles = { width: "100%" };
  }
  return {
    ...restStyles,
    alignItems,
    "--stack-space-ratio": spaceValue,
    ">*": {
      "--stack-space-ratio-element": space === "inherit" ? "var(--stack-space-ratio)" : space,
    },
  } as CssPropType;
}

export function Spacer() {
  return <div style={{ marginRight: "auto", marginBottom: "auto" }} />;
}
HStack.Spacer = Spacer;
Stack.Spacer = Spacer;

const overlayStyles = css({
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  background: "var(--background-base0)",
});

export function Overlay({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return <div {...props} className={overlayStyles({ className }).className} />;
}

type VDividerPropsInternal = {
  space: number;
};
export type VDividerProps<E extends React.ElementType> = PolymorphicComponentProps<
  E,
  VDividerPropsInternal
>;

export function VDivider<E extends React.ElementType = typeof defaultElement>(
  props: VDividerProps<E>
) {
  return <Box as={defaultElement} {...props} />;
}
