import React from "react";
import { CircularProgress } from "@ds-proxy";
import { iconSizes } from "../icons";

interface SpinnerProps {
  className?: string;
  size?: number | "xs" | "s" | "m" | "l";
}

export function Spinner({ className, size = 24 }: SpinnerProps) {
  const sizeInNumber = typeof size === "number" ? size : iconSizes[size];
  return <CircularProgress size={sizeInNumber} thickness={4} className={className} />;
}
