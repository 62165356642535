import React from "react";
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from "@mui/x-data-grid-pro";
import { CHGridColDef } from "../types";
import { GridDetailPanelHeader } from "../molecules/GridDetailPanelHeader";
import { customGridClasses } from "../constants";
import { GridDetailPanelToggle } from "../molecules/GridDetailPanelToggle";

export const GRID_DETAIL_PANEL_TOGGLE_COLUMN_CONFIG: CHGridColDef = {
  ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  width: 38,
  maxWidth: 38,
  minWidth: 38,
  headerClassName: customGridClasses.detailPanelHeader,
  renderHeader: (params) => <GridDetailPanelHeader {...params} />,
};

export const GRID_DETAIL_PANEL_TOGGLE_COLUMN_CONFIG_NEW_STYLE: CHGridColDef = {
  ...GRID_DETAIL_PANEL_TOGGLE_COLUMN_CONFIG,
  width: 96,
  maxWidth: 96,
  minWidth: 96,
  align: "center",
  headerAlign: "center",
  headerName: "Expand",
  renderHeader: (params) => (
    <GridDetailPanelHeader
      collapseAllIconButtonProps={{ variant: "subtle", color: "primary" }}
      {...params}
    />
  ),
  renderCell: (params) => <GridDetailPanelToggle {...params} />,
};
