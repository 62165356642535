import React, { forwardRef } from "react";
import { VariantProps } from "@stitches/react";
// TODO: Merge this with the other typography component
// eslint-disable-next-line no-restricted-imports
import { theme } from "@ds-proxy/theme";
import { css } from "ui/css";
import { BoxComponent } from "ui/atoms/Box";

const textStyles = css({
  variants: {
    variant: Object.entries(theme.customTypography).reduce((acc, [key, value]) => {
      (acc as any)[key] = value;
      return acc;
    }, {} as Record<keyof typeof theme.customTypography, any>),
    noWrap: {
      true: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    uppercase: {
      true: {
        textTransform: "uppercase",
      },
    },
    bold: {
      true: {
        fontWeight: 500,
      },
    },
    isClickable: {
      true: {
        "cursor": "pointer",
        "&:hover": {
          "text-decoration": "underline",
        },
      },
    },
    ellipsis: {
      true: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "inline-block",
      },
    },
  },
});

export type TextProps = VariantProps<typeof textStyles> & {
  color?: string;
  bold?: boolean;
};

export const Text: BoxComponent<TextProps> = forwardRef(function Text(
  {
    as: Comp = "div",
    className,
    variant = "body2",
    bold,
    uppercase,
    sx,
    color,
    noWrap,
    ellipsis,
    isClickable,
    ...props
  },
  ref
) {
  return (
    <Comp
      {...props}
      ref={ref}
      className={textStyles({
        className,
        variant,
        noWrap,
        ellipsis,
        uppercase,
        bold,
        isClickable,
        css: {
          ...sx,
          color,
        },
      })}
    />
  );
});
