import { ThemeOptions } from "@ds-proxy";

export const palette: ThemeOptions["palette"] = {
  mode: "light",
  primary: {
    main: "#0891B2",
    dark: "#0e7790",
    light: "#22c6ee",
    ultralight: "#EBF9FA",
  },
  // TODO: Talk with DS about having a secondary color
  secondary: {
    main: "#737373",
    ultralight: "#F5F5F5",
  },
  error: {
    main: "#D32F2F",
    dark: "#C62828",
    light: "#EF9A9A",
    ultralight: "#FDF7F7",
  },
  success: {
    main: "#2E7D32",
    dark: "#1B5E20",
    ultralight: "#EFF5EF",
  },
  warning: {
    main: "#EF6C00",
    dark: "#E65100",
    ultralight: "#FEF4EB",
  },
  info: {
    main: "#0288D1",
    dark: "#0288D1",
    ultralight: "#EBF6FC",
  },
  divider: "rgba(0,0,0, 0.12)",
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
  text: {
    primary: "#020617",
    secondary: "#64748B",
    disabled: "#A1A1AA",
    icon: "#020617",
    links: "#0891B2",
    placeholder: "#A1A1AA",
    subtle: "#A1A1AA",
    inverted: "#FFFFFF",
  },
};
