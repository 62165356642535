// eslint-disable-next-line no-restricted-imports
import { theme } from "@ds-proxy/theme";
import { styled } from "ui/css";

export const BetaLabel = styled("div", {
  padding: "0px 4px",
  background: "var(--interactive-positive)",
  color: "var(--text-inverted)",
  fontSize: "9px",
  borderRadius: "2px",
  position: "absolute",
  top: "8px",
  left: "25px",
  textTransform: "uppercase",
  zIndex: theme.zIndex.modal + 2,
});

export const FullscreenContent = styled("div", {
  flex: "1 1 100%",
  height: "100%",
  boxSizing: "border-box",
  flexDirection: "column",
  display: "flex",
  position: "relative",
  overflow: "hidden",
});
