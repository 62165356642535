/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from "react";
import { upperFirst } from "lodash";
import { hideTokenizedValue } from "shared/data-masking";
import { Box, Link } from "@ds-proxy";
import { jsxJoin, partition } from "libs/data-utils";
import { useAnimatedChange } from "libs/hooks";
import { HStack, Stack } from "libs/layouts";
import { FilterChip } from "ui/atoms";
import { Popover } from "ui/molecules/Popover";
import { PageFilter } from "modules/page-navigation";

function FilterItem({ value, className = "" }: { value: React.ReactNode; className?: string }) {
  return (
    <span
      title={typeof value === "string" ? value : undefined}
      className={`${className} whitespace-nowrap text-ellipsis overflow-hidden`}
    >
      {value}
    </span>
  );
}

export function FilterLabel({ prefix, items, shouldJoinItems }: any) {
  return (
    <HStack align="center" space={1} className="filter-label" style={{ display: "inline-flex" }}>
      {prefix && <span data-fs="unmask">{prefix}:</span>}
      {items.length > 1 ? (
        shouldJoinItems ? (
          jsxJoin(
            items.map((el: any) => (
              <FilterItem key={"FilterItem"} className="max-w-[400px]" value={el} />
            )),
            () => <b> OR </b>
          )
        ) : (
          items.map((el: any, i: number) => (
            <FilterItem className="max-w-[400px]" value={el} key={i} />
          ))
        )
      ) : (
        <FilterItem value={items[0]} />
      )}
    </HStack>
  );
}

function replacer(_: any, value: (this: any, key: string, value: any) => any) {
  if (value instanceof Map) {
    return {
      dataType: "Map",
      value: Array.from(value.entries()),
    };
  } else if (value instanceof Set) {
    return {
      dataType: "Set",
      value: Array.from(value.keys()),
    };
  } else {
    return value;
  }
}
export const upperFirstEach = (v: any) => [...v].map(upperFirst);

export const displayValueEach = (f: PageFilter[]) => f.map((f) => f.displayValue);

export function Chip({
  animateMount = false,
  hasDelete = true,
  hideDefault,
  isDefault,
  isZero: _,
  showEmpty: ____,
  isClearAll: _____,
  label = (v: any) => v,
  name,
  onDelete,
  prefix,
  context: ___,
  setValue: ______,
  value,
  icon,
  labelIcon,
  shouldJoinLabel = true,
  ...props
}: any) {
  const animateKey = useMemo(() => {
    return JSON.stringify(value, replacer);
  }, [value]);

  const { ref } = useAnimatedChange({
    key: animateKey,
    animateMount,
    keyframes: {
      background: [
        "var(--background-base0)",
        "var(--background-active)",
        "var(--background-base0)",
      ],
    },
    duration: 1000,
    easing: "ease-in-out",
  });

  if (hideDefault && isDefault) {
    return null;
  }

  const textLabel = hideTokenizedValue(label(value));
  const items = Array.isArray(textLabel) ? textLabel : [textLabel];
  const [visible, truncated] = partition(items, (_, i) => i < 3);
  const hasNoIcons = (isDefault || !hasDelete) && !labelIcon;
  return (
    <FilterChip
      label={
        <HStack style={{ paddingRight: hasNoIcons ? "8px" : 0 }}>
          <FilterLabel prefix={prefix} items={visible} shouldJoinItems={shouldJoinLabel} />
          {truncated.length > 0 && (
            <Popover
              target={({ onClick }) => (
                <Link color="textPrimary" underline="always" onClick={onClick}>
                  +{truncated.length} more
                </Link>
              )}
            >
              <Stack as={Box} space={2} padding={2}>
                {truncated.map((el, i) => (
                  <span key={i}>{el}</span>
                ))}
              </Stack>
            </Popover>
          )}
          {labelIcon && (
            <Box
              style={{
                display: "flex",
                marginLeft: 0,
                padding: "0px 4px 0 8px",
                alignItems: "center",
              }}
            >
              {labelIcon}
            </Box>
          )}
        </HStack>
      }
      icon={icon}
      ref={ref}
      {...props}
      className={`filter-chip flex-shrink-0 max-w-full ${props.className}`}
      onDelete={isDefault || !hasDelete ? undefined : onDelete}
    />
  );
}

export function AsyncChip({ useValue, value, name, label, ...props }: any) {
  const { data } = useValue();

  return (
    <Chip {...props} value={value} label={() => (data ? label(data, value) : ["Loading ..."])} />
  );
}
