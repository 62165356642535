import React, { PureComponent } from "react";
import { FieldRenderProps } from "react-final-form";
import { IconButton, InputAdornment, TextField } from "@ds-proxy";
import { Icon } from "@ds-ui";
import { mapProps } from "ui";

interface IPasswordFieldState {
  visible: boolean;
}

export interface IPasswordFieldProps extends FieldRenderProps {
  hideIcon?: boolean;
}

export class PasswordField extends PureComponent<IPasswordFieldProps, IPasswordFieldState> {
  state = {
    visible: false,
  };

  toggleVisibility = () => {
    this.setState({ visible: !this.state.visible });
  };

  render() {
    const { hideIcon, ...props } = this.props;

    return (
      <TextField
        {...mapProps(props)}
        type={this.state.visible ? "text" : "password"}
        InputProps={{
          startAdornment: !hideIcon && (
            <InputAdornment position="start">
              <Icon name="LockOutlined" />
            </InputAdornment>
          ),

          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disableRipple
                tabIndex={-1}
                aria-label="Toggle password visibility"
                onClick={this.toggleVisibility}
                size="large"
              >
                <Icon name={this.state.visible ? "VisibilityOffOutlined" : "VisibilityOutlined"} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
}
