import { createTheme, ThemeOptions } from "@ds-proxy";
import { getCustomProperties } from "./customProperties";
import { getThemeComponents } from "./components";
import { EnhancedTheme, NamedTheme } from "../types";

export const generateTheme = (palette: ThemeOptions["palette"], name: string): EnhancedTheme => {
  //  First create the Palette, while at the same time giving it a name
  const tempTheme: NamedTheme = {
    ...createTheme({
      palette,
    }),
    name: name,
  };

  // "Merge" the theme, and reapply the name to satisfy the type system
  return {
    ...createTheme(tempTheme, {
      components: getThemeComponents(tempTheme),
    }),
    ...getCustomProperties(tempTheme),
    name: name,
  };
};
