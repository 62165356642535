import React from "react";
import { Tooltip } from "@ds-proxy";
import { css } from "ui/css";
import { messages } from "ui/messages";

const TooltipLabelClass = css`
  max-width: 500px;
`;
export class CopyTooltip extends React.Component {
  copiedText = messages.copyTooltip.copiedText;
  delay = 300;

  state = { showLabel: false };

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout);
  }

  onCopy = (e) => {
    e.preventDefault();
    e.clipboardData.setData("text/plain", this.props.title);
  };

  onClick = (e) => {
    this.timeout = setTimeout(() => this.setState({ showLabel: false }), this.delay);
    e.nativeEvent.preventDefault();
    e.nativeEvent.stopPropagation();

    this.setState({ showLabel: true });

    if (this.props.title) {
      document.execCommand("copy");
    }
  };

  getLabel() {
    return this.state.showLabel ? this.copiedText : this.props.title;
  }

  render() {
    return (
      <Tooltip
        interactive
        enterDelay={200}
        leaveDelay={50}
        classes={{
          tooltip: TooltipLabelClass,
        }}
        PopperProps={{
          container: this.props.portalContainer,
          disablePortal: !this.props.portalContainer,
          onClick: this.onClick,
          onCopy: this.onCopy,
        }}
        title={this.getLabel()}
      >
        {this.props.children}
      </Tooltip>
    );
  }
}
