import constate from "constate";
import React, { useMemo } from "react";
import { useUserPermissions, useCurrentUserId, preloadUserPermissions } from "shared/auth-hooks";
import { resolveService } from "service";
import { ENV } from "runenv";
import { QueryClient, useSuspenseQuery } from "@tanstack/react-query";
import { LayoutLoader } from "@ds-proxy";
import { useSelector } from "libs/hooks";
import { authState } from "modules/auth/state";
import { PermissionCheck, PermissionsService } from "./permissionsTypes";

function usePermissionsHook() {
  const currentUserId = useCurrentUserId();
  const user = useUserPermissions(currentUserId!);
  const permissions = user?.data?.permissions ?? [];
  return useMemo(
    () => ({
      roles: user.data?.roles ?? [],
      permissions,
      isPending: user.isLoading,
      permissionsSet: new Set(permissions),
    }),
    [user.data]
  );
}

export const [ConstatePermissionsProvider, usePermissions] = constate(usePermissionsHook);

function PermissionsWithLoader({ children }: { children: React.ReactNode }) {
  const { isPending } = usePermissions();

  return isPending ? <LayoutLoader /> : <>{children}</>;
}

export function PermissionsProvider({ children }: { children: React.ReactNode }) {
  return (
    <ConstatePermissionsProvider>
      <PermissionsWithLoader>{children}</PermissionsWithLoader>
    </ConstatePermissionsProvider>
  );
}

function canAccess(
  permissionSet: Set<string>,
  requiredPermissions: PermissionCheck,
  isAnyOf: boolean
) {
  if (ENV.ALL_PERMISSIONS) {
    return true;
  }

  const requiredPermissionsArray = Array.isArray(requiredPermissions)
    ? requiredPermissions
    : [requiredPermissions];
  return permissionSet
    ? isAnyOf
      ? Boolean(requiredPermissionsArray.find((el) => permissionSet.has(el)))
      : requiredPermissionsArray.every((el) => permissionSet.has(el))
    : false;
}

/**
 * Renders only first allowed Can.
 * Example:
 * <SwitchCan>
 *  <Can do="users.update"><UsersEditForm /></Can>
 *  <Can do="users.read"><UserReadonlyForm /></Can>
 * </SwitchCan>
 */
export function SwitchCan({ children }: { children: React.ReactElement[] }) {
  let match: React.ReactNode = null;

  React.Children.forEach(children, (child) => {
    if (match) {
      return;
    }
    const result = Can(child.props);
    if (result?.type === React.Fragment && result.props.children !== null) {
      match = result;
    }
  });

  return <>{match}</>;
}

export type CanProps = {
  do?: PermissionCheck;
  doAnyOf?: PermissionCheck; // required one of permissions from the list to be granted
  children: React.ReactNode | ((args: { canDo: boolean }) => React.ReactNode);
  // when user can't access content
  fallback?: React.ReactNode | (() => React.ReactNode);
};
/**
 * How to use it
 * <Can do="users.update"><ActionToRestrict/></Can>
 */
export function Can(props: CanProps) {
  const { permissionsSet, isPending } = usePermissions();
  const isDoEither = Boolean(props.doAnyOf);
  const doList = props.do || props.doAnyOf;

  if (props.do && props.doAnyOf) {
    throw Error("Only 1 type of do can be used at a time");
  }

  if (isPending) {
    return null;
  }

  if (!doList || !doList.length) {
    return (
      <>
        {typeof props.children === "function"
          ? props.children({ canDo: true })
          : (props.children as JSX.Element)}
      </>
    );
  }

  if (!canAccess(permissionsSet, doList, isDoEither)) {
    return (
      <>
        {props.fallback
          ? displayRenderFn(props.fallback)
          : typeof props.children === "function"
          ? props.children({ canDo: false })
          : null}
      </>
    );
  }
  return (
    <>{typeof props.children === "function" ? props.children({ canDo: true }) : props.children}</>
  );
}

function displayRenderFn(arg: React.ReactNode | (() => React.ReactNode)) {
  return (React.isValidElement(arg) ? arg : typeof arg === "function" ? arg() : arg) as JSX.Element;
}

export function useCanArray<T>({
  items,
  getItemPermission,
  isDoEither,
}: {
  items: T[];
  getItemPermission: (item: T) => PermissionCheck | undefined;
  isDoEither?: boolean | ((item: T) => boolean);
}): T[] | null {
  const { permissionsSet, isPending } = usePermissions();

  return useMemo(() => {
    if (isPending) {
      return null;
    }

    return items.filter((item) => {
      const itemPermission = getItemPermission(item);

      if (!itemPermission) {
        return true;
      }

      return canAccess(
        permissionsSet,
        itemPermission,
        typeof isDoEither === "function" ? isDoEither(item) : Boolean(isDoEither)
      );
    });
  }, [items, isDoEither, isPending, permissionsSet]);
}

// alternative without resolveService which is unsupported in tests
export function useCanHook(_do: PermissionCheck, isAnyOf: boolean = false) {
  const { permissionsSet, isPending } = usePermissions();

  if (isPending) {
    return null;
  }

  return canAccess(permissionsSet, _do, isAnyOf);
}

// Uses resolveService which is not supported in testing but good for caching
export function useCan(_do: PermissionCheck, isAnyOf: boolean = false) {
  // Use user id as part of query key to invalidate cache when user changes
  const { id } = useSelector(authState.stores.user);

  return useSuspenseQuery({
    queryKey: ["permissions", _do, id],
    queryFn: () =>
      _do ? resolveService("permissions").then((el) => el.canDo(_do, isAnyOf)) : true,

    // Make sure once resolved permission refetches in background
    // This prevents app suddenly show Loading spinner instead of page once permissions refetching after 5 minute timer
    gcTime: 1000 * 60 * 60,

    refetchInterval: 1000 * 60 * 5,
    refetchIntervalInBackground: true,
  });
}

export class PermissionsServiceImp implements PermissionsService {
  userId?: string = undefined;
  constructor(private queryClient: QueryClient) {}
  setCurrentUser(userId?: string) {
    this.userId = userId;
  }
  preloadPermissions() {
    if (!this.userId) {
      console.error("user is not available yet");
      return false;
    }
    return preloadUserPermissions(this.queryClient, this.userId);
  }
  async canDo(requiredPermissions: PermissionCheck, isAnyOf: boolean = false) {
    if (!this.userId) {
      console.error("user is not available yet");
      return false;
    }
    const currentUser = await preloadUserPermissions(this.queryClient, this.userId);
    if (!currentUser) {
      throw Error("user permissions were not loaded");
    }

    return canAccess(new Set(currentUser?.permissions ?? []), requiredPermissions, isAnyOf);
  }
}
