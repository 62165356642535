import React, { HTMLAttributes } from "react";
import { styled } from "../css";

const Container = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "4px",
  height: "22px",
  padding: "4px 8px",
  borderRadius: "16px",
  transition: `background-color .3s ease-out`,
  cursor: "default",

  ["&:hover"]: {
    backgroundColor: "var(--background-active)",
  },
});

const Label = styled("div", {
  color: "var(--palette-text-primary)",
  fontSize: "12px",
  lineHeight: "14px",
  letterSpacing: "0.15px",
});

export interface PageHeaderPillProps extends HTMLAttributes<HTMLDivElement> {
  label: React.ReactNode | string;
  icon: React.ReactNode;
}
export function PageHeaderPill({ label, icon, ...rest }: PageHeaderPillProps) {
  return (
    <Container {...rest}>
      {icon}
      <Label>{label}</Label>
    </Container>
  );
}
