import React from "react";
import { Box } from "@ds-proxy";
import { styled } from "ui/css";

const NoteContainer = styled(Box, {
  label: "Box",
  background: "var(--background-base3)",
  border: "var(--border-width-small) solid var(--palette-divider)",
  padding: "10px",
  letterSpacing: "-0.2px",
  width: "fit-content",
});

export interface NoteProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  title?: string;
  hideTitle?: boolean;
}

export function Note({ children, style, title, hideTitle }: NoteProps) {
  return (
    <NoteContainer style={style}>
      <span hidden={hideTitle} style={{ fontWeight: "bold" }}>
        {title || "Note"}:{" "}
      </span>
      {children}
    </NoteContainer>
  );
}
