import React from "react";
import { ReactComponent as CHLogo4 } from "assets/images/cyberhaven-logo4.svg";
import { Paper } from "@ds-proxy";
import styles from "./PageTemplate.module.css";

export function PageTemplate({ children }: any) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.innerContainer}>
        <div className={styles.logo}>
          <CHLogo4 />
        </div>
        <Paper className={styles.paper} elevation={3}>
          {children}
        </Paper>
      </div>
    </div>
  );
}
