import React from "react";
import { Icon } from "@ds-ui";

export const ExpandIcon = ({ expanded, ...props }: any) => {
  return expanded ? (
    <Icon name={"KeyboardArrowUpOutlined"} {...props} />
  ) : (
    <Icon name={"KeyboardArrowDownOutlined"} {...props} />
  );
};
