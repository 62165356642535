import React, { forwardRef } from "react";
import MuiRadio, { RadioProps } from "@mui/material/Radio";

// Re-export everything from MUI (all types, utilities)
export * from "@mui/material/Radio";

const Radio = forwardRef<HTMLButtonElement, RadioProps>(function Checkbox(props, ref) {
  const normalizedColor = (props.color === "default" ? "primary" : props.color) ?? "primary";

  return (
    <MuiRadio
      {...props}
      ref={ref}
      sx={{
        // TODO: See if we can move this logic in the "components" file
        color: (theme) => {
          return theme.palette[normalizedColor].main;
        },
      }}
    />
  );
});

export { Radio };
