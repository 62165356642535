import React from "react";
import { cva, VariantProps } from "class-variance-authority";
import { MenuItem as MuiMenuItem, MenuItemProps as MuiMenuItemProps } from "@ds-proxy";

const styles = cva("hover:bg-background-base3 h-11 p-3 flex items-center gap-1", {
  variants: {
    tone: {
      positive: "text-text-primary",
      destructive: "text-interactive-destructive",
    },
    disabled: {
      true: "text-text-disabled",
      false: "",
    },
  },
  defaultVariants: { tone: "positive", disabled: false },
});

export type MenuItemProps = MuiMenuItemProps &
  VariantProps<typeof styles> & {
    leftIcon?: React.ReactNode;
    closeOnClick?: boolean;
  };

export function MenuItem({ leftIcon, children, className, tone, ...props }: MenuItemProps) {
  return (
    <MuiMenuItem
      {...props}
      className={styles({ className, tone })}
      disableRipple
      disableTouchRipple
    >
      {leftIcon}
      {children}
    </MuiMenuItem>
  );
}
