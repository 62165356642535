import React, { useState } from "react";
import { Popover } from "@ds-proxy";

export function useAnchorPositionedPopover<T>(render: (_: any, props: T) => React.ReactNode) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [props, setProps] = useState<T | null>({} as any);
  const [position, setPosition] = useState<null | { left: number; top: number; maxHeight: number }>(
    null
  );

  function handleOpen(anchor: HTMLElement, props?: T) {
    setProps(props!);
    setAnchorEl(anchor!);
    const el = anchor.getBoundingClientRect();
    const MinHeight = 500;
    setPosition({
      left: el.left,
      top: Math.min(el.top, window.innerHeight - MinHeight),
      maxHeight: Math.max(MinHeight, window.innerHeight - el.top),
    });
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const popProps = {
    onClose: handleClose,
    open: Boolean(anchorEl),
    anchorPosition: position,
    anchorReference: "anchorPosition",
  } as any;
  return [
    <Popover
      key={"Popover"}
      TransitionProps={{
        onExited() {
          setProps(null);
        },
      }}
      {...popProps}
      onClose={handleClose}
      style={{ maxHeight: position?.maxHeight }}
      keepMounted={false}
    >
      {render({ onClose: handleClose }, props!)}
    </Popover>,
    {
      onOpen: handleOpen,
    },
  ] as const;
}
