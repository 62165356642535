import "ds-ui/generated/styles.css";
import "assets/styles/index.css";
import React from "react";
import { useTheme } from "@shared/color-theme/useTheme";
import { CssBaseline, ThemeProvider as MuiThemeProvider, StyledEngineProvider } from "@ds-proxy";
import { EnhancedTheme, getTheme } from "@ds-proxy/theme";

export type ThemeProviderProps = React.PropsWithChildren & {
  theme?: EnhancedTheme;
};
export const ThemeProvider = ({ children, theme: overrideTheme }: ThemeProviderProps) => {
  const { activeTheme } = useTheme();

  // Memoize the theme to prevent unnecessary re-renders
  const theme = React.useMemo(() => {
    return getTheme(activeTheme);
  }, [activeTheme]);

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={overrideTheme || theme}>
        <React.Fragment>
          <CssBaseline />
          {children}
        </React.Fragment>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};
