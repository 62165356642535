import React from "react";
import { IS_SAFARI } from "shared/checkBrowser";
import { twMerge } from "tailwind-merge";

const safariStyles = IS_SAFARI
  ? {
      flexShrink: 0,
    }
  : {};

export function TablePageTemplate({
  topbar = null,
  filters = null,
  children,
  className,
}: {
  topbar?: React.ReactNode;
  filters?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={twMerge("h-full", className)}>
      <div className="flex flex-col h-full">
        {(topbar || filters) && (
          <div className="flex flex-col mb-xl gap-1 px-l py-s bg-background-base3 border-t-0 border-l-0 border-r-0 border-b border-solid border-outlines-1">
            {topbar}
            {filters}
          </div>
        )}
        <div className={"flex flex-col grow h-full px-l overflow-hidden"}>{children}</div>
      </div>
    </div>
  );
}

export function TableTemplate({
  className,
  tableFilters,
  caption,
  children,
  "data-testid": testId,
}: {
  tableFilters?: React.ReactNode;
  caption?: React.ReactNode;
  children: React.ReactNode;
  ["data-testid"]?: string;
  className?: string;
}) {
  return (
    <div className={twMerge("flex flex-col gap-2 h-full", className)} data-testid={testId}>
      {tableFilters && (
        <div className="flex flex-col gap-3" style={safariStyles}>
          {tableFilters}
        </div>
      )}
      <div className="flex flex-col grow overflow-auto">
        {children}
        {caption && (
          <div
            className="flex pl-0.5 py-0.5 border border-solid border-palette-divider border-t-0"
            data-fs="unmask"
            style={
              IS_SAFARI && {
                flexShrink: 0,
              }
            }
          >
            {caption}
          </div>
        )}
      </div>
    </div>
  );
}
