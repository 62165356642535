import React, { useMemo } from "react";
import {
  GridColumnMenuContainer,
  GridColumnMenuProps,
  GridColumnMenuHideItem,
  GridColumnMenuPinningItem,
  GridColumnMenuFilterItem,
} from "@mui/x-data-grid-pro";
import { Divider, MenuItem } from "@ds-proxy";
import { useGrid } from "../useGrid";

export function GridColumnMenu(props: GridColumnMenuProps) {
  const { hideMenu, colDef: currentColumn } = props;

  const { resetColumnsWidths, pinnedColumns } = useGrid();
  const isPinned = useMemo(
    () =>
      pinnedColumns?.left?.includes(currentColumn.field) ||
      pinnedColumns?.right?.includes(currentColumn.field),
    [pinnedColumns]
  );

  return (
    <GridColumnMenuContainer {...props}>
      {!isPinned && <GridColumnMenuHideItem onClick={hideMenu} colDef={currentColumn!} />}
      <GridColumnMenuPinningItem onClick={hideMenu} colDef={currentColumn!} />
      {currentColumn!.filterable && <Divider />}
      {currentColumn!.filterable && (
        <GridColumnMenuFilterItem onClick={hideMenu} colDef={currentColumn!} />
      )}
      <Divider />
      <MenuItem
        onClick={(event) => {
          resetColumnsWidths();
          hideMenu(event);
        }}
      >
        Auto-size all columns
      </MenuItem>
    </GridColumnMenuContainer>
  );
}
