import React from "react";
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from "@mui/material/TextField";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { TextFieldVariants } from "@ds-proxy";

// Re-export everything from @mui/material/TextField
export * from "@mui/material/TextField";

// Define custom props
// export type TextFieldProps = Omit<MuiTextFieldProps, "variant">; // TODO Disable the variant prop at some point
export type TextFieldProps<V extends TextFieldVariants = TextFieldVariants> = MuiTextFieldProps<V>;

// Define the type map for the overridable component
interface TextFieldTypeMap {
  props: TextFieldProps;
  defaultComponent: "input";
}

const TextField = ((props: TextFieldProps) => {
  return <MuiTextField {...props} />;
}) as OverridableComponent<TextFieldTypeMap>;

// Override only the TextField component
export { TextField };

declare module "@mui/material/TextField" {
  interface TextFieldVariantOverrides {
    filled: false;
    // standard: false; // TODO: Disable the standard variant at some point
  }
}
