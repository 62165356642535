import React, { forwardRef } from "react";
import { notificationsSelectors } from "shared/testConstants";
import { SnackbarContent } from "@ds-proxy";
import { css } from "ui/css";
import { INotificationType } from "./state";

const styles = css({
  variants: {
    type: {
      default: {},
      success: {},
      info: {},

      error: {
        color: "var(--palette-error-light)",
      },

      warning: {
        color: "var(--palette-warning-light)",
      },
    },
  },
});

export interface INotificationProps {
  type: INotificationType;
  message: React.ReactNode;
  description?: string;
  action?: React.ReactNode;
}

export const Notification = forwardRef(function Notification(
  { message, description, type, action }: INotificationProps,
  ref: any
) {
  return (
    <SnackbarContent
      style={{ justifyContent: "center" }}
      data-testid={notificationsSelectors.Message}
      className={styles({ type }).className}
      action={action}
      ref={ref}
      message={
        <>
          <span>{message}</span>
          <br />
          <span>{description}</span>
        </>
      }
    />
  );
});
