type PlainObject = { [key: string]: any };

export function mergeDeep<T extends PlainObject, U extends PlainObject>(
  target: T,
  source: U
): T & U {
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return target as T & U;
}

function isObject(item: any): item is PlainObject {
  return item && typeof item === "object" && !Array.isArray(item);
}
