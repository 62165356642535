import React from "react";
import { IconButton } from "@ds-proxy";

interface GridHeaderActionButtonProps {
  active?: boolean;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler;
  className?: string;
}

export function GridHeaderActionButton({
  active,
  children,
  onClick,
  className,
}: GridHeaderActionButtonProps) {
  return (
    <IconButton
      className={className}
      onClick={onClick}
      variant={active ? "subtle" : "ghost"}
      color={"primary"}
      size={"extraSmall"}
      // TODO [grid] remove this once we update to v7 of the grid and we are able to use the slotProps for the sortIconButton
      data-tempBtnHack="tempBtnHack"
    >
      {children}
    </IconButton>
  );
}
