import React from "react";
import { merge, extend } from "lodash";
import { createActions, createState, build } from "okdux";
import { SnackbarProps } from "@ds-proxy";

export type INotificationType = "info" | "warning" | "success" | "error" | "default";

export interface INotification {
  message: React.ReactNode;
  description: string;
  open: boolean;
  type: INotificationType;
  props: Partial<SnackbarProps>;
  action?: React.ReactNode;
  autoHideDuration?: number;
}

export const actions = createActions(
  {
    open: build.action<Partial<INotification>>(),
    warning: build.action<Partial<INotification>>(),
    success: build.action<Partial<INotification>>(),
    error: build.action<Partial<INotification>>(),
    close: build.action(),
  },
  "notification"
);

export const initialState: INotification = {
  type: "default",
  message: "",
  description: "",
  open: false,
  props: {},
  action: null,
  autoHideDuration: 2000,
};

export const notificationState = createState<INotification>(initialState);

notificationState
  .on(actions.open, open("default"))
  .on(actions.warning, open("warning"))
  .on(actions.success, open("success"))
  .on(actions.error, open("error"))
  .on(actions.close, close());

function open(type: INotificationType) {
  return (state: any, payload: any): INotification =>
    extend({}, state, initialState, { type, open: true }, payload);
}

function close() {
  return (state: any): INotification => merge({}, state, { open: false });
}

export const notification = actions;
