// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Chip-module__chip--BAIve {
  border-radius: 3px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  min-width: 22px;
  font-weight: 500;
  font-size: 12px;
  min-height: 22px;
  align-items: center;
  line-height: 14px;
  padding: 4px 4px;
}

.Chip-module__disabled--qXnZ0 {
  opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/ui/atoms/Chip/Chip.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,oBAAoB;EACpB,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".chip {\n  border-radius: 3px;\n  text-align: center;\n  display: inline-flex;\n  justify-content: center;\n  min-width: 22px;\n  font-weight: 500;\n  font-size: 12px;\n  min-height: 22px;\n  align-items: center;\n  line-height: 14px;\n  padding: 4px 4px;\n}\n\n.disabled {\n  opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chip": `Chip-module__chip--BAIve`,
	"disabled": `Chip-module__disabled--qXnZ0`
};
export default ___CSS_LOADER_EXPORT___;
