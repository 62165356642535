import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button } from "@ds-proxy";
import { css } from "ui/css";
import { messages } from "../messages";

const styles = css({
  display: "flex",
  width: "100%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
});

export const ErrorPageTemplate = ({
  title,
  subTitle,
  className = "",
  style,
}: {
  title: string;
  subTitle: string | JSX.Element;
  className?: string;
  style?: React.CSSProperties;
}) => (
  <div className={styles({ className }).className} style={style}>
    <div style={{ textAlign: "center" }}>
      <Typography variant="h5" className={"text-[25vh]"}>
        {title}
      </Typography>
      <Typography color="textSecondary" variant="subtitle1">
        {subTitle}
      </Typography>
      <div style={{ marginTop: "3em" }}>
        <Button
          color="primary"
          variant="contained"
          component={(props: any) => <Link to="/" {...props} />}
        >
          {messages.backBtn}
        </Button>
      </div>
    </div>
  </div>
);
