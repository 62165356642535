/*
  Code generated with generateTheme.ts file. DO NOT EDIT.
  last updated at 2025-03-28T14:53:51.198Z */
module.exports.colors = {
  "common": {
    "black": "var(--common-black)",
    "white": "var(--common-white)"
  },
  "branding": {
    "blue": "var(--branding-blue)",
    "purple": "var(--branding-purple)",
    "pink": "var(--branding-pink)"
  },
  "interactive": {
    "positive": "var(--interactive-positive)",
    "positive-highlight": "var(--interactive-positive-highlight)",
    "destructive": "var(--interactive-destructive)",
    "destructive-highlight": "var(--interactive-destructive-highlight)",
    "disabled": "var(--interactive-disabled)"
  },
  "text": {
    "primary": "var(--text-primary)",
    "secondary": "var(--text-secondary)",
    "disabled": "var(--text-disabled)",
    "icon": "var(--text-icon)",
    "links": "var(--text-links)",
    "placeholder": "var(--text-placeholder)",
    "subtle": "var(--text-subtle)",
    "inverted": "var(--text-inverted)"
  },
  "dataviz": {
    "1": "var(--dataviz-1)",
    "2": "var(--dataviz-2)",
    "3": "var(--dataviz-3)",
    "4": "var(--dataviz-4)",
    "5": "var(--dataviz-5)",
    "6": "var(--dataviz-6)",
    "7": "var(--dataviz-7)",
    "8": "var(--dataviz-8)"
  },
  "linea": {
    "primary": "var(--linea-primary)",
    "background": "var(--linea-background)"
  },
  "background": {
    "base0": "var(--background-base0)",
    "base1": "var(--background-base1)",
    "base2": "var(--background-base2)",
    "base3": "var(--background-base3)",
    "idle": "var(--background-idle)",
    "active": "var(--background-active)",
    "destructive-idle": "var(--background-destructive-idle)",
    "destructive-active": "var(--background-destructive-active)",
    "shadow": "var(--background-shadow)",
    "skeleton": "var(--background-skeleton)"
  },
  "outlines": {
    "1": "var(--outlines-1)",
    "2": "var(--outlines-2)",
    "3": "var(--outlines-3)",
    "disabled": "var(--outlines-disabled)",
    "focus": "var(--outlines-focus)"
  },
  "accent": {
    "primary": "var(--accent-primary)",
    "background": "var(--accent-background)"
  },
  "severity": {
    "critical": "var(--severity-critical)",
    "high": "var(--severity-high)",
    "medium": "var(--severity-medium)",
    "low": "var(--severity-low)",
    "informational": "var(--severity-informational)"
  },
  "semantic": {
    "success-primary": "var(--semantic-success-primary)",
    "success-background": "var(--semantic-success-background)",
    "error-primary": "var(--semantic-error-primary)",
    "error-background": "var(--semantic-error-background)",
    "warning-primary": "var(--semantic-warning-primary)",
    "warning-background": "var(--semantic-warning-background)",
    "informational-primary": "var(--semantic-informational-primary)",
    "informational-background": "var(--semantic-informational-background)"
  },
  "backdrops": {
    "1": "var(--backdrops-1)"
  },
  "palette": {
    "primary-main": "var(--palette-primary-main)",
    "primary-dark": "var(--palette-primary-dark)",
    "primary-light": "var(--palette-primary-light)",
    "primary-ultralight": "var(--palette-primary-ultralight)",
    "primary-contrastText": "var(--palette-primary-contrastText)",
    "secondary-main": "var(--palette-secondary-main)",
    "secondary-ultralight": "var(--palette-secondary-ultralight)",
    "secondary-light": "var(--palette-secondary-light)",
    "secondary-dark": "var(--palette-secondary-dark)",
    "secondary-contrastText": "var(--palette-secondary-contrastText)",
    "error-main": "var(--palette-error-main)",
    "error-dark": "var(--palette-error-dark)",
    "error-light": "var(--palette-error-light)",
    "error-ultralight": "var(--palette-error-ultralight)",
    "error-contrastText": "var(--palette-error-contrastText)",
    "info-main": "var(--palette-info-main)",
    "info-dark": "var(--palette-info-dark)",
    "info-ultralight": "var(--palette-info-ultralight)",
    "info-light": "var(--palette-info-light)",
    "info-contrastText": "var(--palette-info-contrastText)",
    "warning-main": "var(--palette-warning-main)",
    "warning-dark": "var(--palette-warning-dark)",
    "warning-ultralight": "var(--palette-warning-ultralight)",
    "warning-light": "var(--palette-warning-light)",
    "warning-contrastText": "var(--palette-warning-contrastText)",
    "success-main": "var(--palette-success-main)",
    "success-dark": "var(--palette-success-dark)",
    "success-ultralight": "var(--palette-success-ultralight)",
    "success-light": "var(--palette-success-light)",
    "success-contrastText": "var(--palette-success-contrastText)",
    "common-black": "var(--palette-common-black)",
    "common-white": "var(--palette-common-white)",
    "background-paper": "var(--palette-background-paper)",
    "background-default": "var(--palette-background-default)",
    "divider": "var(--palette-divider)",
    "text-primary": "var(--palette-text-primary)",
    "text-secondary": "var(--palette-text-secondary)",
    "text-disabled": "var(--palette-text-disabled)",
    "text-icon": "var(--palette-text-icon)",
    "text-links": "var(--palette-text-links)",
    "text-placeholder": "var(--palette-text-placeholder)",
    "text-subtle": "var(--palette-text-subtle)",
    "text-inverted": "var(--palette-text-inverted)"
  }
}
 
 module.exports.spacing = {
  "xxs": "var(--spacing-xxs)",
  "xs": "var(--spacing-xs)",
  "s": "var(--spacing-s)",
  "m": "var(--spacing-m)",
  "l": "var(--spacing-l)",
  "xl": "var(--spacing-xl)",
  "2xl": "var(--spacing-2xl)",
  "3xl": "var(--spacing-3xl)",
  "4xl": "var(--spacing-4xl)"
}
 
 module.exports.borderRadius = {
  "xs": "var(--border-radius-xs)",
  "sm": "var(--border-radius-sm)",
  "md": "var(--border-radius-md)",
  "lg": "var(--border-radius-lg)",
  "full": "var(--border-radius-full)"
}
 
 module.exports.borderWidth = {
  "large": "var(--border-width-large)",
  "medium": "var(--border-width-medium)",
  "small": "var(--border-width-small)"
}
 
 module.exports.fontSize = {
  "s": "var(--font-size-s)",
  "m": "var(--font-size-m)",
  "l": "var(--font-size-l)",
  "xl": "var(--font-size-xl)",
  "2xl": "var(--font-size-2xl)",
  "4xl": "var(--font-size-4xl)",
  "btn-l": "var(--font-size-btn-l)",
  "btn-m": "var(--font-size-btn-m)",
  "btn-s": "var(--font-size-btn-s)"
}
 
 module.exports.lineHeight = {
  "s": "var(--line-size-s)",
  "m": "var(--line-size-m)",
  "l": "var(--line-size-l)",
  "xl": "var(--line-size-xl)",
  "xxl": "var(--line-size-xxl)"
}
 
 module.exports.outlineWidth = {
  "xs": "var(--outline-size-xs)",
  "sm": "var(--outline-size-sm)",
  "md": "var(--outline-size-md)",
  "lg": "var(--outline-size-lg)",
  "xl": "var(--outline-size-xl)"
}
 
 module.exports.boxShadow = {
  "shadow1": "var(--box-shadow-shadow1)",
  "shadow2": "var(--box-shadow-shadow2)",
  "shadow3": "var(--box-shadow-shadow3)",
  "shadow4": "var(--box-shadow-shadow4)"
}
 
 module.exports.iconSize = {
  "l": "var(--icon-size-l)",
  "m": "var(--icon-size-m)",
  "s": "var(--icon-size-s)",
  "xs": "var(--icon-size-xs)"
}