import React from "react";
import { SvgIconProps } from "@ds-proxy";
import { Icon } from "@ds-ui";

export const ArrowIcon = ({ open, ...props }: SvgIconProps & { open?: boolean }) => {
  return open ? (
    <Icon {...props} name={"KeyboardArrowUpOutlined"} />
  ) : (
    <Icon {...props} name={"KeyboardArrowDownOutlined"} />
  );
};
