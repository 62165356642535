import React from "react";
import { Typography } from "@ds-proxy";
import { Icon } from "@ds-ui";
import { Stack } from "libs/layouts";
import { css } from "ui/css";
import { Box } from "ui/atoms/Box";

const stackStyles = css({
  height: "auto",
  margin: "auto",
});

const fullWidthStyles = css({
  width: "100%",
});

export function Alert({
  title,
  subtitle = "",
  icon = <Icon name={"ErrorOutlined"} />,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  icon?: React.ReactElement;
  title: string;
  subtitle?: string;
}) {
  return (
    <Box
      sx={{ paddingX: "var(--spacing-xl)", height: "100%", width: "100%", centerContent: true }}
      {...rest}
    >
      <Stack space={1} className={stackStyles().className}>
        {icon &&
          React.cloneElement(icon, { style: { fontSize: 80, width: "100%" }, color: "action" })}
        <Typography
          color="textSecondary"
          align="center"
          style={{
            fontWeight: 500,
          }}
          variant="subtitle1"
          className={fullWidthStyles().className}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography
            color="textSecondary"
            align="center"
            variant="subtitle1"
            className={fullWidthStyles().className}
          >
            {subtitle}
          </Typography>
        )}
      </Stack>
    </Box>
  );
}
