import React, { useEffect, useMemo, useState } from "react";
import { DataGridPro, DataGridProProps, gridClasses, useGridApiRef } from "@mui/x-data-grid-pro";
import { SxProps } from "@mui/system";
import { Theme } from "@ds-proxy";
import { waitForElementToExist } from "libs/dom";
import { cellHorizontalPadding, gridSpacingPx } from "../theme";
import { customGridClasses } from "../constants";
import { EMPTY_GRID_ACTIONS } from "../colDef/actions";
import { ScrollState, useScrollState } from "../useScrollState";

const zIndex = {
  menu: 1000,
  resizer: 10001,
};

const gridPanelStyles = (hideFilterLogicOperator?: boolean): SxProps<Theme> => ({
  [`& .${gridClasses.filterFormOperatorInput}, & .${gridClasses.filterFormColumnInput}`]: {
    justifyContent: "flex-end",
  },
  [`& .${gridClasses.filterFormOperatorInput}`]: {
    width: "140px",
  },
  ...(hideFilterLogicOperator
    ? {
        [`& .${gridClasses.filterFormLogicOperatorInput}`]: {
          display: "none",
        },
      }
    : {}),
});

const gridFilterPanelStyles = (): SxProps<Theme> => ({
  [`& .${gridClasses.panelFooter}`]: { display: "none" },
  [`& .${gridClasses.filterFormDeleteIcon}`]: { marginBottom: "6px" },
});

export interface GridDisplayState {
  scroll: ScrollState;
  pinnedColumns: {
    hasLeft: boolean;
    hasRight: boolean;
  };
  hasActions?: boolean;
  alignTop?: boolean;
  isExpandable?: boolean;
}

export const gridStyles = ({
  scroll,
  pinnedColumns,
  hasActions,
  alignTop,
  isExpandable,
}: GridDisplayState): SxProps<Theme> => ({
  border: "none",
  color: "var(--palette-text-primary)",
  [`& .${gridClasses.main}`]: {
    borderTopWidth: "2px",
    borderTopColor: "var(--palette-text-disabled)",
  },
  [`& .${gridClasses.cell}`]: {
    "position": "relative",
    "backgroundColor": "var(--background-base0)",
    "border": "none",
    "padding": alignTop ? `16px ${cellHorizontalPadding}` : `0px ${cellHorizontalPadding}`,
    "transition": ".3s ease-out background-color",
    "cursor": "pointer",
    [`&:not([data-field="actions"])`]: {
      ...(alignTop
        ? {
            alignItems: "start",
            lineHeight: "1.8",
          }
        : {}),
    },
    "&:focus, &:focus-within": {
      outline: "none",
    },
    [`&.${customGridClasses.zeroNumberCell}`]: {
      color: "var(--palette-text-secondary)",
    },
    [`&.${customGridClasses.highlightCell}`]: {
      color: "var(--palette-error-main)",
      fontWeight: 500,
    },
    "paddingLeft": isExpandable
      ? cellHorizontalPadding
      : `calc(${cellHorizontalPadding} + ${gridSpacingPx})`,
    [`&[data-field="__detail_panel_toggle__"]`]: {
      paddingLeft: "0px",
      fontSize: "var(--font-size-xl)",

      button: {
        fontSize: "1em",
      },
    },
    [`&.${gridClasses.cellCheckbox}`]: {
      paddingLeft: isExpandable ? "0px" : gridSpacingPx,
    },
    [`&[data-field="actions"]`]: {
      padding: 0,
    },
  },
  [`& .${gridClasses.pinnedColumns}`]: {
    minHeight: "0 !important",
    background: "transparent",
    overflow: "visible",
    display: "flex",
    flexDirection: "column",
    [`&.${gridClasses["pinnedColumns--right"]}`]: {
      zIndex: pinnedColumns.hasRight || hasActions ? 1 : -1,
    },
  },
  [`& .${gridClasses.columnHeaders}`]: {
    borderBottomWidth: "2px",
    borderBottomColor: "var(--palette-divider)",
    zIndex: 2,
    boxShadow: !scroll.isScrolled && "var(--box-shadow-shadow1)",
    background: "var(--background-base0)",

    // Override native style which hides last column border
    [`& .${gridClasses.columnHeadersInner} .${gridClasses.columnHeader}:last-child`]: {
      borderRight: `2px solid var(--palette-text-disabled)`,
    },

    [`& .${gridClasses.columnHeadersInner} .${gridClasses.columnHeader}:first-child`]: {
      marginLeft: isExpandable ? "0px" : gridSpacingPx,
    },
    [`& .${gridClasses["pinnedColumnHeaders--left"]}`]: {
      [`& .${gridClasses.columnHeaderTitleContainer}`]: {
        paddingLeft: isExpandable ? "0px" : gridSpacingPx,
      },
      [`& .${gridClasses.columnHeaderCheckbox} .${gridClasses.columnHeaderTitleContainer}`]: {
        paddingLeft: isExpandable ? "0px" : gridSpacingPx,
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    background: "var(--background-base0)",
    borderRight: `2px solid var(--palette-divider)`,
    outline: "none !important",
    padding: 0,
    [`.${customGridClasses.filterIconButton} > svg`]: {
      display: "none",
    },
    [":hover"]: {
      [`.${customGridClasses.filterIconButton} > svg`]: {
        display: "block",
      },
    },
    [`&:not(.${gridClasses.columnHeaderCheckbox}, .${customGridClasses.actionsHeader}) .${gridClasses.columnHeaderTitleContainerContent}`]:
      {
        paddingTop: "6px",
      },
    [`& .${gridClasses.menuIcon}`]: {
      margin: 0,
      button: {
        zIndex: zIndex.menu,
      },
    },
    [`&.${gridClasses.columnHeaderCheckbox} > .${gridClasses.columnSeparator}`]: {
      display: "none !important",
    },
    [`&[data-field="__detail_panel_toggle__"]`]: {
      border: "none",
      [`.${gridClasses.columnSeparator}`]: {
        display: "none",
      },
      fontSize: "var(--font-size-xl)",

      button: {
        fontSize: "1em",
      },
    },
    [`&:not(.${gridClasses.columnHeaderCheckbox}) .${gridClasses.iconButtonContainer} > button`]: {
      padding: `${gridSpacingPx} 3px`,
    },
    [`&.${gridClasses.columnHeaderCheckbox}, & .${gridClasses.cellCheckbox}`]: {
      borderRight: "none",
    },
  },
  [`& .${gridClasses["pinnedColumnHeaders--right"]} .${gridClasses.columnHeader}`]: {
    [`&:last-child`]: {
      borderRight: "none",
    },
  },
  [`& .${gridClasses.columnHeaderTitleContainerContent}`]: {
    padding: `6px ${cellHorizontalPadding}`,
  },
  [`& .${gridClasses["columnHeader--sortable"]}:hover .${gridClasses.columnHeaderTitleContainerContent},
    & .${gridClasses["columnHeader--sorted"]} .${gridClasses.columnHeaderTitleContainerContent}`]: {
    paddingRight: 0,
  },
  [`& .${gridClasses["columnHeader--sortable"]}.${gridClasses["columnHeader--alignRight"]}:hover .${gridClasses.columnHeaderTitleContainerContent},
    & .${gridClasses["columnHeader--sorted"]}.${gridClasses["columnHeader--alignRight"]} .${gridClasses.columnHeaderTitleContainerContent}`]:
    {
      paddingRight: gridSpacingPx,
      paddingLeft: 0,
    },
  [`& .${gridClasses["columnSeparator--sideRight"]}`]: {
    width: "6px",
    right: "-15px",
    zIndex: zIndex.resizer,
  },
  [`& .${gridClasses.checkboxInput}`]: {
    padding: gridSpacingPx,
  },
  [`& .${gridClasses.toolbarContainer}`]: {
    padding: 0,
    marginBottom: "21px",
    height: "auto",
  },
  [`& .${gridClasses.columnHeaderTitleContainer}`]: {
    paddingRight: "4px",
  },
  [`& .${gridClasses.columnHeaderCheckbox} .${gridClasses.columnHeaderTitleContainerContent},
    & [data-field="__detail_panel_toggle__"] .${gridClasses.columnHeaderTitleContainerContent},
    & .${gridClasses.cellCheckbox},
    & .${customGridClasses.actionsHeader} .${gridClasses.columnHeaderTitleContainerContent}`]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  [`& .${gridClasses.detailPanelToggleCell}`]: {
    padding: "0px",
  },
  [`& .${gridClasses.checkboxInput}`]: {
    "padding": "0px",
    "& svg": {
      fontSize: "24px",
    },
  },
  [`& .${gridClasses.cellContent}`]: {
    fontSize: "13px",
  },
  [`& .${gridClasses.virtualScroller}`]: {
    "flexGrow": 1,
    "&::-webkit-scrollbar, &::-webkit-scrollbar-track, &::-webkit-scrollbar-track:hover": {
      background: "transparent",
    },
  },
  [`& .${gridClasses.virtualScrollerRenderZone} .${gridClasses.row}`]: {
    [`&.picked`]: {
      boxShadow: "0 1px 0 0 var(--background-shadow), 0 -1px 0 0 var(--background-shadow)",
      [`& .${gridClasses.cell}:first-of-type`]: {
        boxShadow: "inset 1px 0px 0px 0px var(--background-shadow)",
      },
      [`& .${gridClasses.cell}:last-of-type`]: {
        boxShadow: "inset -1px 0px 0px 0px var(--background-shadow)",
      },
    },
    [`&.Mui-hovered, &.Mui-hovered .${gridClasses.cell}, &.picked .${gridClasses.cell}`]: {
      backgroundColor: "var(--background-active) !important",
    },
  },
  // Actions column is pinned, but we show it as regular column unless we have horizontal scroll or other pinned columns
  ...(!pinnedColumns.hasRight && (!scroll.horizontal.visible || !hasActions)
    ? {
        [`& .${gridClasses.virtualScrollerContent} .${gridClasses["pinnedColumns--right"]}`]: {
          boxShadow: "none",
          [`& .${gridClasses.row}`]: {
            boxShadow: "none",
            [`&.${gridClasses["row--lastVisible"]}`]: {
              boxShadow: "none",
            },
            [`&.picked`]: {
              boxShadow:
                "0 1px 0 0 var(--background-shadow), 0 -1px 0 0 var(--background-shadow) !important",
              [`& .${gridClasses.cell}:last-of-type`]: {
                boxShadow: "inset -1px 0px 0px 0px var(--background-shadow)",
              },
            },
            [`&.Mui-hovered, &.Mui-hovered .${gridClasses.cell}, &.picked .${gridClasses.cell}`]: {
              backgroundColor: "var(--background-base2) !important",
            },
          },
        },
        [`& .${gridClasses.pinnedColumnHeaders}.${gridClasses["pinnedColumnHeaders--right"]}`]: {
          boxShadow: "none",
          borderLeft: "2px solid var(--palett-divider)",
        },
        [`& .${gridClasses.virtualScrollerRenderZone} .${gridClasses.cell}:last-of-type`]: {
          boxShadow: "none !important",
        },
      }
    : {}),
});

export function StyledDataGridPro({
  alignTop,
  customSx = (style) => style,
  isExpandable,
  hideFilterLogicOperator,
  ...props
}: DataGridProProps & {
  alignTop?: boolean;
  isExpandable?: boolean;
  hideFilterLogicOperator?: boolean;
  customSx?: (style: SxProps<Theme>, displayState: GridDisplayState) => SxProps<Theme>;
}) {
  const [gridElement, setGridElement] = useState<HTMLDivElement | null>();
  const [virtualScrollerElement, setVirtualScrollerElement] = useState<HTMLDivElement | null>(null);
  useEffect(() => {
    if (gridElement && !virtualScrollerElement) {
      const waitForVirtualScroller = async () => {
        const el = await waitForElementToExist(gridElement, `.${gridClasses.virtualScroller}`);
        if (el) {
          setVirtualScrollerElement(el as HTMLDivElement);
        }
      };

      waitForVirtualScroller();
    }
  }, [gridElement, virtualScrollerElement]);

  const hasActions = useMemo(
    () =>
      props.columns.some(
        (column) => column.type === "actions" && (column as any).getActions != EMPTY_GRID_ACTIONS
      ),
    [props.columns]
  );
  const pinnedColumns = useMemo(
    () => ({
      hasRight: Boolean(
        props.pinnedColumns?.right?.filter(
          (col) => props.columnVisibilityModel?.[col] && col !== "actions"
        )?.length
      ),
      hasLeft: Boolean(
        props.pinnedColumns?.left?.filter((col) => props.columnVisibilityModel?.[col])?.length
      ),
    }),
    [props.pinnedColumns, props.columnVisibilityModel]
  );

  const innerApiRef = useGridApiRef();
  const apiRef = props.apiRef ?? innerApiRef;

  const displayState = {
    scroll: useScrollState(apiRef, virtualScrollerElement),
    hasActions,
    pinnedColumns,
    alignTop,
    isExpandable,
  };

  return (
    <DataGridPro
      ref={setGridElement}
      apiRef={apiRef}
      sx={customSx(gridStyles(displayState), displayState)}
      {...props}
      slotProps={{
        ...props.slotProps,
        panel: { ...props.slotProps?.panel, sx: gridPanelStyles(hideFilterLogicOperator) },
        filterPanel: { ...props.slotProps?.filterPanel, sx: gridFilterPanelStyles() },
        loadingOverlay: {
          ...props.slotProps?.loadingOverlay,
          style: !props.rows.length
            ? {
                background: "transparent",
              }
            : {},
        },
      }}
    />
  );
}
