import React, { FunctionComponent } from "react";
import { useAppEnvironment } from "shared/environment";
import { useCan } from "shared/permissions";
import { useLocation, Navigate } from "react-router-dom";
import { LayoutLoader } from "@ds-proxy";
import { authState } from "modules/auth";
import { useSelector } from "libs/hooks";
import { TermsAndConditions, useTermsState } from "modules/terms";
import { Permission } from "api/types";
import { ErrorState } from "ui";
import { AccessDeniedPage } from "../pages/AccessDeniedPage";

export interface IProtectedRoute {
  requiredPermissions?: Permission[];
  requiredPermissionsAnyOf?: Permission[];
}

const RouteAccessCheck: FunctionComponent<React.PropsWithChildren<IProtectedRoute>> = ({
  children,
  requiredPermissions,
  requiredPermissionsAnyOf,
}) => {
  const isAccepted = useTermsState();
  const { data, isLoading, isError } = useCan(
    (requiredPermissions || requiredPermissionsAnyOf) ?? [],
    Boolean(requiredPermissionsAnyOf)
  );

  if (isLoading) {
    return <LayoutLoader />;
  }

  if (isError) {
    return <ErrorState />;
  }

  if (!data) {
    return <AccessDeniedPage />;
  }
  if (!isAccepted) {
    return <TermsAndConditions />;
  }
  return children as any;
};

export const RouteUserCheck: FunctionComponent<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const location = useLocation();
  const { id } = useSelector(authState.stores.user);
  const { routes } = useAppEnvironment();

  if (!id && routes) {
    return (
      <Navigate
        to={routes.login.path}
        replace
        state={{ from: location.pathname + location.search }}
      />
    );
  }

  return <>{children}</>;
};

export const ProtectedRoute: FunctionComponent<React.PropsWithChildren<IProtectedRoute>> = (
  props
) => {
  return (
    <RouteUserCheck>
      <RouteAccessCheck {...props} />
    </RouteUserCheck>
  );
};
