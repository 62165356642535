import React from "react";
import { TextField } from "@ds-proxy";
import { mapProps } from "./mapError";

export class TextFieldInput extends React.PureComponent {
  render() {
    return <TextField {...mapProps(this.props)} />;
  }
}

TextFieldInput.defaultProps = {
  blacklist: [],
};
