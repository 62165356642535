import { Link as MUILink } from "@ds-proxy";
import { styled } from "ui/css";

export const Link = styled(MUILink, {
  variants: {
    bold: {
      true: {
        ":hover": {
          fontWeight: "bold",
        },
      },
    },
  },
});
