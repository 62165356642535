// @inheritedComponent ButtonBase

import React from "react";
import clsx from "classnames";
import { upperFirst } from "lodash";
import { styled, alpha, ButtonBase } from "@ds-proxy";

const PREFIX = "MuiToggleButton";

const styleClasses = {
  root: `${PREFIX}-root`,
  disabled: `${PREFIX}-disabled`,
  selected: `${PREFIX}-selected`,
  label: `${PREFIX}-label`,
  sizeSmall: `${PREFIX}-sizeSmall`,
  sizeMedium: `${PREFIX}-sizeMedium`,
  sizeLarge: `${PREFIX}-sizeLarge`,
};

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  /* Styles applied to the root element. */
  [`&.${styleClasses.root}`]: {
    ...theme.typography.button,
    "boxSizing": "border-box",
    "textTransform": "none",
    "fontWeight": "normal",
    "borderRadius": theme.shape.borderRadius,
    "padding": "0px 12px",
    "border": `1px solid var(--palette-divider)`,
    "color": "var(--palette-text-primary)",
    "&.MuiToggleButton-selected": {
      "border": "none",
      "borderRadius": theme.shape.borderRadius,
      "color": theme.palette.getContrastText("#000"),
      "backgroundColor": "var(--palette-primary-main)",
      "&:hover": {
        backgroundColor: "var(--palette-primary-main)",
      },
      "& + &": {
        borderLeft: 0,
        marginLeft: 0,
      },
    },
    "&$disabled": {
      color: alpha(theme.palette.action.disabled, 0.12),
    },
    "&:hover": {
      "textDecoration": "none",
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
      "&$disabled": {
        backgroundColor: "transparent",
      },
    },
  },

  /* Pseudo-class applied to the root element if `disabled={true}`. */
  [`&.${styleClasses.disabled}`]: {},

  /* Pseudo-class applied to the root element if `selected={true}`. */
  [`&.${styleClasses.selected}`]: {},

  /* Styles applied to the `label` wrapper element. */
  [`& .${styleClasses.label}`]: {
    width: "100%", // Ensure the correct width for iOS Safari
    display: "inherit",
    alignItems: "inherit",
    justifyContent: "inherit",
  },

  /* Styles applied to the root element if `size="small"`. */
  [`& .${styleClasses.sizeSmall}`]: {
    height: 25,
    padding: "0px 8px",
    fontSize: theme.typography.pxToRem(13),
  },

  [`& .${styleClasses.sizeMedium}`]: {
    height: 30,
    padding: "0px 16px",
    fontSize: theme.typography.pxToRem(15),
  },

  /* Styles applied to the root element if `size="large"`. */
  [`& .${styleClasses.sizeLarge}`]: {
    height: 40,
    padding: "0px 16px",
    fontSize: theme.typography.pxToRem(15),
  },
}));

function capitalize(a: any) {
  return upperFirst(a);
}

export {};

const ToggleButton = React.forwardRef(function ToggleButton(props: any, ref) {
  const {
    children,
    classes,
    className,
    disabled = false,
    disableFocusRipple = false,
    disableRipple = false,
    onChange,
    onClick,
    selected,
    selectedColor,
    size = "medium",
    value,
    ...other
  } = props;

  const handleChange = (event: any) => {
    if (onClick) {
      onClick(event, value);
      if (event.isDefaultPrevented()) {
        return;
      }
    }

    if (onChange) {
      onChange(event, value);
    }
  };

  return (
    <StyledButtonBase
      className={clsx(
        styleClasses.root,
        {
          [styleClasses.disabled]: disabled,
          [styleClasses.selected]: selected,
          [styleClasses[`size${capitalize(size)}` as unknown as keyof typeof styleClasses]]: size,
        },
        classes,
        className
      )}
      disabled={disabled}
      focusRipple={!disableFocusRipple}
      ref={ref}
      onClick={handleChange}
      onChange={onChange}
      value={value}
      disableRipple={disableRipple}
      aria-pressed={selected}
      style={
        selected
          ? {
              backgroundColor: selectedColor,
            }
          : undefined
      }
      {...other}
    >
      <span className={styleClasses.label}>{children}</span>
    </StyledButtonBase>
  );
});

export default ToggleButton;
