import React, { useLayoutEffect, useState } from "react";
import { gridClasses } from "@mui/x-data-grid-pro";
import { Chip } from "@ds-proxy";
import { HStack, HStackProps, Paper, Popover, VStack, Text } from "ui";
import { usePopover } from "libs/hooks";
import { GRID_DEFAULT_EMPTY_LABEL } from "../formatters";

interface GridTruncateCellItemsProps<T> extends React.ComponentProps<HStackProps> {
  items?: T[];
  renderItem: (
    item: T,
    context: { shrink?: boolean; last?: boolean; popover?: boolean }
  ) => React.ReactNode;
}
export function GridTruncateCellItems<T>({
  items,
  renderItem,
  ...restProps
}: GridTruncateCellItemsProps<T>) {
  const [containerEl, setContainerEl] = useState<HTMLDivElement | null>();
  const [fitItemsCount, setFitItemsCount] = useState(0);
  const truncatedItems = fitItemsCount ? items?.slice(0, fitItemsCount) : items;
  const otherItems = fitItemsCount ? items?.slice(fitItemsCount) : [];

  const [measureItemsNode, getItemWidth] = useMeasureItems(
    items?.map((item, i) => renderItem(item, { shrink: false, last: i === items!.length - 1 }))
  );

  useLayoutEffect(() => {
    if (!containerEl || !items) {
      return;
    }

    const plusNChipWidth = 30;
    const gap = 5;
    let availableWidth = containerEl.clientWidth - plusNChipWidth;

    let fitItemsCount;
    for (fitItemsCount = 0; fitItemsCount < items.length; fitItemsCount++) {
      availableWidth -= getItemWidth(fitItemsCount) + gap;
      if (availableWidth < 0) {
        break;
      }
    }
    setFitItemsCount(fitItemsCount || 1);
  }, [containerEl?.clientWidth]);

  if (!items?.length) {
    return <HStack className={gridClasses.cellContent}>{GRID_DEFAULT_EMPTY_LABEL}</HStack>;
  }

  return (
    <HStack
      ref={setContainerEl}
      className={gridClasses.cellContent}
      align="center"
      space="1"
      fullWidth
      {...restProps}
    >
      {truncatedItems?.map((item, i) =>
        renderItem(item, {
          shrink: truncatedItems!.length === 1,
          last: i === truncatedItems!.length - 1,
        })
      )}
      <OtherItemsPopover items={otherItems} renderItem={renderItem} />
      {measureItemsNode}
    </HStack>
  );
}

interface OtherItemsPopoverProps<T> {
  items?: T[];
  renderItem: (
    item: T,
    context: { shrink?: boolean; last?: boolean; popover?: boolean }
  ) => React.ReactNode;
}
function OtherItemsPopover<T>({ items, renderItem }: OtherItemsPopoverProps<T>) {
  const [popover, popoverActions] = usePopover((api) => (
    <Popover {...api}>
      <Paper style={{ padding: "var(--spacing-s)" }}>
        <VStack space="1">
          {items?.map((item, i) => (
            <HStack key={i}>
              <Text sx={{ marginRight: "var(--spacing-xs)" }}>{"•"}</Text>
              {renderItem(item, {
                shrink: false,
                last: i === items!.length - 1,
                popover: true,
              })}
            </HStack>
          ))}
        </VStack>
      </Paper>
    </Popover>
  ));

  if (!items?.length) {
    return null;
  }

  return (
    <>
      <Chip
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          popoverActions.onOpen(e.target);
        }}
        label={`+ ${items.length}`}
        size={"small"}
      />
      {popover}
    </>
  );
}

function useMeasureItems(
  renderedItems: React.ReactNode
): [React.ReactNode, (index: number) => number] {
  const [measureContainerEl, setMeasureContainerEl] = useState<HTMLDivElement | null>();

  const measureNode = (
    <div
      ref={setMeasureContainerEl}
      style={{ position: "absolute", zIndex: -1, pointerEvents: "none", visibility: "hidden" }}
    >
      {renderedItems}
    </div>
  );

  const getItemWidth = (index: number) => {
    return (measureContainerEl?.childNodes[index] as HTMLElement)?.clientWidth || 0;
  };

  return [measureNode, getItemWidth];
}
