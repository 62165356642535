import React from "react";
import { Merge } from "ts-essentials";
import { PartialBy } from "shared";
import { TablePagination, TablePaginationProps } from "@ds-proxy";
import styles from "./Pagination.module.css";

const classes = {
  spacer: styles.spacerStyles,
  toolbar: styles.toolbarStyles,
};

export function Pagination({
  onChangePage,
  disabled,
  ...props
}: Merge<
  PartialBy<TablePaginationProps, "onPageChange">,
  { disabled: { next?: boolean; prev?: boolean }; onChangePage: (n: number) => void }
>) {
  return (
    <TablePagination
      component={"div" as any}
      classes={classes}
      rowsPerPageOptions={[25]}
      nextIconButtonProps={{
        disabled: disabled.next,
      }}
      backIconButtonProps={{
        disabled: disabled.prev || props.page === 0,
      }}
      labelDisplayedRows={({ from, to, count }) =>
        `${from}-${to} of ${count === Infinity ? "Many" : count}`
      }
      onPageChange={(_, n) => {
        onChangePage(n);
      }}
      {...props}
    />
  );
}
