import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { difference, isEqual } from "lodash";
import classNames from "classnames";
import { DEFAULT_DATE_FORMAT } from "shared";
import { Checkbox, Icon } from "@ds-ui";
import {
  DataGridProProps,
  GridRowId,
  useGridApiRef,
  GridRowSelectionModel as GridRowSelectionModelInternal,
  GridColumnResizeParams,
  GridFilterModel,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GRID_CHECKBOX_SELECTION_FIELD,
  GridLoadingOverlay,
  GridRowClassNameParams,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";
import { SxProps } from "@mui/system";
import { Theme } from "@ds-proxy/mui-proxy/styles";
import { useGrid } from "./useGrid";
import { GridToolbar, StyledDataGridPro } from "./organisms";
import { GridNoRowsOverlay, GridToolbarButton } from "./atoms";
import { GridColumnMenu } from "./molecules";
import { columnOrderWithoutFixed } from "./utils";
import { mapFiltersToMuiFilterModel, mapMuiFilterModelToFilters } from "./filters";
import { GridRowSelectionModel } from "./types";
import { normalizeColumnDefinitions } from "./normalizeColumnDefinitions";
import { CHGridLoadingOverlay } from "./CHGridLoadingOverlay";
import { ColumnSortedAscendingIcon } from "./molecules/ColumnSortedAscendingIcon";
import { ColumnSortedDescendingIcon } from "./molecules/ColumnSortedDescendingIcon";
import { ColumnUnsortedIcon } from "./molecules/ColumnUnsortedIcon";
import {
  DISABLED_ROW_CLASS_NAME,
  PICKED_ROW_CLASS_NAME,
  DETAIL_PANEL_COLLAPSE_ICON,
  DETAIL_PANEL_EXPAND_ICON,
} from "./constants";
import { useColumnsPinning } from "./useColumnsPinning";
import { newDesignSystemStyles } from "./CHDataGrid.style";
import { newDesign } from "./theme";

const DetailPanelExpandIcon: React.FC = (props) => (
  <Icon name={DETAIL_PANEL_EXPAND_ICON} {...props} />
);
const DetailPanelCollapseIcon: React.FC = (props) => (
  <Icon name={DETAIL_PANEL_COLLAPSE_ICON} {...props} />
);

export interface CHGridProps<R extends GridValidRowModel = any>
  extends Omit<DataGridProProps<R>, "columns" | "components" | "componentsProps" | "slots"> {
  onGetMoreRows?: () => Promise<{ newRows: Array<{ id: GridRowId }> | null }>;
  getDetailPanelContent?: (row: any) => React.ReactNode;
  autoExpandSingleRow?: boolean;
  messages?: {
    item?: string;
    itemPlural?: string;
    searchPlaceholder?: string;
  };
  renderToolbarActions?: (selection: GridRowSelectionModel) => React.ReactNode;
  hasMoreRows?: boolean;
  totalRowsCount?: number;
  showTotalRowsCount?: boolean;
  canPickRows?: boolean;
  onRowPicked?: (id: GridRowId | undefined) => any;
  onRefresh?: () => any;
  dateFormat?: string;
  timezone?: string;
  searchEnabled?: boolean;
  noRowsTitle?: string;
  noRowsDescription?: string;
  alignTop?: boolean;
  hideActions?: boolean;
  hideFilterLogicOperator?: boolean;
  customSx?: (style: SxProps<Theme>) => SxProps<Theme>;
  disableSelectAll?: boolean;
  designSystemStyles?: boolean;
  isRowDisabled?: (row: unknown) => boolean;
  // Omitting detail panel icons for convenience. Support can be added if needed.
  slots?: Omit<
    NonNullable<DataGridProProps<R>["slots"]>,
    "detailPanelExpandIcon" | "detailPanelCollapseIcon"
  >;
}

/**
 * Because we ignore empty filters, we should change the MUI filter model only when
 * the filters we care about are different from the ones in MUI.
 * This function is used to determine if we should change the MUI filter model.
 */
export const areNewFiltersIncludedInMuiFilters = (
  newFilters: GridFilterModel,
  muiFilters: GridFilterModel
): boolean => {
  if (newFilters.logicOperator !== muiFilters.logicOperator) {
    return false;
  }

  if (newFilters.items.length > muiFilters.items.length) {
    // We added new filters.
    // Usually we should have as many or fewer fields than MUI, as we ignore empty filters.
    return false;
  }

  if (newFilters.items.length === muiFilters.items.length) {
    // When we have the same number of filters they should be identical.
    return isEqual(newFilters, muiFilters);
  }

  // If we got here it means that either MUI has some empty filters we ignored, or we removed filters.
  // Here we verify that the fields we care about are in MUI filters.
  const verifiedFields = new Set();
  for (const filter of newFilters.items) {
    const muiFilter = muiFilters.items.find((muiF) => muiF.field === filter.field);

    if (!isEqual(filter, muiFilter)) {
      return false;
    }

    verifiedFields.add(filter.field);
  }

  // All of our filters matched, now we need to make sure that everything else in MUI is empty.
  for (const muiFilter of muiFilters.items) {
    if (!verifiedFields.has(muiFilter.field) && muiFilter.value) {
      return false;
    }
  }

  return true;
};

export function CHDataGrid<R extends GridValidRowModel>({
  onGetMoreRows,
  hasMoreRows,
  rows,
  totalRowsCount,
  showTotalRowsCount,
  renderToolbarActions,
  messages = {},
  canPickRows,
  dateFormat = DEFAULT_DATE_FORMAT,
  timezone,
  onRowPicked,
  onRefresh,
  getDetailPanelContent,
  searchEnabled,
  noRowsTitle,
  noRowsDescription,
  onRowClick,
  hideActions,
  hideFilterLogicOperator,
  slots,
  disableSelectAll,
  autoExpandSingleRow = true,
  customSx,
  designSystemStyles = false,
  isRowDisabled,
  getRowClassName,
  ...restProps
}: CHGridProps<R>) {
  const gridState = useGrid();
  const gridStateRef = useRef(gridState);
  gridStateRef.current = gridState;
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([]);
  const detailsPanelExpandedRowIdsSet = useMemo(
    () => new Set(detailPanelExpandedRowIds),
    [detailPanelExpandedRowIds]
  );

  const {
    columnVisibilityModel: storedColumnVisibilityModel,
    setColumnVisibilityModel,
    search,
    sortModel,
    setSortModel,
    columnsOrder: storedColumnsOrder,
    columnsWidths,
    setColumnsWidths,
    selectionModel,
    setSelectionModel,
    pickedRow,
    setPickedRow,
    density,
    filters,
    setFilters,
    columns,
    resetGrid,
  } = gridState;

  const columnVisibilityModel = {
    ...storedColumnVisibilityModel,
    [GRID_DETAIL_PANEL_TOGGLE_FIELD]: Boolean(getDetailPanelContent),
    [GRID_CHECKBOX_SELECTION_FIELD]: Boolean(restProps.checkboxSelection),
  };

  const columnsOrder = columnOrderWithoutFixed(storedColumnsOrder);

  const apiRef = useGridApiRef();

  useEffect(() => {
    if (apiRef.current) {
      return apiRef.current.subscribeEvent("columnHeaderDragEnd", () => {
        const state = apiRef.current.exportState();
        gridStateRef.current.setColumnsOrder(state.columns!.orderedFields!);
      });
    }
    return;
  }, [apiRef.current]);

  useEffect(() => {
    (window as any).resetGrid = () => gridStateRef.current.resetGrid();

    return () => {
      delete (window as any)["resetGrid"];
    };
  }, []);

  const normalizedColumns = useMemo(
    () =>
      normalizeColumnDefinitions(columns, {
        dateFormat,
        timezone,
        hideActions,
        disableSelectAll,
        newStyle: designSystemStyles,
      }),
    [columns, dateFormat, timezone, hideActions, disableSelectAll]
  );

  const [normalizedPinnedColumns, onPinnedColumnsChange] = useColumnsPinning({
    columns: normalizedColumns,
    columnsOrder,
  });

  // Convert our generic filter model to specific mui data grid model
  const [filterModel, setFilterModel] = useState(() => mapFiltersToMuiFilterModel(filters));

  const onFilterModelChange = useCallback(
    (filterModel: GridFilterModel) => {
      setFilterModel(filterModel);

      const newFilters = mapMuiFilterModelToFilters(filterModel, normalizedColumns);
      if (!isEqual(filters, newFilters)) {
        setFilters(newFilters);
      }
    },
    [filters, normalizedColumns]
  );

  useEffect(() => {
    const newFilterModel = mapFiltersToMuiFilterModel(filters);
    if (!areNewFiltersIncludedInMuiFilters(newFilterModel, filterModel)) {
      setFilterModel(newFilterModel);
    }
  }, [filters]);

  const onColumnsWidthChange = useCallback(
    ({ colDef, width }: GridColumnResizeParams) => {
      setColumnsWidths({
        ...columnsWidths,
        [colDef.field]: width,
      });
    },
    [columnsWidths]
  );

  const onSelectionModelChange = useCallback(
    (selected: GridRowSelectionModelInternal) => {
      const rowsCount = rows.length;
      const areSomeSelected = selected.length > 0;
      const isAllSelected = selected.length === rowsCount;
      const wasAllSelected = selectionModel.all;

      // When we click select on single item when all selected, then we invert logic and make it the only selected item
      if (wasAllSelected && !isAllSelected && areSomeSelected) {
        const excluded = difference(selectionModel.selected, selected);

        setSelectionModel({ selected: excluded, all: false });
      } else {
        setSelectionModel({ selected: selected, all: isAllSelected });
      }
    },
    [rows, selectionModel]
  );

  const handleOnRowsScrollEnd = async () => {
    if (!hasMoreRows || !onGetMoreRows) {
      return;
    }

    const { newRows } = await onGetMoreRows();
    // If "All" are selected then add new items to selected array
    if (newRows && selectionModel.all) {
      setSelectionModel({
        ...selectionModel,
        selected: [...selectionModel.selected, ...newRows.map((row) => row.id)],
      });
    }
  };

  const handleRowClick = (rowId: GridRowId) => {
    if (canPickRows) {
      if (rowId !== pickedRow) {
        setPickedRow(rowId);
        onRowPicked?.(rowId);
      }
    }
    if (getDetailPanelContent) {
      setDetailPanelExpandedRowIds((prev) => {
        if (prev.includes(rowId)) {
          return prev.filter((id) => id !== rowId);
        } else {
          return [...prev, rowId];
        }
      });
    }
  };

  const memoDetailPanelContent = useCallback((...args: any) => getDetailPanelContent?.(args), []);

  // Auto expand detail panel if there is only one row
  useEffect(() => {
    if (autoExpandSingleRow && getDetailPanelContent && rows.length === 1) {
      setDetailPanelExpandedRowIds([rows[0].id]);
    }
  }, [rows.length, getDetailPanelContent, autoExpandSingleRow]);

  const getRowClassNameCustom = (row: GridRowClassNameParams) => {
    return classNames(
      {
        [PICKED_ROW_CLASS_NAME]: row.id === pickedRow,
        [DISABLED_ROW_CLASS_NAME]: isRowDisabled?.(row),
      },
      getRowClassName?.(row)
    );
  };

  return (
    <StyledDataGridPro
      apiRef={apiRef}
      rows={rows}
      columns={normalizedColumns}
      initialState={{
        columns: {
          orderedFields: columnsOrder,
          columnVisibilityModel,
        },
        sorting: {
          sortModel,
        },
        filter: {
          filterModel,
        },
      }}
      columnHeaderHeight={designSystemStyles ? 52 : 34}
      rowHeight={designSystemStyles ? 54 : 44}
      density={density}
      sortingMode="server"
      filterMode="server"
      getRowSpacing={({ id, isFirstVisible, isLastVisible }) => {
        if (!designSystemStyles) {
          return { top: isFirstVisible ? 0 : 2, bottom: 2 };
        }

        return {
          top: isFirstVisible ? newDesign.rowSpacing : 0,
          bottom: isLastVisible || detailsPanelExpandedRowIdsSet.has(id) ? 0 : newDesign.rowSpacing,
        };
      }}
      getRowClassName={getRowClassNameCustom}
      hideFooter
      hideFilterLogicOperator={hideFilterLogicOperator}
      checkboxSelection
      disableRowSelectionOnClick
      showColumnVerticalBorder
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={setColumnVisibilityModel}
      rowSelectionModel={selectionModel.selected}
      onRowSelectionModelChange={onSelectionModelChange}
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      filterModel={filterModel}
      onFilterModelChange={onFilterModelChange}
      pinnedColumns={normalizedPinnedColumns}
      onPinnedColumnsChange={onPinnedColumnsChange}
      onColumnWidthChange={onColumnsWidthChange}
      onRowsScrollEnd={restProps.onRowsScrollEnd ?? handleOnRowsScrollEnd}
      onRowClick={(...args) => {
        handleRowClick(args[0].id);
        onRowClick?.(...args);
      }}
      customSx={designSystemStyles ? newDesignSystemStyles(customSx) : customSx}
      getDetailPanelHeight={() => "auto"}
      detailPanelExpandedRowIds={detailPanelExpandedRowIds}
      slots={{
        toolbar: GridToolbar,
        columnMenu: GridColumnMenu,
        noRowsOverlay: GridNoRowsOverlay,
        baseButton: GridToolbarButton,
        detailPanelExpandIcon: DetailPanelExpandIcon,
        detailPanelCollapseIcon: DetailPanelCollapseIcon,
        loadingOverlay: designSystemStyles ? CHGridLoadingOverlay : GridLoadingOverlay,
        // V7 "hascolumnHeaderSortIcon" as slot so until we upgrade we will have nested buttons
        columnSortedAscendingIcon: designSystemStyles ? ColumnSortedAscendingIcon : undefined,
        columnSortedDescendingIcon: designSystemStyles ? ColumnSortedDescendingIcon : undefined,
        columnUnsortedIcon: designSystemStyles ? ColumnUnsortedIcon : undefined,
        baseCheckbox: designSystemStyles ? Checkbox : undefined,
        ...slots,
      }}
      getDetailPanelContent={memoDetailPanelContent}
      isExpandable={Boolean(getDetailPanelContent)}
      onDetailPanelExpandedRowIdsChange={(ids) => setDetailPanelExpandedRowIds(ids)}
      slotProps={{
        toolbar: {
          totalRowsCount,
          showTotalRowsCount,
          selectionModel,
          renderToolbarActions,
          searchEnabled,
          messages,
        },
        noRowsOverlay: {
          // @ts-ignore
          hasActiveFilters: Boolean(search.length),
          title: noRowsTitle,
          description: noRowsDescription,
        },
        columnsPanel: {
          onReset: resetGrid,
        },
        baseButton: {
          size: "small",
          color: "primary",
        },
        baseIconButton: {
          size: "extraSmall",
          color: "primary",
        },
      }}
      {...restProps}
    />
  );
}
