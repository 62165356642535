import React, { useState } from "react";
import classNames from "classnames";
import { ListSubheader, ListSubheaderProps, Typography, List } from "@ds-proxy";
import { Icon } from "@ds-ui";
import { css, styled } from "ui/css";

const StyledListSubheader = styled(ListSubheader, {
  display: "flex",
  alignItems: "center",
  background: "var(--background-base3)",
  paddingTop: "var(--spacing-xs)",
  paddingBottom: "var(--spacing-xs)",
});

export const ExpandIconButton = ({
  expanded,
  onClick,
  className,
}: {
  expanded: boolean;
  onClick(): void;
  className?: string;
}) => {
  return (
    <span
      className={classNames("flex items-center", className)}
      role="button"
      aria-label="Expand"
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <Icon name={expanded ? "ExpandLessOutlined" : "ExpandMoreOutlined"} color="action" />
    </span>
  );
};

const expanderIconClassName = css({
  position: "absolute",
  top: "-2px",
  right: "-22px",
});

interface IExpanderPure extends ListSubheaderProps {
  isExpanded: boolean;
  onExpand(): void;
  children: JSX.Element | JSX.Element[];
  title: string;
}

export const ExpanderPure = ({
  isExpanded,
  onExpand,
  children,
  title,
  ...subHeaderProps
}: IExpanderPure) => {
  const expander = (
    <ExpandIconButton
      className={expanderIconClassName().className}
      expanded={isExpanded}
      onClick={() => {
        onExpand();
      }}
    />
  );

  return (
    <List style={{ padding: 0 }} dense disablePadding role="treeitem">
      <StyledListSubheader aria-label="Tree item header" {...(subHeaderProps as any) /*TODO fix*/}>
        <Typography style={{ position: "relative", fontSize: 13 }} variant="subtitle2">
          <span style={{ paddingRight: 6 }}>{title}</span> {expander}
        </Typography>
      </StyledListSubheader>
      {isExpanded && children}
    </List>
  );
};

export const Expander = ({ children, ...rest }: Omit<IExpanderPure, "isExpanded" | "onExpand">) => {
  const [expanded, setExpanded] = useState(false);

  const toggle = () => setExpanded((expanded) => !expanded);

  return (
    <ExpanderPure onExpand={toggle} isExpanded={expanded} {...rest}>
      {children}
    </ExpanderPure>
  );
};
