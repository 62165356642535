import React from "react";
import { Icon } from "@ds-ui";
import { IconButton } from "@ds-proxy";

export function CopyConfigButton({ onClick }: { onClick: () => void }) {
  return (
    <IconButton onClick={onClick} size="extraSmall">
      <Icon name="Terminal" />
    </IconButton>
  );
}
