export function recordKeys<R extends object>(record: R): Array<keyof R> {
  return Object.keys(record) as Array<keyof R>;
}

export function recordValues<R extends object>(record: R): Array<R[keyof R]> {
  return Object.values(record) as Array<R[keyof R]>;
}

export function recordEntries<R extends object>(record: R): Array<[keyof R, R[keyof R]]> {
  return Object.entries(record) as Array<[keyof R, R[keyof R]]>;
}

/**
 * Data-first version of https://ramdajs.com/docs/#indexBy
 * > Given a function that generates a key, turns a list of objects into an object indexing the objects by the given key.
 * > When multiple objects generate the same value for the indexing key only the last value will be included in the generated object.
 *
 * @example
 * const items = [{ id: 1, name: "Alice" }, { id: 2, name: "Bob" }];
 * indexBy(items, item => item.id) // { 1: { id: 1, name: "Alice" }, 2: { id: 2, name: "Bob" } }
 */
export function indexBy<T, U extends PropertyKey>(items: T[], fn: (item: T) => U): Record<U, T> {
  return items.reduce((acc, item) => {
    acc[fn(item)] = item;
    return acc;
  }, {} as Record<U, T>);
}

/* Generic type guards */
export function isObject(value: unknown): value is object {
  return value !== null && typeof value === "object";
}

export function isString(value: unknown): value is string {
  return typeof value === "string";
}
function isNumber(value: unknown): value is number {
  return typeof value === "number";
}

export function isPositiveNumber(value: unknown): value is number {
  return isNumber(value) && value > 0;
}

export function isNegativeNumber(value: unknown): value is number {
  return isNumber(value) && value < 0;
}

/**
 * Type guard to check that a value is defined
 * @param value
 */
export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

/**
 * Replicate a function type with all arguments optional,
 * while handling the case where T might be undefined.
 */
export type AllArgsOptional<T> = T extends (...args: infer P) => infer R
  ? (...args: Partial<P>) => R
  : never;
