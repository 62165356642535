// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageTemplate-module__wrapper--aEKnU {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PageTemplate-module__innerContainer--P1QT4 {
  width: 400px;
}

.PageTemplate-module__paper--DwiVO {
  padding: var(--spacing-4xl);
}

.PageTemplate-module__logo--Gqv9_ {
  width: 100%;
  margin: 0 auto var(--spacing-l);
  color: var(--palette-common-black);
  text-align: center;

  svg {
    height: 43px;
    stroke: transparent;
    stroke-width: 0px;
  }
}

/* Apply dark mode styles correctly */
[data-color-scheme="dark"] .PageTemplate-module__logo--Gqv9_ {
  color: var(--palette-common-white);
}
`, "",{"version":3,"sources":["webpack://./src/modules/auth/pages/PageTemplate.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,+BAA+B;EAC/B,kCAAkC;EAClC,kBAAkB;;EAElB;IACE,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;EACnB;AACF;;AAEA,qCAAqC;AACrC;EACE,kCAAkC;AACpC","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.innerContainer {\n  width: 400px;\n}\n\n.paper {\n  padding: var(--spacing-4xl);\n}\n\n.logo {\n  width: 100%;\n  margin: 0 auto var(--spacing-l);\n  color: var(--palette-common-black);\n  text-align: center;\n\n  svg {\n    height: 43px;\n    stroke: transparent;\n    stroke-width: 0px;\n  }\n}\n\n/* Apply dark mode styles correctly */\n[data-color-scheme=\"dark\"] .logo {\n  color: var(--palette-common-white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `PageTemplate-module__wrapper--aEKnU`,
	"innerContainer": `PageTemplate-module__innerContainer--P1QT4`,
	"paper": `PageTemplate-module__paper--DwiVO`,
	"logo": `PageTemplate-module__logo--Gqv9_`
};
export default ___CSS_LOADER_EXPORT___;
