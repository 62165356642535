import {
  useGridApiContext,
  useGridSelector,
  gridDetailPanelExpandedRowIdsSelector,
  gridDetailPanelExpandedRowsContentCacheSelector,
  gridRowsLookupSelector,
} from "@mui/x-data-grid-pro";
import { recordKeys } from "@ds-proxy";

export function useExpandCollapseAll() {
  const apiRef = useGridApiContext();

  const expandedRowIds = useGridSelector(apiRef, gridDetailPanelExpandedRowIdsSelector);

  const rowsWithDetailPanels = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector
  );

  function expandAll() {
    const dataRowIdToModelLookup = gridRowsLookupSelector(apiRef);
    const allRowIdsWithDetailPanel = recordKeys(rowsWithDetailPanels).map((key) =>
      apiRef.current.getRowId(dataRowIdToModelLookup[key])
    );
    apiRef.current.setExpandedDetailPanels(allRowIdsWithDetailPanel);
  }

  function collapseAll() {
    apiRef.current.setExpandedDetailPanels([]);
  }

  return {
    expandedRowIds,
    expandAll,
    collapseAll,
  };
}
