import React from "react";
import classnames from "classnames";

export interface GridPageTemplateProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  isSidePanelOpen?: boolean;
  contentWrapperStyle?: React.CSSProperties;
  sidepanelWrapperStyle?: React.CSSProperties;
  sidePanel?: React.ReactNode;
  filters?: React.ReactNode;
  counters?: React.ReactNode;
  className?: string;
}

/**
 * @deprecated All new pages should adhere to the new design system
 */
export function GridPageTemplate({
  children,
  header,
  filters,
  counters,
  sidePanel,
  contentWrapperStyle,
  sidepanelWrapperStyle,
  isSidePanelOpen,
  className,
}: GridPageTemplateProps) {
  return (
    <div className={classnames("flex flex-col h-full w-full overflow-hidden", className)}>
      <div className={"flex h-full"}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: `0 var(--spacing-xl)`,
            overflow: "hidden",
            transition: "flex-shrink .3s ease-in",
            width: "100%",
            flexShrink: isSidePanelOpen ? 1 : 0,
            ...contentWrapperStyle,
          }}
        >
          {header && <div className="mt-l mb-s">{header}</div>}
          {filters && <div className="mb-xl">{filters}</div>}
          {counters && <div className="mb-2xl">{counters}</div>}
          {children}
        </div>
        {sidePanel && (
          <div
            style={{
              height: "100%",
              minWidth: "450px",
              maxWidth: "600px",
              width: "40%",
              flexShrink: 0,
              ...sidepanelWrapperStyle,
            }}
          >
            {sidePanel}
          </div>
        )}
      </div>
    </div>
  );
}
