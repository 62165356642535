import React, { ComponentProps } from "react";
// eslint-disable-next-line no-restricted-imports
import { theme } from "@ds-proxy/theme";

export enum Severity {
  Informational = 0,
  Low = 1,
  Medium = 2,
  High = 3,
  Critical = 4,
}

export const NULL_SEVERITY = -1;

export type SeverityItemData = {
  label: string;
  color: string;
  severity: Severity;
  unselectedColor: string;
  isNullItem?: boolean;
};

export const severityMap: Record<Severity | "null_value", SeverityItemData> = {
  [Severity.Informational]: {
    label: "Informational",
    color: theme.customColors.severity.informational.main,
    severity: Severity.Informational,
    unselectedColor: theme.customColors.severity.informational.light,
  },
  [Severity.Low]: {
    label: "Low",
    color: theme.customColors.severity.low.main,
    severity: Severity.Low,
    unselectedColor: theme.customColors.severity.low.light,
  },
  [Severity.Medium]: {
    label: "Medium",
    color: theme.customColors.severity.medium.main,
    severity: Severity.Medium,
    unselectedColor: theme.customColors.severity.medium.light,
  },
  [Severity.High]: {
    label: "High",
    color: theme.customColors.severity.high.main,
    severity: Severity.High,
    unselectedColor: theme.customColors.severity.high.light,
  },
  [Severity.Critical]: {
    label: "Critical",
    color: theme.customColors.severity.critical.main,
    severity: Severity.Critical,
    unselectedColor: theme.customColors.severity.critical.light,
  },
  null_value: {
    label: "Unmatched",
    color: theme.customColors.severity.null.main,
    unselectedColor: theme.customColors.severity.null.light,
    severity: NULL_SEVERITY as Severity,
    isNullItem: true,
  },
};

export const severityListWithNull = Object.values(severityMap)
  .map(({ label, color, severity, ...rest }) => {
    return { severity, label, color, ...rest };
  })
  .sort((a, b) => b.severity - a.severity);

export const severityList = severityListWithNull.filter(({ isNullItem }) => !isNullItem);
export const isNullSeverityAllowed = (severities: Severity[] | null) => {
  if (!severities) {
    return false;
  }
  return severities.includes(NULL_SEVERITY as Severity);
};

export const isAllSeveritiesSelected = (severities?: Severity[] | null) => {
  if (!severities) {
    return false;
  }
  if (severities.length === 0) {
    return true;
  }
  const severitiesSet = new Set(severities);
  return severityList.every((v) => severitiesSet.has(v.severity));
};

export type SeverityItemProps = {
  containerProps?: ComponentProps<"div">;
  severity: Severity;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  showLabel?: boolean;
  nullSeverity?: boolean;
};

export const SeverityItem = ({
  containerProps,
  severity,
  onClick,
  showLabel = true,
  nullSeverity = false,
}: SeverityItemProps) => {
  if (!severityMap[severity] && !nullSeverity) {
    return null;
  }

  const { style, ...restContainerProps } = containerProps || {};
  const { color, label } = formatSeverity(severity);
  const marginRight = showLabel ? 10 : 0;

  return (
    <div
      onClick={onClick}
      style={{ display: "flex", alignItems: "center", padding: "3px 0", ...style }}
      {...restContainerProps}
    >
      <div style={{ background: color, width: 16, height: 16, marginRight, flexShrink: 0 }} />
      {showLabel && label}
    </div>
  );
};

export function formatSeverity(severity?: Severity): { color: string; label: string } {
  return typeof severity === "number"
    ? severityMap[severity] || severityMap.null_value
    : severityMap.null_value;
}
