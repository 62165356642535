import { ENV } from "runenv";
// eslint-disable-next-line no-restricted-imports
import { theme } from "@ds-proxy/theme";
import { types, SensorNames } from "api/gen";
import { SensorType } from "./constants";

// this file should be moved to dataflow-core
export const riskyColors = {
  low_risky: theme.customColors.risks.risksColorMap.low_risky,
  risky: theme.customColors.risks.risksColorMap.risky,
  risky_or_low: theme.customColors.risks.risksColorMap.risky_or_low,
  needs_review: theme.customColors.risks.risksColorMap.needs_review,
  approved: theme.customColors.risks.risksColorMap.approved,
} as Record<types.CategoryStatus, string>;

export function getColorForRisk(r: types.CategoryStatus) {
  return riskyColors[r] ?? riskyColors.needs_review;
}

export const getSensorNames = (sensorTypes: SensorType[]): string[] => {
  return Object.values(SensorNames).filter((name) =>
    sensorTypes.some((sensorType) => name.startsWith(sensorType))
  );
};

export const isEmailNotificationSupported = (rule: types.Category["rule"]) =>
  Boolean(rule?.create_incident) ||
  (ENV.FEATURES.AI_INCIDENT_DETECTION && !rule?.disable_ai_incidents);
