import React from "react";
import { CircularProgress } from "@ds-proxy";

export type LayoutLoaderProps = {
  classes?: {
    progress?: string;
    loaderWrapper?: string;
  };
  innerRef?: React.RefObject<HTMLDivElement> | null;
  loadingMessage?: string;
};

export const LayoutLoader = ({
  classes,
  innerRef = null,
  loadingMessage = "Loading...",
}: LayoutLoaderProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        height: "100%",
        placeContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        zIndex: 200,
      }}
      ref={innerRef}
    >
      <CircularProgress className={classes?.progress} size={50} />
      {loadingMessage}
    </div>
  );
};
