import React from "react";
import { Skeleton } from "@ds-proxy";

export function FilterSkeleton({ count = 3 }: { count: number }) {
  return Array.from({ length: count }, (_, i) => (
    <Skeleton animation="wave" variant="rectangular" key={i} height={128} width={320} />
  )) as any;
}

export function TopLevelFilters({ isLoading, children }: any) {
  return (
    <div data-fs="unmask" className="flex mb-l select-none gap-l">
      {isLoading ? (
        <FilterSkeleton count={React.Children.toArray(children).length} />
      ) : (
        <>{children}</>
      )}
    </div>
  );
}
