import { Context, useContext } from "react";

export function useNonNullableContext<T>(context: Context<T>, name?: string): NonNullable<T> {
  const value = useContext(context);

  if (value === null || typeof value === "undefined") {
    const displayName = name ?? context.displayName ?? "[name unavailable]";
    throw new Error(`Context ${`"${displayName}"`} was used without a Provider`);
  }

  return value;
}
