import { ThemeOptions } from "@ds-proxy";

export const palette: ThemeOptions["palette"] = {
  mode: "dark",
  primary: {
    main: "#81D4FA",
    dark: "#4FC3F7",
    light: "#E1F5FE",
    ultralight: "#1F292E",
  },
  // TODO: Talk with DS about having a secondary color
  secondary: {
    main: "#737373",
    ultralight: "#1F1F1F",
  },
  error: {
    main: "#F44336",
    dark: "#D32F2F",
    light: "#E57373",
    ultralight: "#251615",
  },
  success: {
    main: "#66BB6A",
    dark: "#388E3C",
    ultralight: "#223323",
  },
  warning: {
    main: "#FFA726",
    dark: "#E65100",
    ultralight: "#4B3920",
  },
  info: {
    main: "#29B6F6",
    dark: "#0288D1",
    ultralight: "#16323F",
  },
  divider: "rgba(255,255,255, 0.12)",
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
  text: {
    primary: "#CBD5E1",
    secondary: "#94A3B8",
    disabled: "#64748B",
    icon: "#CBD5E1",
    links: "#7DD3FC",
    placeholder: "#64748B",
    subtle: "#64748B",
    inverted: "#000000",
  },
};
