import React from "react";
// TODO: See if we can refactor this and not use space from theme
// eslint-disable-next-line no-restricted-imports
import { Spacing } from "@ds-proxy/theme/types";
import { Spacer } from "libs/layouts";
import { selectors } from "libs/css";
import { HStack } from "./Stack";
import { Box } from "./Box";

export type ShowOnHoverProps = {
  node: React.ReactNode;
  children?: React.ReactNode;
  space?: Spacing;
  sx?: React.CSSProperties;
  spacer?: boolean;
  disableOnHover?: boolean;
  className?: string;
  directChildOnly?: boolean;
};

/**
 * Abstraction to show component on children hover.
 * @param node to be shown once user hovers on the "children" of ShowOnHover.
 * @param spacer to add spacer between children and component to be shown
 */

export const ShowOnHover = React.forwardRef<HTMLDivElement, ShowOnHoverProps>(function ShowOnHover(
  {
    children,
    space,
    sx,
    className,
    node,
    disableOnHover,
    spacer = false,
    directChildOnly = false,
  }: ShowOnHoverProps,
  ref
) {
  return (
    <HStack
      role="group"
      ref={ref}
      space={space}
      sx={{
        ...sx,
        position: "relative",
      }}
      className={className}
    >
      {children}
      {spacer && <Spacer />}
      {node && (
        <Box
          sx={
            !disableOnHover
              ? {
                  visibility: "hidden",
                  [directChildOnly ? selectors.hoverParent : selectors.hoverGroup]: {
                    visibility: "visible",
                  },
                }
              : {}
          }
        >
          {node}
        </Box>
      )}
    </HStack>
  );
});
