import React, { ReactNode } from "react";
import { LocationEndpointUnmanagedIcon } from "assets/icons";
import { SvgIcon } from "@ds-proxy";
import { locations } from "modules/graph/services";
import { types } from "api";
import { types_LocationEnum } from "api/gen";
import { AppFavIcon, DomainFavIcon } from "ui";
import { locationIconMap } from "modules/graph/icons";

export interface LocationIconProps {
  item: {
    location: types.Location;
    location_outline: string;
    expand_path?: types.LocationPath;
  };
  depth?: number;
  shouldShowGenericIconForNested?: boolean;
  fallback?: ReactNode;
}

export function LocationIcon({
  item: { expand_path, location, location_outline },
  depth = expand_path?.length ? expand_path.length - 1 : 0,
  shouldShowGenericIconForNested = false,
  fallback = null,
}: LocationIconProps) {
  // start showing icons level before for emails and endpoint apps
  const isFavIconsLevel =
    location === types_LocationEnum.LocationMail ||
    location === types_LocationEnum.LocationMailBody ||
    location === types_LocationEnum.LocationEndpointApps
      ? depth > 0
      : depth > 1;

  if (isFavIconsLevel) {
    if (
      location === types_LocationEnum.LocationWebsite ||
      location === types_LocationEnum.LocationMail ||
      location === types_LocationEnum.LocationMailBody
    ) {
      return <DomainFavIcon size={16} url={location_outline} />;
    }

    if (location === types_LocationEnum.LocationEndpointApps) {
      return <AppFavIcon size={16} app={location_outline} color={locations.endpoint_apps.color} />;
    }
  }

  const icon =
    location === types_LocationEnum.LocationEndpoint && location_outline === "unmanaged" ? (
      <LocationEndpointUnmanagedIcon />
    ) : (
      locationIconMap[location] || locationIconMap["*"]
    );

  return !isFavIconsLevel || shouldShowGenericIconForNested ? (
    <SvgIcon fontSize="small">{icon}</SvgIcon>
  ) : (
    fallback
  );
}
