// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Table-module__wrapper--lueAc {
  td {
    background: var(--background-base0);
  }
}

.Table-module__loadingClassName--DiVGx {
  td {
    opacity: 0.6;
    filter: blur(1px);
    user-select: none;
  }
}

.Table-module__resizeActiveClassName--JfWQA {
  th,
  td {
    user-select: none;
  }
}

.Table-module__resizerClassName--mLJHQ {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  user-select: none;
  cursor: col-resize;
  width: 4px;

  &:hover {
    border-left: var(--border-width-medium) solid var(--outlines-2) !important;
  }
}

.Table-module__stripedClassName--d79e6 {
  & > td {
    background: var(--background-base3);
  }
}

.Table-module__stripedCellClassName--YL21Q {
  background: var(--background-base2);
}

.Table-module__borderBottomClassName--j98pu {
  border-bottom: var(--border-width-small) solid var(--outlines-2);
}

.Table-module__highlightOnHoverClassName--iZRIM {
  &:hover {
    background: var(--background-active);
  }
}

.Table-module__highlightClassName--zOWiC {
  background: var(--background-active);
}

.Table-module__borderNone--uDnYY {
  border-left: none;
}
`, "",{"version":3,"sources":["webpack://./src/libs/css-grid-table/Table.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,mCAAmC;EACrC;AACF;;AAEA;EACE;IACE,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;EACnB;AACF;;AAEA;EACE;;IAEE,iBAAiB;EACnB;AACF;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,SAAS;EACT,iBAAiB;EACjB,kBAAkB;EAClB,UAAU;;EAEV;IACE,0EAA0E;EAC5E;AACF;;AAEA;EACE;IACE,mCAAmC;EACrC;AACF;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,gEAAgE;AAClE;;AAEA;EACE;IACE,oCAAoC;EACtC;AACF;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".wrapper {\n  td {\n    background: var(--background-base0);\n  }\n}\n\n.loadingClassName {\n  td {\n    opacity: 0.6;\n    filter: blur(1px);\n    user-select: none;\n  }\n}\n\n.resizeActiveClassName {\n  th,\n  td {\n    user-select: none;\n  }\n}\n\n.resizerClassName {\n  position: absolute;\n  left: 0;\n  top: 0;\n  bottom: 0;\n  user-select: none;\n  cursor: col-resize;\n  width: 4px;\n\n  &:hover {\n    border-left: var(--border-width-medium) solid var(--outlines-2) !important;\n  }\n}\n\n.stripedClassName {\n  & > td {\n    background: var(--background-base3);\n  }\n}\n\n.stripedCellClassName {\n  background: var(--background-base2);\n}\n\n.borderBottomClassName {\n  border-bottom: var(--border-width-small) solid var(--outlines-2);\n}\n\n.highlightOnHoverClassName {\n  &:hover {\n    background: var(--background-active);\n  }\n}\n\n.highlightClassName {\n  background: var(--background-active);\n}\n\n.borderNone {\n  border-left: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Table-module__wrapper--lueAc`,
	"loadingClassName": `Table-module__loadingClassName--DiVGx`,
	"resizeActiveClassName": `Table-module__resizeActiveClassName--JfWQA`,
	"resizerClassName": `Table-module__resizerClassName--mLJHQ`,
	"stripedClassName": `Table-module__stripedClassName--d79e6`,
	"stripedCellClassName": `Table-module__stripedCellClassName--YL21Q`,
	"borderBottomClassName": `Table-module__borderBottomClassName--j98pu`,
	"highlightOnHoverClassName": `Table-module__highlightOnHoverClassName--iZRIM`,
	"highlightClassName": `Table-module__highlightClassName--zOWiC`,
	"borderNone": `Table-module__borderNone--uDnYY`
};
export default ___CSS_LOADER_EXPORT___;
