import React from "react";
import { StandardProps } from "@ds-proxy";
import { css } from "ui/css";

export interface ToggleButtonGroupProps
  extends StandardProps<
    React.HTMLAttributes<HTMLDivElement>,
    ToggleButtonGroupClassKey,
    "onChange" | "children"
  > {
  /**
   * The content of the button.
   */
  children?: React.ReactNode;
  /**
   * If `true`, only allow one of the child ToggleButton values to be selected.
   */
  exclusive?: boolean;
  /**
   * Callback fired when the value changes.
   *
   * @param {object} event The event source of the callback.
   * @param {any} value of the selected buttons. When `exclusive` is true
   * this is a single value; when false an array of selected values. If no value
   * is selected and `exclusive` is true the value is null; when false an empty array.
   */
  onChange?: (event: React.MouseEvent<HTMLElement>, value: any) => void;
  /**
   * The currently selected value within the group or an array of selected
   * values when `exclusive` is false.
   */
  value?: any;
}

export type ToggleButtonGroupClassKey =
  | "root"
  | "grouped"
  | "groupedSizeSmall"
  | "groupedSizeLarge";

function isValueSelected(value: any, candidate: any) {
  if (candidate === undefined || value === undefined) {
    return false;
  }

  if (Array.isArray(candidate)) {
    return candidate.indexOf(value) >= 0;
  }

  return value === candidate;
}

const grouped = css({
  "padding": "0px 11px 0px 12px",
  "&:not(:first-child)": {
    marginLeft: -1,
    borderLeft: "1px solid transparent",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  "&:not(:last-child)": {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
});

export const ToggleButtonGroupInternal = React.forwardRef(function ToggleButton(
  props: ToggleButtonGroupProps,
  ref: any
) {
  const { children, className, exclusive = false, onChange, value, ...other } = props;

  const handleChange = (event: any, buttonValue: any) => {
    if (!onChange) {
      return;
    }

    const index = value && value.indexOf(buttonValue);
    let newValue;

    if (value && index >= 0) {
      newValue = [...value];
      newValue.splice(index, 1);
    } else {
      newValue = value ? [...value, buttonValue] : [buttonValue];
    }

    onChange(event, newValue);
  };

  const handleExclusiveChange = (event: any, buttonValue: any) => {
    if (!onChange) {
      return;
    }

    onChange(event, value === buttonValue ? null : buttonValue);
  };

  return (
    <div
      style={{
        backgroundColor: "var(--palette-background-paper)",
        borderRadius: "var(--border-radius-sm)",
        display: "inline-flex",
      }}
      ref={ref}
      role="group"
      {...other}
    >
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        if (process.env.NODE_ENV !== "production") {
          if (child.type === React.Fragment) {
            console.error(
              [
                "Material-UI: the ToggleButtonGroup component doesn't accept a Fragment as a child.",
                "Consider providing an array instead.",
              ].join("\n")
            );
          }
        }

        const { selected: buttonSelected, value: buttonValue } = child.props;
        const selected =
          buttonSelected === undefined ? isValueSelected(buttonValue, value) : buttonSelected;

        return React.cloneElement(child, {
          className: grouped().className,
          selected,
          onChange: exclusive ? handleExclusiveChange : handleChange,
        } as any);
      })}
    </div>
  );
});
