import React, { FunctionComponent } from "react";
import { CircularProgress, CircularProgressProps } from "@ds-proxy";
import styles from "./Loader.module.css";

type LoaderProps = CircularProgressProps & {
  loading: boolean;
};

export const Loader: FunctionComponent<React.PropsWithChildren<LoaderProps>> = ({
  loading,
  children,
  ...props
}) =>
  loading ? (
    <div className={styles.wrapper} aria-label="Loading">
      <CircularProgress {...props} />
    </div>
  ) : (
    children
  );
