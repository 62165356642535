import React from "react";
import { Typography } from "@ds-proxy";
import { Box } from "ui";
import { Stack, HStack } from "libs/layouts";
import { LineProgress } from "ui/atoms/Progress";

export type FilterCardProps = {
  label: string;
  counter: number;
  subLabel: string;
  actions?: JSX.Element | null;
  progressColors: string[];
  progressValues: number[];
  selected: boolean;
  onClick?: (e: any) => any;
  overlappingProgress?: boolean;
  progressActive?: boolean[];
  countTestId?: string;
};
export function FilterCard({
  label,
  counter,
  subLabel,
  actions = null,
  selected,
  onClick = undefined,
  progressValues,
  progressColors,
  overlappingProgress,
  progressActive,
  countTestId,
}: FilterCardProps) {
  const isDisabled = counter === 0;
  return (
    <Box
      sx={{
        "paddingX": "var(--spacing-s)",
        "paddingY": "var(--spacing-m)",
        "background": selected ? "var(--background-active)" : "var(--background-idle)",
        "width": "320px",
        "boxShadow": "var(--box-shadow-shadow1)",
        "cursor": !isDisabled ? "pointer" : "default",
        "&:hover": {
          background: isDisabled ? "" : "var(--background-active)",
        },
      }}
      as={Stack}
      space={5}
      onClick={!isDisabled ? onClick : undefined}
    >
      <HStack>
        <Box disabled={isDisabled}>
          <Typography variant="subtitle2">{label}</Typography>
        </Box>
        <HStack.Spacer />
        {actions}
      </HStack>
      <Box as={Stack} space={2} disabled={isDisabled}>
        <HStack align="baseline">
          <Typography variant="h4" data-testid={countTestId}>
            {counter}
          </Typography>{" "}
          <Typography variant="body2">{subLabel}</Typography>
        </HStack>
        <LineProgress
          overlap={overlappingProgress}
          minValue={5}
          options={progressValues.map((el, i) => ({
            value: el ?? 0,
            color: progressColors[i],
            isActive: progressActive?.[i],
          }))}
          style={{
            background: "var(--background-base1)",
          }}
        />
      </Box>
    </Box>
  );
}
