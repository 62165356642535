import React from "react";
import { Skeleton as MuiSkeleton, SkeletonProps as MuiSkeletonProps } from "@ds-proxy";

export type SkeletonProps = MuiSkeletonProps;

export function Skeleton(props: SkeletonProps) {
  return (
    <MuiSkeleton
      {...props}
      sx={{
        bgcolor: "var(--background-skeleton)",
      }}
    />
  );
}
