// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pagination-module__spacerStyles--f_arv {
  display: none;
}

.Pagination-module__toolbarStyles--nd2Gi {
  justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/libs/css-grid-table/Pagination.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".spacerStyles {\n  display: none;\n}\n\n.toolbarStyles {\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spacerStyles": `Pagination-module__spacerStyles--f_arv`,
	"toolbarStyles": `Pagination-module__toolbarStyles--nd2Gi`
};
export default ___CSS_LOADER_EXPORT___;
