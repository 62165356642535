/* eslint-disable no-restricted-imports  */
import { navigationSelectors } from "shared/testConstants";
import { lazy } from "react";
import { INavigationItem } from "shared/types";
import { AnalyticsDashboardIcon, AdministrationIcon } from "assets/icons";
// TODO: Discuss with Design to see if we can change these icons
import Cloud from "@mui/icons-material/Cloud";
import Settings from "@mui/icons-material/Settings";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import { Home, AssuredWorkload } from "@mui/icons-material";
import { messages } from "modules/layout/messages";
import { routes } from "./routes";
import { ROUTES_PATHS } from "./routesPaths";

export const getNavigationIcons = () =>
  [
    {
      "label": messages.pages.dashboards,
      "icon": Home,
      "data-testid": navigationSelectors.dashboards,
      "hidden": routes.dashboards.disabled,
      ...routes.dashboards,
    },
    {
      "label": "Risks Overview",
      "icon": DashboardIcon,
      "data-testid": navigationSelectors.dashboard,
      ...routes.risksDashboard,
    },
    {
      "label": messages.pages.analyticsDashboard,
      "icon": AnalyticsDashboardIcon,
      "data-testid": navigationSelectors.analytics,
      "hidden": routes.analyticsDashboard.disabled,
      ...routes.analyticsDashboard,
      "path": ROUTES_PATHS.ANALYTICS_DASHBOARD(),
    },
    {
      "label": "Insider Risk",
      "icon": PersonIcon,
      "data-testid": navigationSelectors.insiders,
      "hidden": routes.insiderRisks.disabled,
      ...routes.insiderRisks,
    },
    {
      "label": messages.pages.incidents,
      "icon": lazy(() =>
        import("modules/risks/alerts-table/AlertsSideIcon").then((el) => ({
          default: el.AlertsSideIcon,
        }))
      ),
      "data-testid": navigationSelectors.incidents,
      ...routes.alerts,
    },
    {
      isSpacer: true,
    },
    {
      "label": "Objects",
      "icon": AssuredWorkload,
      "data-testid": navigationSelectors.objects,
      "hidden": routes.objects.disabled,
      ...routes.objects,
    },
    {
      "label": messages.pages.cloudSensors,
      "data-testid": navigationSelectors.sensors.cloud,
      "icon": Cloud,
      ...routes.sensors,
      "path": "/sensors/cloud",
    },
    {
      "label": messages.pages.endpointSensors,
      "icon": lazy(() =>
        import("modules/endpoint-sensors/molecules/EndpointSideIcon").then((el) => ({
          default: el.EndpointSideIcon,
        }))
      ),
      "data-testid": navigationSelectors.sensors.endpoint,
      ...routes.endpoint,
      "path": "/sensors/endpoint",
    },
    {
      "label": messages.pages.administration,
      "icon": AdministrationIcon,
      "data-testid": navigationSelectors.administration,
      ...routes.administration,
      "path": "/administration",
    },
    {
      "label": messages.pages.preferences,
      "icon": Settings,
      "data-testid": navigationSelectors.preferences,
      ...routes.preferences,
      "path": "/preferences",
    },
  ] as INavigationItem[];
