import React from "react";
import { ThemeStateProvider, useTheme } from "@shared/color-theme/useTheme";

export const ThemeLoaderInner = ({ children }: { children: React.ReactNode }) => {
  useTheme();

  return children;
};

export const ThemeLoader = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeStateProvider>
      <ThemeLoaderInner>{children}</ThemeLoaderInner>
    </ThemeStateProvider>
  );
};
