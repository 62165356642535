import React, { useState } from "react";
import { IconButton } from "@ds-proxy";
import { Icon } from "@ds-ui";
import { SearchInput } from "ui";
import { useGrid } from "../useGrid";

export function GridToolbarSearch({ placeholder }: { placeholder?: string }) {
  const { search, setSearch } = useGrid();
  const [isSearching, setIsSearching] = useState(Boolean(search));

  return isSearching || Boolean(search) ? (
    <SearchInput
      autoFocus
      initialValue={search}
      className={"w-full"}
      placeholder={placeholder || "Search Name, Title, or Dept."}
      inputProps={{
        startAdornment: null,
        onBlur: () => {
          if (!search) {
            setIsSearching(false);
          }
        },
      }}
      onCancel={() => {
        setSearch("");
        setIsSearching(false);
      }}
      onRequestSearch={(search: string) => (search.length > 2 || !search) && setSearch(search)}
    />
  ) : (
    // height 44px comes from the height of the searchInput, we should ideally align these in a different way
    <IconButton color={"secondary"} onClick={() => setIsSearching(true)} style={{ height: "44px" }}>
      <Icon name={"SearchOutlined"} />
    </IconButton>
  );
}
