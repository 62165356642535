import React from "react";
import {
  IconButton,
  Button,
  ButtonGroup,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  CircularProgress,
  IconButtonProps,
  ButtonGroupOwnProps,
  Link,
  ClickAwayListener,
} from "@ds-proxy";
import { Icon } from "@ds-ui";
import { usePopover } from "libs/hooks";

const layoutMessages = {
  exportAll: "Export all available columns",
};

type ExportButtonProps = {
  "children"?: React.ReactNode;
  "isLoading": boolean;
  "data-testid": string;
  "url": string;
  "exportAllUrl"?: string;
  "exportAllTestId"?: string;
  "color"?: ButtonGroupOwnProps["color"];
  "size"?: ButtonGroupOwnProps["size"];
};

export function ExportButton({
  children = <>Export</>,
  isLoading,
  "data-testid": testId,
  url,
  exportAllUrl,
  exportAllTestId,
  color = "primary",
  size = "small",
}: ExportButtonProps) {
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    actions.onClose();
  };

  const [popover, actions] = usePopover((api) => (
    <Popper
      {...api}
      anchorEl={anchorRef.current}
      role={undefined}
      style={{ zIndex: 3 }}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="export-button-menu">
                <Link
                  style={{
                    textDecoration: "none",
                    pointerEvents: isLoading ? "none" : undefined,
                  }}
                  href={exportAllUrl}
                  data-testid={exportAllTestId}
                >
                  <MenuItem dense onClick={togglePopover}>
                    {layoutMessages.exportAll}
                  </MenuItem>
                </Link>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  ));

  const togglePopover = (ev: React.MouseEvent<HTMLElement>) =>
    actions.open ? actions.onClose() : actions.onOpen(anchorRef.current || ev.target);

  return (
    <>
      <ButtonGroup variant="subtle" ref={anchorRef} aria-label="split button" color={color}>
        <Button
          href={url}
          data-testid={testId}
          style={{ textDecoration: "none", pointerEvents: isLoading ? "none" : undefined }}
          startIcon={
            isLoading ? (
              <CircularProgress sx={{ marginX: "2px" }} size={16} color="inherit" />
            ) : (
              <Icon name="GetAppOutlined" />
            )
          }
          size={size}
        >
          {children}
        </Button>
        {exportAllUrl && (
          <ArrowMenuButtonComponent
            aria-label="export all menu"
            isPopoverOpened={actions.open}
            onClick={togglePopover}
            color={color}
            size={size}
          />
        )}
      </ButtonGroup>
      {popover}
    </>
  );
}

export type ArrowMenuButtonComponentProps = IconButtonProps & {
  isPopoverOpened?: boolean;
  color?: ButtonGroupOwnProps["color"];
  size?: ButtonGroupOwnProps["size"];
};

export const ArrowMenuButtonComponent = ({
  isPopoverOpened,
  ...props
}: ArrowMenuButtonComponentProps) => {
  return (
    <IconButton
      aria-controls={"export-button-menu"}
      aria-expanded={isPopoverOpened ? "true" : false}
      aria-haspopup="menu"
      color={"primary"}
      variant="subtle"
      {...props}
    >
      <Icon name={isPopoverOpened ? "KeyboardArrowUpOutlined" : "KeyboardArrowDownOutlined"} />
    </IconButton>
  );
};
